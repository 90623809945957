import React from 'react'

import './style.css'

export function Steps({steps, active, onClick}){
  const activeIndex = steps.findIndex(item => item.url === active)

  function setActive(_step){
      if (!onClick) return null

      onClick(_step)
  }

  if (activeIndex === -1) return null

  return(
      <div className="steps">
          {steps.map((item, key) =>
              <div key={key} className={`step ${item?.url === active ? "active" : ""} ${key < activeIndex ? "done" : ""}`}>
                  <div onClick={() => setActive(item)}>
                      <span className="number">{++key}</span>
                      <span className="label">{item.label}</span>
                  </div>
                  <span className="separator"></span>
              </div>
          )}
      </div>          
  )
}