import React, { useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { useDetectOutsideClick } from '../../hooks'

import './style.css'

export function PriorityBar({navigationItems = [], stickLast = false, itemMinWidth = 0}) {

  const navigationOuter = useRef(null)
  const navigation = useRef(null)
  const moreNav = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper: navigationOuter, menu: moreNav, initialState: false, disableInClickClose: true})
  const [priorityItems, setPriorityItems] = useState(navigationItems)
  const [moreItems, setMoreItems] = useState([])

  useEffect(() => {
    const widthsArray = Array.from(navigation.current.children).map(item => item.getBoundingClientRect().width || itemMinWidth)

    function howManyItemsInMenuArray(array, outerWidth, minimumNumberInNav, currentLength){
      let total = 0
      for(let i = 0; i < array.length; i++) {
        if(total + array[i] > (outerWidth - itemMinWidth/2)) {
          return Math.max(minimumNumberInNav, i)
        } else {
          total += array[i]
        }
      }
      if (navigationItems?.length > array?.length) return array.length - 1
    }

    function updateNavigation(){
      if (!navigationOuter.current) return false

      let outerWidth = Math.min(navigationOuter.current.getBoundingClientRect().width, window.innerWidth)
      let arrayAmount = howManyItemsInMenuArray(widthsArray, outerWidth, 0)
      const navItemsCopy = navigationItems

      if (navItemsCopy.length - arrayAmount === 0) arrayAmount = navItemsCopy.length

      let _priorityItems = navItemsCopy.slice(0, arrayAmount)
      let _moreItems = _priorityItems.length !== navItemsCopy.length ? navItemsCopy.slice(arrayAmount, navItemsCopy.length) : []

      if (stickLast){
        _moreItems.unshift(_priorityItems[_priorityItems.length-1])
        _priorityItems.splice(-1)
        _priorityItems.push(_moreItems[_moreItems.length-1])
        _moreItems.splice(-1)
      }

      setPriorityItems(_priorityItems)
      setMoreItems(_moreItems)
   }

    window.addEventListener('resize', updateNavigation, 100)

    updateNavigation()

    return () => {
      window.removeEventListener('resize', updateNavigation)
    }
    // eslint-disable-next-line
  }, [navigationItems, stickLast, itemMinWidth])

  return(
    <div ref={navigationOuter} className="priority-bar">
      <div ref={navigation} className="priority-bar-items">
        {moreItems?.length > 0 && <div className="toggle" onClick={(e) => setIsActive(!isActive)}><FontAwesome name="filter" /></div>}
        {priorityItems.map((item, i) => item)}
      </div>
      {moreItems.length > 0 &&
        <div ref={moreNav} className={`more-items-box ${isActive ? "opened" : ""}`}>
          {moreItems.map((item, i) => item )}
        </div>
      }
    </div>
  )
}
