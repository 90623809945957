import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Notification, IntlText } from '../../../components'

import './style.css'

export function PurchaseFeedback({purchase}) {
  
  if (!purchase.feedback) return (
    <div id="purchase-feedback" className="tab">
      <div>
        <div className="product-feedback">
          <span className="no-feedback"><IntlText group="purchase-feedback" id="no-feedback" /></span>
        </div>
      </div>
    </div>
  )

  return(
    <div id="purchase-feedback">
      <div className="product-feedback">
        <div className="faces">
          <div className={`face ${purchase.feedback.productsQuantitative === 1 ? "active" : ""}`}>
            <FontAwesome name="frown" />
          </div>
          <div className={`face ${purchase.feedback.productsQuantitative === 2 ? "active" : ""}`}>
            <FontAwesome name="meh" />
          </div>
          <div className={`face ${purchase.feedback.productsQuantitative === 3 ? "active" : ""}`}>
            <FontAwesome name="smile" />
          </div>
        </div>
        {purchase.feedback.productsQualitative &&
          <span>{purchase.feedback.productsQualitative}</span>
        }
      </div>
      {!!purchase.feedback?.productsVoided &&
        <Notification icon="exclamation-triangle" type='ui'>
          <IntlText group="purchase-feedback" id="voided" />
        </Notification>          
      }
    </div>
  )
  
}
