import React from 'react'

import { CategoryForm } from '../../views'

export function NewCategory({match}) {

  return(
    <div id="product" className="container">
      <div className="row padding">
        <CategoryForm />
      </div>
    </div>
  )

}
