import { gql }from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const REPORT_PURCHASE = gql`
  mutation reportPurchase($id: ID!, $reason: PURCHASE_REPORT_REASON!){
    reportPurchase(id: $id, reason: $reason){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
