import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { PRODUCT_QUERY } from '../../graphql'
import { IntlText, Loader, ProgressSteps, Button, Title } from '../../components'
import { ProductDetails, ProductAddons, ProductTagEditor, ProductCardPreview, ProductStatus, ReviewProductAddon } from '../../views'

import './style.css'

export function NewProduct() {

  const items = [
    <IntlText group="new-product" id="steps-product-details" />,
    <IntlText group="new-product" id="steps-product-addons" />,
    <IntlText group="new-product" id="steps-product-tags" />,
    <IntlText group="new-product" id="steps-confirm" />
  ]

  const navigate = useNavigate()
  const { id } = useParams()
  const [selected, change] = useState(1)
  const {loading, error, data} = useQuery(PRODUCT_QUERY, {variables: {id}, skip: !id})

  if (loading) return <Loader theme="main" />
  if (error) console.log("error", error)

  function getActiveView(){
    let _product = data?.product

    switch (selected) {
      case 1: return(
        <div>
          <ProductDetails product={_product} onSuccess={_createProduct} buttonText={<IntlText id="next" />}/>
        </div>
      )
      case 2: return(
        <div>
          <ProductAddons product={_product} />
          <div className="actions">
            <Button theme="alt" onClick={() => change(selected-1)}><IntlText id="back" /></Button>
            <Button theme="main" onClick={() => change(selected+1)}><IntlText id="next" /></Button>
          </div>
        </div>
      )
      case 3: return(
        <div>
          <ProductTagEditor product={_product} />
          <div className="actions">
            <Button theme="alt" onClick={() => change(selected-1)}><IntlText id="back" /></Button>
            <Button theme="main" onClick={() => change(selected+1)}><IntlText id="next" /></Button>
          </div>
        </div>
      )
      case 4: return(
        <div>
          <div className="product-confirm">
            <div className="review">
              <ProductCardPreview product={_product} />
              <div>
                <Title tag="h3" className="block-title"><IntlText group="new-product" id="product-status-title"/></Title>
                <ProductStatus product={_product} />
              </div>
            </div>
            <div className="addons-review">
              <Title tag="h3" className="block-title"><IntlText group="new-product" id="addons-title"/></Title>
              {_product?.addons?.map(({addon: _addon, options: _options}, key) =>
                <ReviewProductAddon key={key} addon={_addon} options={_options} />
              )}
            </div>
          </div>
          <div className="actions">
            <Button theme="alt" onClick={() => change(selected-1)}><IntlText id="back" /></Button>
            <Button theme="main" onClick={() => navigate("/products")}><IntlText id="done" /></Button>
          </div>
        </div>
      )
      default : return null
    }
  }

  function _createProduct(prod){
    navigate(`/products/new/${prod.id}`)
    change(2)
  }

  return(
    <div id="new-product" className="container">
      <div className="row padding">
        <ProgressSteps navigationEnabled={false} items={items} selected={selected} change={change} />
        <div className="content">
            {getActiveView()}
        </div>
      </div>
    </div>
  )

}
