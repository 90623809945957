import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Button, Title, Actions, IntlText } from '../../components'

import './OptionList.css'

export default function OptionList({options, selected, onChange, children}) {

  function addOption(_option){
    let data = {
      "options": options.filter((i) => i.id !== _option.id),
      "selected": selected.concat(_option)
    }

    onChange(data)
  }

  function deleteOption(_option){
    let data = {
      "options": options.concat(_option),
      "selected": selected.filter((i) => i.id !== _option.id)
    }

    onChange(data)
  }

  return(
    <div className="option-list">
      <div className="options selected-options">
        <Title tag="h3"><IntlText group="option-list" id="selected">Selected</IntlText></Title>
        {selected && selected.length > 0 ? selected.map((_option, key) =>
          <div key={_option.id} className="option">
            {children && children(_option)}
            <Actions>
              <Button onClick={() => deleteOption(_option)}><FontAwesome name="trash" /><IntlText id="delete">Delete</IntlText></Button>
            </Actions>
          </div>
        ) : <IntlText group="option-list" id="any-selected">No option added</IntlText>}
      </div>
      <div className="options available-options">
        <Title tag="h3"><IntlText group="option-list" id="available">Available</IntlText></Title>
        {options && options.length > 0 ? options.map((_option, key) =>
          <div key={_option.id} className="option">
            {children && children(_option)}
            <Actions>
              <Button onClick={() => addOption(_option)}><FontAwesome name="plus" /><IntlText id="add">Add</IntlText></Button>
            </Actions>
          </div>
        ) : <IntlText group="option-list" id="any-left">No options to add</IntlText>}
      </div>
    </div>
  )
}
