import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { EDIT_PRODUCT_ADDON_MUTATION, PRODUCT_QUERY } from '../../../graphql'
import { returnGraphqlError } from '../../../helpers'
import { useValidatedForm } from '../../../hooks'
import { ModalContext } from '../../../contexts'
import { Modal, Button, Actions, IntlText } from '../../../components'
import { AddProductAddonOption, AddonOption } from '../../../views'

export function AddProductAddonOptionModal() {

  const [newOption, toggleNewOption] = useState(false)
  const [editProductAddon, { loading: editLoading }] = useMutation(EDIT_PRODUCT_ADDON_MUTATION)
  const { attributes, closeModal } = useContext(ModalContext)
  const { form: {options}, updateField } = useValidatedForm({options: attributes?.options})

  useEffect(() => {
    updateField({key: "options", value: attributes?.options})
    // eslint-disable-next-line
  }, [attributes])


  async function save(){
    try {
      let data = {
        options: options ? options.map((option) => option.id) : []
      }
      await editProductAddon({variables: {id: attributes?.productAddonId, data}, refetchQueries: [{query: PRODUCT_QUERY, variables: {id: attributes.id}}]})
      closeModal()
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  return(
    <Modal.Modal id="add-product-addon-option">
      {({attributes}) =>
        <Modal.Info>
          <Modal.Header><IntlText group="add-product-addon-option-modal" id="title" /></Modal.Header>
          {newOption ? 
            <Modal.Content>
              <AddonOption success={() => toggleNewOption(false)} cancel={() => toggleNewOption(false)} />
            </Modal.Content>
          :
            <>
              <Modal.Content>
                <AddProductAddonOption owner={attributes?.addon?.owner} options={options} onChange={(data) => updateField({key: "options", value: data.selected})}/>
                <span id="add-new-addon-button" onClick={() => toggleNewOption(true)}><FontAwesome name="plus-circle" /><IntlText group="add-product-addon-modal" id="add-new-option" /></span>
              </Modal.Content>
              <Modal.Footer>
                <Actions fluid>
                  <Button onClick={closeModal}><IntlText id="back" /></Button>
                  <Button loading={editLoading} theme="main" onClick={save}><IntlText id="save" /></Button>
                </Actions>
              </Modal.Footer>
            </>
          }
        </Modal.Info>
      }
    </Modal.Modal>
  )
}
