import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { useValidatedForm } from '../../../hooks'
import { getCommonAsset } from '../../../helpers'
import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { LabeledInput, Button, IntlText, Validation, Form, Select, SwitchBox } from '../../../components'

const validations = {
  "name": [{id: "required", validation: (val) => !!val}, {id: "validName", validation: (val) => validateName(val)}],
  "rfc": [{id: "required", validation: (val) => !!val}, {id: "rfc", validation: (val) => validateRfc(val)}],
  "email": [{id: "required", validation: (val) => !!val}, {id: "email", validation: (val) => validateEmail(val)}],
  "city": [{id: "required", validation: (val) => !!val}],
  "zip": [{id: "required", validation: (val) => !!val}],
  "state": [{id: "required", validation: (val) => !!val}],
  "taxSystem": [{id: "required", validation: (val) => !!val}],
}

function validateEmail(str) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase())
}

function validateRfc(str) {
    const re = /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/;
    return re.test(String(str).toLowerCase())
}

function validateName(str) {
    const re = /^((?!\ss(.*)a(.*)\s|\ss(.*)c(.*)\s|\ss(.*)de(.*)\s|\ss(.*)en(.*)\s).)*$/;
    return re.test(String(str).toLowerCase())
}

export function InvoicingDetails({vendor, onSuccess}) {

  const { getTranslation } = useContext(IntlContext)
  const [editVendor, {loading}] = useMutation(EDIT_VENDOR_MUTATION)
  const { form: {name, rfc, email, street, exterior, interior, neighborhood, city, zip, municipality, state, taxSystem}, updateField, validate, errors, done } = useValidatedForm({...vendor?.invoicing}, validations)
  const states = getCommonAsset('states.json')
  const taxSystems = getCommonAsset('tax-systems.json')

  async function save(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

    let data = {
      invoicing:{
        name,
        rfc,
        email,
        street,
        exterior,
        interior,
        neighborhood,
        city,
        zip,
        municipality,
        state: state,
        taxSystem: taxSystem
      }
    }

    try {
      await editVendor({variables: {id: vendor.id, data}})
      toast.success(getTranslation({id: "save-success"}))
      if (onSuccess) onSuccess()
      done()
    } catch (e) {
      console.log("e", e);
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function toggle(data){
    try {
      const { valid } = validate()

      if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))
      
      await editVendor({variables: {id: vendor.id, data}})
      toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      console.log("e", e);
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="invoicing-details">
      <Form.Form>
        <Form.Field>
          <SwitchBox id="needsInvoiceOfServicefee" label={<IntlText group="invoicing-details" id="needsInvoiceOfServicefee-desc" />} checked={vendor.needsInvoiceOfServicefee} onChange={(e) => toggle({[e.target.id]: e.target.checked})}>
            <IntlText group='invoicing-details' id='needsInvoiceOfServicefee' />
          </SwitchBox>                 
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.name}>
            <LabeledInput name="name" placeholder={getTranslation({group: "invoicing-details", id: "input-name"})} value={name} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.rfc}>
            <LabeledInput name="rfc" placeholder={getTranslation({group: "invoicing-details", id: "input-rfc"})} value={rfc} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.email}>
            <LabeledInput name="email" placeholder={getTranslation({group: "invoicing-details", id: "input-email"})} value={email} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
          <Validation errors={errors.street}>
            <LabeledInput name="street" placeholder={getTranslation({group: "invoicing-details", id: "input-street"})} value={street} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        <Form.Field>
          <Validation errors={errors.exterior}>
            <LabeledInput name="exterior" placeholder={getTranslation({group: "invoicing-details", id: "input-exterior"})} value={exterior} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.interior}>
            <LabeledInput name="interior" placeholder={getTranslation({group: "invoicing-details", id: "input-interior"})} value={interior} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.neighborhood}>
            <LabeledInput name="neighborhood" placeholder={getTranslation({group: "invoicing-details", id: "input-neighborhood"})} value={neighborhood} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.city}>
            <LabeledInput name="city" placeholder={getTranslation({group: "invoicing-details", id: "input-city"})} value={city} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.zip}>
            <LabeledInput name="zip" placeholder={getTranslation({group: "invoicing-details", id: "input-zip"})} value={zip} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.municipality}>
            <LabeledInput name="municipality" placeholder={getTranslation({group: "invoicing-details", id: "input-municipality"})} value={municipality} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.state}>
            <Select placeholder={getTranslation({group: "invoicing-details", id: "input-state"})} selected={states.find(s => s.abbreviation === state)} defaultValue={getTranslation({id: "please-select"})} options={states} labelId="name" onChange={(_data) => updateField({key: "state", value:  _data.abbreviation})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.taxSystem}>
            <Select placeholder={getTranslation({group: "invoicing-details", id: "input-taxSystem"})} selected={taxSystems.find(_ts => _ts.id === taxSystem)} defaultValue={getTranslation({id: "please-select"})} options={taxSystems} labelId="name" onChange={(_data) => updateField({key: "taxSystem", value:  _data.id})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Button theme="main" className="full" loading={loading} onClick={save}><IntlText id="save" /></Button>
        </Form.Field>        
      </Form.Form>
    </div>
  )
}
