import { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_CATEGORY_MUTATION, REORDER_PRODUCT_CATEGORIES_MUTATION, DELETE_CATEGORY_MUTATION } from '../../../graphql'
import { IntlContext, ModalContext } from '../../../contexts'
import { useConfirm } from '../../../hooks'
import { toast } from '../../../helpers'
import { LabeledCheckbox, ContextMenu, Loader, IntlText, IntlValue, Filter, Table, StatusDot, Sortable, Restricted } from '../../../components'

export function CategoyList({data, refetch, filter}) {

  const { confirm } = useConfirm()
  const { getTranslation, getTranslatedValue } = useContext(IntlContext)
  const { openModal } = useContext(ModalContext)
  const [deleteCategory, { loading: deleteLoading }] = useMutation(DELETE_CATEGORY_MUTATION)
  const [editProductCategory, { loading: editLoading }] = useMutation(EDIT_CATEGORY_MUTATION)
  const [reorder, { loading: reorderLoading }] = useMutation(REORDER_PRODUCT_CATEGORIES_MUTATION)

  async function _deleteCategory(id){
    try {
      await confirm()
      await deleteCategory({variables: {id}})
      refetch()
      toast.success(getTranslation({id: "delete-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _editProductCategory(data){
    const alert = toast.loading()
    try {
      await editProductCategory(data)
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.apolloError(e)
    }    
  }

  async function onSort(orderedItems){
    try {
      await reorder({variables: {data: orderedItems}})
      refetch()
      toast.success(getTranslation({id: "order-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  const renderHeader = () => (
    <>
      <Table.Th></Table.Th>
      <Table.Th></Table.Th>
      <Table.Th className="text-left"><IntlText group="table-header" id="name" /></Table.Th>
      <Table.Th><IntlText group="table-header" id="disabled" /></Table.Th>
      <Table.Th><IntlText group="table-header" id="hidden" /></Table.Th>
      <Table.Th></Table.Th>
    </>
  )  

  const renderItem = (category) => (
    <>
      <Table.Td className="status"><StatusDot active={!(category.disabled || category.hidden)} /></Table.Td>
      <Table.Td className="text-left"><IntlValue value={category.name} /></Table.Td>
      <Table.Td centered><LabeledCheckbox id={`disabled-${category.id}`} checked={!category.disabled} onChange={({target}) => _editProductCategory({variables: {id: category.id, data: {disabled: !target.checked}}})} rounded/></Table.Td>
      <Table.Td centered><LabeledCheckbox id={`hidden-${category.id}`} checked={!category.hidden} onChange={({target}) => _editProductCategory({variables: {id: category.id, data: {hidden: !target.checked}}})} rounded/></Table.Td>
      <Table.Td>
        <Restricted>
          <ContextMenu.Menu id={category?.id} >
            <ContextMenu.Link to={`/categories/${category.id}`} icon="external-link-alt"><IntlText id="edit" /></ContextMenu.Link>
            <ContextMenu.Button icon="edit" onClick={() => openModal("category", {category})}><IntlText id="quick-edit" /></ContextMenu.Button>
            <ContextMenu.Button icon="trash" className="invalid" onClick={() => _deleteCategory(category.id)}><IntlText id="delete" /></ContextMenu.Button>
          </ContextMenu.Menu>
        </Restricted>
      </Table.Td>
    </>
  )

  return(
    <div id="categories-list" className="relative">
      {(!!deleteLoading || !!reorderLoading || !!editLoading) && <div className="full-size-loader"><Loader theme="main"/></div>}
      <Filter id="categories" data={data?.categories || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => data.id}, {filter: (data) => getTranslatedValue(data.name)}, {filter: (data) => data.disabled}]}>
        {(data) =>
          <Sortable.Table data={data} onSort={onSort} renderItem={renderItem} renderHeader={renderHeader} />
        }
      </Filter>
    </div>
  )
}
