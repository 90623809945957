import { gql }from '@apollo/client'

const PURCHASE_PRICE_FRAGMENT = gql`
  fragment PURCHASE_PRICE_FRAGMENT on PurchasePrice {
    subtotal
    grossSubtotal
    serviceFeeCustomer
    serviceFeeVendor
    vendorPrice
    total
    discount
    deliveryFeeBase
    deliveryFeeDistance
    deliveryFeeTotal
    transactionFee
    tax
    discount
    total
    totalRevenue
    vendorCashBalanceAdjustment
    vendorCashPayment
    vendorPriceAdjustmentByCustomerCashBalance
    riderCashCreditBalanceAdjustment
    vendorPriceAdjustmentByRiderCashCreditBalance
  }
`

export default PURCHASE_PRICE_FRAGMENT
