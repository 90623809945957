import { gql }from '@apollo/client'

export const VENDOR_BOOK_FRAGMENT = gql`
  fragment VENDOR_BOOK_FRAGMENT on VendorBook {
    id
    owner
    cashBalance
    createdAt
    updatedAt
  }
`
