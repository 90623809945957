import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { useAuth } from '../../hooks'
import { IntlText, Loader, Sidebar, SidebarMenu } from '../../components'
import { VendorUser, NewVendorUser, VendorUsers, VendorSettings, VendorMedia, BasicDetails, VendorOpeningHours, VendorPaymentSettings, Preview, DeliverySettings, InvoicingDetails, ContactDetails, VendorLocation } from '../../views'

export function Vendor() {

  const { user, loading, error } = useAuth()

  if (loading || error) return <Loader theme="main" />

  return(
    <div id="vendor" className="container">
      <div className="row padding">
        <Preview />
      </div>
      <div className="row padding">
        <Sidebar.View>
          <Sidebar.Menu size={1}>
            <SidebarMenu.Menu bordered>
              <SidebarMenu.Link to="" end={true}><IntlText group="settings" id="tab-menu-details" /></SidebarMenu.Link>
              <SidebarMenu.Link to="contact"><IntlText group="settings" id="tab-menu-contact" /></SidebarMenu.Link>
              <SidebarMenu.Link to="invoicing"><IntlText group="settings" id="tab-menu-invoicing" /></SidebarMenu.Link>
              <SidebarMenu.Link to="operation-settings"><IntlText group="settings" id="tab-menu-settings" /></SidebarMenu.Link>
              <SidebarMenu.Link to="location"><IntlText group="settings" id="tab-menu-location" /></SidebarMenu.Link>
              <SidebarMenu.Link to="opening-hours"><IntlText group="settings" id="tab-menu-opening-hours" /></SidebarMenu.Link>
              <SidebarMenu.Link to="payment"><IntlText group="settings" id="tab-menu-payment-settings" /></SidebarMenu.Link>
              <SidebarMenu.Link to="delivery"><IntlText group="settings" id="tab-menu-delivery" /></SidebarMenu.Link>
              <SidebarMenu.Link to="media"><IntlText group="settings" id="tab-menu-media" /></SidebarMenu.Link>
              <SidebarMenu.SubMenu label={<IntlText group="settings" id="tab-menu-users" />} path="settings/users">
                <SidebarMenu.Link to="users"><IntlText group="settings" id="tab-menu-all-users" /></SidebarMenu.Link>
                <SidebarMenu.Link to="users/new"><IntlText group="settings" id="tab-menu-new-user" /></SidebarMenu.Link>
              </SidebarMenu.SubMenu>                  
            </SidebarMenu.Menu>
          </Sidebar.Menu>
          <Sidebar.Content size={3}>
            <Routes>
              <Route path="" element={<BasicDetails vendor={user} />} />
              <Route path="contact" element={<ContactDetails vendor={user} />} />
              <Route path="invoicing" element={<InvoicingDetails vendor={user} />} />
              <Route path="operation-settings" element={<VendorSettings vendor={user} />} />
              <Route path="location" element={<VendorLocation vendor={user} />} />
              <Route path="opening-hours" element={<VendorOpeningHours vendor={user} />} />
              <Route path="payment" element={<VendorPaymentSettings vendor={user} />} />
              <Route path="delivery" element={<DeliverySettings vendor={user} />} />
              <Route path="media" element={<VendorMedia vendor={user} />} />
              <Route path="users">
                <Route path="" element={<VendorUsers />} />
                <Route path=":id" element={<VendorUser />} />
                <Route path="new" element={<NewVendorUser />} />
              </Route>                  
            </Routes>
          </Sidebar.Content>
        </Sidebar.View>
      </div>
    </div>
  )
}
