import moment from 'moment'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'

import client from './client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'react-toastify/dist/ReactToastify.css'
import 'tippy.js/dist/tippy.css'
import './style/index.css'

moment.updateLocale("en", { week: {
  dow: 1, // First day of week is Monday
}})


window.onbeforeunload = function () {
  window.scrollTo({
    top: 0,
    left: 0
  })
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <ApolloProvider client={client}>
      <App />
  </ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister()
