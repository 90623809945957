import FontAwesome from 'react-fontawesome'
import { useQuery } from '@apollo/client'

import { VENDOR_QUERY } from '../../graphql'
import { useAuth } from '../../hooks'
import { IntlText, Link } from '../../components'
import { WhatsAppHelp } from '../../views'


import './style.css'

const alerts = {
  suspended: {color: "error"},
  technicalBreak: {color: "error"},
  onVacation: {color: "error"},
  slow: {color: "warning"}
}

export function AlertBar() {
  
  const { user } = useAuth()
  const id = user?.role === "VENDOR_USER" ? user?.vendor : user?.id
  const { loading, data, error } = useQuery(VENDOR_QUERY, {variables: {id}})
  
  function getAlertType(){
    if (data?.vendor?.suspended) return "suspended"
    if (data?.vendor?.technicalBreak) return "technicalBreak"
    if (data?.vendor?.onVacation) return "onVacation"
    
    return false
  }
  
  if (loading || error) return null

  const alert = alerts[getAlertType()]

  if (alert) return(
    <div id="alert-bar" className={`alert-bar ${alert.color} active`}>
      <div className="icon"><FontAwesome name="exclamation-triangle" /></div>
      <div className="message">
        <div className="title"><IntlText group="alert-bar" id="warning" /></div>
        <IntlText group="alert-bar" id={getAlertType()} />
      </div>
      <div className='button-wrapper'>
        {getAlertType() === "suspended" || user?.role === "VENDOR_USER" ?
          <WhatsAppHelp />
        :
          <Link to="/settings/operation-settings" button><IntlText group="alert-bar" id="button"/></Link>
        }
      </div>
    </div>
  )

  return null
}
