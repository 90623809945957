import React from 'react'

import { useAuth } from '../../hooks'
import { Loader } from '../../components'
import { VendorTerms } from '../../views'

export function Terms() {

  const { user, loading, error} = useAuth()

  if (loading || error) return <Loader theme="main" />

  return(
    <div className="container">
      <div className="row padding">
        <div className="relative">
          <VendorTerms vendor={user} hideActions />
        </div>
      </div>
    </div>
  )
}
