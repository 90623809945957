import React from 'react'
import ReactModal from 'react-modal'

import { CloseButton } from '../../components'

import style from '../../style/modal'
import './style.css'

export function Modalv3({id = "", fluid, header, children, footer, disableClose, contentStyle, onHide, modal = {}}){

  if (!modal.opened) return null

  function _onHide(){
    if (onHide) onHide()
    modal.hide()
  }

  return(
    <ReactModal isOpen={modal.opened} style={style} onRequestClose={!disableClose ? _onHide : null} appElement={document.getElementById('root')}>
      <div id={`${id}-modal`} className={`modal ${fluid && "full"}`}>
        <div className="modal-info">
          {header &&
            <div className="modal-header">
              {header}
              {!disableClose && <CloseButton onClick={modal.hide}/>}
            </div>
          }
          <div className="modal-content" style={contentStyle}>
            {children}
          </div>
          {footer &&
            <div className="modal-footer">
              {footer}
            </div>
          }
        </div>
      </div>
    </ReactModal>
  )
}
