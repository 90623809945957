import config from '../config'
import client, { wsLink } from '../client'
// import { Notifications } from '../components'
import { IS_LOGGED_IN } from '../graphql'

export class AuthKit {

  static async login(user, token){
    try {
      localStorage.setItem(config.userID, user.id)
      localStorage.setItem(config.authToken, token)
      // Notifications.start()
      await wsLink.subscriptionClient.connect()
    } catch (error) {
      console.log("Login error", error) 
    }    
  }

  static logout(){
    try {
      localStorage.removeItem(config.userID)
      localStorage.removeItem(config.authToken)
      client.cache.reset()
      client.writeQuery({
        query: IS_LOGGED_IN,
        data: { isLoggedIn: false }
      })
      // Notifications.clear()
    } catch (error) {
      console.log("Login error", error) 
    }  
  }
}
