import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { formatPrice } from '../../helpers'
import { SERVICE_FEE_PROFORMA_INVOICE } from '../../graphql'
import { IntlText, Table, Loader, Button, Badge, Tooltip } from '../../components'

export function Invoice() {
  
  const { invoiceID } = useParams()
  const { loading, data, error } = useQuery(SERVICE_FEE_PROFORMA_INVOICE, {variables: {id: invoiceID}})

  if (loading || error) return <Loader theme="main" />

  const { serviceFeeProformaInvoice } = data

  return(
    <div id="invoice" className="container">
      <div className="row padding">
        <Table.Table>
          <Table.Body>
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="reference" />
              </Table.Th>
              <Table.Td className='text-right'>
                {serviceFeeProformaInvoice?.reference}
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="netInvoiceAmount" />
              </Table.Th>
              <Table.Td className='text-right'>
                {formatPrice(serviceFeeProformaInvoice.grossInvoiceAmount)}
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="periodicity" />
              </Table.Th>
              <Table.Td className='text-right'>
                <IntlText group="vendor-service-fee-invoices" id={serviceFeeProformaInvoice?.periodicity} />
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="paymentTermDays" />
              </Table.Th>
              <Table.Td className='text-right'>
                {serviceFeeProformaInvoice?.paymentTermDays}
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="created-at" />
              </Table.Th>
              <Table.Td className='text-right'>
                {moment(serviceFeeProformaInvoice?.createdAt).format("MM/DD/YYYY HH:mm")}
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="due-at" />
              </Table.Th>
              <Table.Td className='text-right'>
                {moment(serviceFeeProformaInvoice?.dueAt).format("MM/DD/YYYY HH:mm")}
                {serviceFeeProformaInvoice.overdue &&
                  <Badge style={{marginLeft: 10}} icon="exclamation-triangle" theme='invalid'><IntlText group="vendor-service-fee-invoices" id="overdue" /></Badge>
                }
              </Table.Td>
            </Table.Tr>
            {serviceFeeProformaInvoice.approvedAt &&
              <Table.Tr>
                <Table.Th className='text-left'>
                  <IntlText group="vendor-service-fee-invoices" id="approved-at" />
                </Table.Th>
                <Table.Td className='text-right'>
                  {moment(serviceFeeProformaInvoice?.approvedAt).format("MM/DD/YYYY HH:mm")}
                </Table.Td>
              </Table.Tr>
            }
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="status" />
              </Table.Th>
              <Table.Td className='text-right'>
                <Badge theme={serviceFeeProformaInvoice.status === "PAID" ? "valid" : serviceFeeProformaInvoice.status === "WAITING_FOR_PAYMENT" ? "warning" : null}><IntlText group="vendor-service-fee-invoices" id={serviceFeeProformaInvoice.status} /></Badge>
                {serviceFeeProformaInvoice.overdue &&
                  <Badge style={{marginLeft: 10}} icon="exclamation-triangle" theme='invalid'><IntlText group="vendor-service-fee-invoices" id="overdue" /></Badge>
                }     
              </Table.Td>
            </Table.Tr>          
            {!serviceFeeProformaInvoice.approvedAt && 
              <Table.Tr>
                <Table.Th className='text-left'>
                  <IntlText group="vendor-service-fee-invoices" id="last-email-sent" />
                </Table.Th>
                <Table.Td className='text-right'>
                  <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    {serviceFeeProformaInvoice?.lastEmailSent ?
                      <span style={{marginRight: 10}}>{moment(serviceFeeProformaInvoice.lastEmailSent).format("MM/DD/YYYY HH:mm")}</span>
                    : 
                      <span style={{marginRight: 10}}><IntlText group="vendor-service-fee-invoices" id="email-not-sent" /></span>
                    }
                  </div>
                </Table.Td>
              </Table.Tr>
            }
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="proformaLink" />
              </Table.Th>
              <Table.Td className='text-right'>
                <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                  {serviceFeeProformaInvoice?.proformaLink ?
                    <Button><a href={serviceFeeProformaInvoice.proformaLink} target="_blank" rel='noreferrer'><IntlText id="download" /></a></Button>
                  :
                    <IntlText group="vendor-service-fee-invoices" id="not-generated-yet" />
                  }
                </div>
              </Table.Td>
            </Table.Tr>                  
            <Table.Tr>
              <Table.Th className='text-left'>
                <IntlText group="vendor-service-fee-invoices" id="invoiceLink" />
              </Table.Th>
              <Table.Td className='text-right'>
                <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>               
                  {serviceFeeProformaInvoice?.invoiceLink ?
                    <>
                      <Button><a href={serviceFeeProformaInvoice.invoiceLink}><IntlText id="download" /></a></Button>
                      <Tooltip text={moment(serviceFeeProformaInvoice?.invoiceCreatedAt).format("MM/DD/YYYY HH:mm")}>
                        <Badge style={{marginLeft: 10}} icon="check" theme='valid'><IntlText group="vendor-service-fee-invoices" id="invoice-created-at" /></Badge>
                      </Tooltip>   
                    </>
                  :
                    <IntlText group="vendor-service-fee-invoices" id="not-generated-yet" />
                  }
                </div>
              </Table.Td>
            </Table.Tr>          
          </Table.Body>
        </Table.Table>
      </div>
    </div>
  )
}
