import { toast } from 'react-toastify'

export function returnGraphqlError(e, log) {
  if (e?.graphQLErrors){
    e.graphQLErrors.forEach(x => {
      toast.error(x.message)
      if (log) console.log(`Error: ${x.message}`)
    })
  } else {
    console.log(`Error: ${e}`)
  }

  return null
}
