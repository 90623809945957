import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { PRODUCTS_QUERY } from '../../graphql'
import { useQueryBuilder } from '../../hooks'
import { Filters, Loader, PriorityBar, IntlValue } from '../../components'
import { ProductsList, CategoryFilter  } from '../../views'

export function Products(){

  const navigate = useNavigate()
  const location = useLocation()
  const search = new URLSearchParams(location?.search)
  const [filter, setFilter] = useState()
  const { params, getParams, removeParam, buildQuery, runQuery, clearParams } = useQueryBuilder({
    data:{
      category: {value: search.get("category"), id: "category", label: search.get("category"), skip: !search.get("category")}
    }
  })
  const { data, loading, refetch } = useQuery(PRODUCTS_QUERY, {variables: buildQuery(), fetchPolicy: "network-only"})

  function setSearch(str){
    if (str === "") return clearSearch()
    search.set("search", str)
    navigate({search: search.toString()})
    setFilter(str)
  }

  function clearSearch(){
    search.delete("search")
    navigate({search: search.toString()})
    setFilter("")
  }

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  const navigationItems = [
    <div key="query" className="left">
      <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setSearch(target.value)} reset={() => clearSearch()} placeholder="Type here for searching" onKeyDown={onSearch}/>
    </div>,
    <div key="in-progress">
      <CategoryFilter category={params?.category} reset={() => removeParam("category")} onSelect={(_category) => runQuery({instantParams: {category: {value: _category?.id, id: "category", label: <IntlValue value={_category?.name} />, skip: !_category?.id}}})} />
    </div>
  ]

  return(
    <div id="products" className="container">
      {loading && <Loader theme="main" overlay/>}
      <div className="row padding">
        <Filters.Bar vertical>
          <PriorityBar navigationItems={navigationItems} itemMinWidth={150} />
          <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams} />
        </Filters.Bar>
        <ProductsList data={data} refetch={refetch} filter={filter}/>
      </div>
    </div>
  )
}
