import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { FAQS } from '../../graphql'
import { Heading, Loader, Button, IntlText } from '../../components'
import { Help as HelpView } from '../../views'

const topics = {
  "purchases": "VENDOR_PURCHASE",
  "get-started": "VENDOR_ONBOARDING"
}

export function Help() {

  const { id } = useParams()
  const navigate = useNavigate()
  const { loading, data, error } = useQuery(FAQS, {variables: {topic: topics[id]}})

  if (error) return `Error! ${error.message}`

  return(
    <div className="relative">
      <Heading spacing info={<IntlText group="help" id={`${topics[id]}-desc`} />}>
        <IntlText group='help' id={`${topics[id]}-title`} />
      </Heading>            
      {loading && <Loader theme="main" overlay/>}
      <HelpView items={data?.faqItems} />
      <div className='actions' style={{marginTop: 30}}>
        <Button onClick={() => navigate("../")}><IntlText id="back" /></Button>
      </div>          
    </div>        
  )
}
