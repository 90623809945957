import React from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { OrdersList, HistoryFilter } from '../../views'
import { Loader, Pagination } from '../../components'
import { MY_PURCHASES_PAGINATED_QUERY } from '../../graphql'
import { useQueryBuilder } from '../../hooks'

export function OrdersHistory() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { params, addParam, removeParam, clearParams, isQuery, buildQuery, runQuery, updateQuery, setPage, page, sort, setSort } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${search.getAll("date")[0]} - ${search.getAll("date")[1]}`, value: search.getAll("date")?.length === 2 ? [search.getAll("date")[0], search.getAll("date")[1]] : undefined, skip: !search.get("date")},
      rider: {key: "rider", label: `${search.get("rider")}`, value: search.get("rider")},
      status: {key: "status", label: `${search.get("status")}`, value: search.get("status")},
      payment: {key: "payment", label: `${search.get("payment")}`, value: search.get("payment")},
      query: {key: "query", label: `Search for ${search.get("query")}`, value: search.get("query")}
    },
    page: search.get("page"),
    sort: search.get("sort")
  })
  const { data, loading, error } = useQuery(MY_PURCHASES_PAGINATED_QUERY, {variables: buildQuery(), skip: !isQuery(), notifyOnNetworkStatusChange: true})

  if (error) return `Error! ${error.message}`

  return(
    <div id="orders-history" className="container">
      <div className="row padding">
        <HistoryFilter params={params} updateQuery={updateQuery} addParam={addParam} removeParam={removeParam} clearParams={clearParams} sort={sort} setSort={setSort} runQuery={runQuery}/>
        <div className="relative">
          {loading && <Loader theme="main" overlay/>}
          <OrdersList data={data?.myPurchasesPaginated?.nodes || []} />
          <Pagination page={page} setPage={setPage} pageInfo={data?.myPurchasesPaginated?.pageInfo}/>
        </div>
      </div>
    </div>
  )
}
