import { gql }from '@apollo/client'

import { PURCHASES_FRAGMENT } from './'

export const MY_PURCHASES_PAGINATED_QUERY = gql`
  query myPurchasesPaginated($date: [String!], $status: [PURCHASE_STATUS!], $limit: Int, $offset: Int, $type: PURCHASE_TYPE, $sort: [PURCH_SORT_CRITERIA], $payment: PAYMENT_METHOD, $query: String){
     myPurchasesPaginated(date: $date, status: $status, limit: $limit, offset: $offset, type: $type, sort: $sort, payment: $payment, query: $query) {
       nodes{
         ...PURCHASES_FRAGMENT
       }
       pageInfo{
         limit
         totalNodes
         firstNode
         lastNode
         totalPages
         actualPage
         hasNextPage
       }
     }
  }
  ${PURCHASES_FRAGMENT}
`
