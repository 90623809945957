import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { IntlContext } from '../../../contexts'
import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { IntlText, Form, SwitchBox } from '../../../components'

export function DeliverySettings({vendor}) {

  const { getTranslation } = useContext(IntlContext)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)

  async function changeSwitch(data){
    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      return toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function toggleEco(_checked){
    let _tags = vendor.tags?.length > 0 ? vendor.tags : []

    if (_checked) {
      _tags = _tags.concat("bio")
    } else {
      _tags = _tags.filter(_a => _a !== "bio")
    }

    try {
      await editVendor({variables: {id: vendor.id, data: {tags: _tags}}})
      return toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="vendor-delivery-settings">
      <Form.Form>
        <Form.Field>
          <SwitchBox id="utensilsAvailable" label={<IntlText group="vendor-delivery-settings" id="utensils-available-desc" />} checked={vendor.utensilsAvailable} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
            <IntlText group='vendor-delivery-settings' id='utensils-available' />
          </SwitchBox>          
        </Form.Field>
        <Form.Field>
          <SwitchBox id="ecopackage" label={<IntlText group="vendor-delivery-settings" id="eco-package-desc" />} checked={!!vendor.tags?.includes("bio")} onChange={!!vendor.tags?.includes("disabledEcoSettings") ? () => toast.error(getTranslation({group: "vendor-delivery-settings", id: "disabled-eco"})) : (e) => toggleEco(e.target.checked)}>
            <IntlText group='vendor-delivery-settings' id='eco-package' />
          </SwitchBox>                 
        </Form.Field>
      </Form.Form>
    </div>
  )
}
