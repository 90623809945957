import React, { useState, useRef } from 'react'
import { node } from 'prop-types'

const ModalContext = React.createContext()
const ModalConsumer = ModalContext.Consumer

function ModalProvider({children}) {

  const [modal, toggleModal] = useState(null)
  const [attributes, setAttributes] = useState({})
  const modals = {
    verifyModal: useRef(null),
    confirmContainersModal: useRef(null),
    termsModal: useRef(null),
    confirmInvoicingDetailsModal: useRef(null),
  }

  function isOpened(name){
    return modal === name
  }

  function openModal(name, attributes = {}){
    toggleModal(name)
    setAttributes(attributes)
  }

  function closeModal(){
    toggleModal(null)
    setAttributes({})
  }

  return (
    <ModalContext.Provider
      value={{
        modal,
        isOpened,
        openModal,
        closeModal,
        attributes,
        modals
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: node.isRequired
}

export { ModalContext, ModalProvider, ModalConsumer }
