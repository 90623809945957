const defaultCenter = () => {
  switch (process.env.REACT_APP_APP_NAME) {
    case "besmart":
      return({
        lat: parseFloat(20.501279),
        lng: parseFloat(-87.228575)        
      })
    default:
      return({
        lat: parseFloat(20.2114185),
        lng: parseFloat(-87.465350)        
      })
  }
}

const config = {
  siteName: process.env.REACT_APP_SITE_NAME || "Tomato.mx - Partner",
  home: process.env.REACT_APP_HOME || "https://vendor.tomato.mx",
  clientHome: process.env.REACT_APP_CLIENT_HOME || "https://tomato.mx",
  remoteUrl: process.env.REACT_APP_REMOTE_URL,
  subscriptionUrl: process.env.REACT_APP_SOCKET_URL,
  authToken: `${process.env.REACT_APP_APP_NAME || "tomatomx"}-vendor-user-token`,
  userID: `${process.env.REACT_APP_APP_NAME || "tomatomx"}-vendor-user-id`,
  defaultCenter: defaultCenter()
}

export default config