import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { Filters, IntlValue } from '../../../components'
import { CATEGORIES_QUERY } from '../../../graphql'
import { IntlContext } from '../../../contexts'

import './style.css'

export function CategoryFilter({vendor, category, onSelect, reset}){

  const {loading, error, data} = useQuery(CATEGORIES_QUERY, {variables: {owner: vendor}})
  const { getTranslation } = useContext(IntlContext)

  if (loading) return null
  if (error) return null

  const { categories } = data

  return(
    <Filters.Select value={category?.label} placeholder={getTranslation({group: "categories-select", id: "placeholder"})} reset={reset}>
      {categories.map((_category, key) =>
        <li key={key} onClick={() => onSelect(_category)}><span><IntlValue value={_category.name} /></span></li>
      )}
    </Filters.Select>
  )

}
