import { gql }from '@apollo/client'

const DELETE_PRODUCT_MUTATION = gql`
  mutation deleteProduct($id: ID!){
    deleteProduct(id: $id){
      success
    }
  }
`

export default DELETE_PRODUCT_MUTATION
