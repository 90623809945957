import { useState, useLayoutEffect, useRef } from 'react'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { useModal } from '../../hooks'
import { toast } from '../../helpers'
import { Button, DatePicker, Modalv3, Title, IntlText } from '../'

import './style.css'

export function Text({placeholder, type = "textarea", text, children, save, actions, icon, disabled, validation}) {

  const [edit, toggleEdit] = useState(false)
  const [value, setValue] = useState(children || text)
  const element = useRef(null)

  function _save(){
    try {
      if (validation) validation(value)
      save(value)
      toggleEdit(false)

      if (text) setValue(text)
    } catch (error) {
      toast.error(error.message)
    }
  }

  function autogrow(){
    if (!element?.current) return null
    element.current.style.height = "0"
    element.current.style.height = element.current.scrollHeight - 2 +"px"
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  function reset(){
    setValue(children || text)
    toggleEdit(false)
  }

  function startEdit(){
    setValue("")
    toggleEdit(true)
  }

  useLayoutEffect(() => {
    if (type === "textarea") autogrow()
  })

  return(
    <div className={`edit-box ${value ? "populated" : ""} ${edit ? "edit" : ""}`}>
      {icon && showIcon()}
      <span className="value-text">
        <span className="label">{placeholder}</span>
        {type === "textarea" ? 
          <textarea ref={element} className="value-input" value={value || ""} onChange={edit ? (e) => setValue(e.target.value) : null} disabled={!edit}/>
          :
          <input type={type} className="value-input" value={value || ""} onChange={edit ? (e) => setValue(e.target.value) : null} disabled={!edit}/>
        }
      </span>
      {!disabled && save &&
        <div className="actions-wrapper">
            {edit ?
              <div className="actions">
                <Button icon="save" theme="main" onClick={_save}/>
                <Button icon="times" theme="alt" onClick={reset}/>
                {actions}
              </div>
            :
              <div className="actions">
                <Button icon="edit" theme="alt" onClick={startEdit}/>
                {actions}
              </div>
            }
        </div>
      }
    </div>
  )

}

export function Data({placeholder, children, icon}) {

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  return(
    <div className={`edit-box`}>
      {icon && showIcon()}
      <span className="value-text">
        <span className="label">{placeholder}</span>
        <span className='value-input'>{children}</span>
      </span>
    </div>
  )

}

export function DateBox({placeholder, children, save, actions, icon, disabled}) {

  const modal = useModal()

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  return(
    <>
      <div className={`edit-box ${children ? "populated" : ""}`}>
        {icon && showIcon()}
        <span className="value-text">
          <span className="label">{placeholder}</span>
          <input className="value-input" value={moment(children).format("YYYY-MM-DD")} readOnly />
        </span>
        {!disabled && save &&
          <div className="actions-wrapper">
            <div className="actions">
              <Button icon="edit" theme="alt" onClick={() => modal?.show()}/>
              {actions}
            </div>
          </div>
        }
      </div>
      <DateModal modal={modal} expiry={children} edit={save} />
    </>
  )

}


function DateModal({modal, expiry, edit}){

  const [_expiry, setExpiry] = useState(expiry)

  function save(){
    edit(_expiry)
    modal.hide()
  }    

  function hideModal(){
    setExpiry(expiry)
    modal.hide()
  }    

  return(
    <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-expiry" /></Title>} onHide={hideModal}>
      <DatePicker.Date date={_expiry ? new Date(_expiry) : new Date()} onChange={(_date) => setExpiry(moment(_date).valueOf())} />
      <div className='actions' style={{marginTop: 15}}>
        <Button onClick={hideModal}><IntlText id="cancel" /></Button>
        <Button theme="main" onClick={save}><IntlText id="save" /></Button>
      </div>
    </Modalv3>      
  )
}

const editbox = { Text, Date: DateBox, Data }

export default editbox