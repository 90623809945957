import React from 'react'
import { useQuery } from '@apollo/client'

import { ADDON_OPTIONS_QUERY } from '../../../graphql'
import { formatPrice } from '../../../helpers'
import { Loader, IntlValue, OptionList } from '../../../components'

import './style.css'

export function AddProductAddonOption({options, onChange}) {

  const {loading, error, data} = useQuery(ADDON_OPTIONS_QUERY)

  function filterOptions(_options, _selected){
    let filtered = _options.reduce((current, next) => {
      if (_selected.findIndex(_addonOption => next.id === _addonOption.id) < 0){
        current.options = current.options.concat(next)
      } else {
        current.selected = current.selected.concat(next)
      }

      return current
    }, {selected: [], options: []})

    return filtered
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { productAddonOptions } = data
  const {options: _options, selected: _selected} = filterOptions(productAddonOptions, options)

  return(
    <div id="add-product-addon-option">
      <OptionList options={_options} selected={_selected} onChange={onChange}>
        {(_option) =>
          <div>
            <span className="name"><IntlValue>{_option.name}</IntlValue></span>
            {_option.price && _option.price > 0 ? formatPrice(_option.price) : ""}
          </div>
        }
      </OptionList>
    </div>
  )
}
