import React from 'react'

import { Loader } from '../'

const TomatoTheme = React.lazy(() => import('./TomatoTheme'))
const BesmartTheme = React.lazy(() => import('./BesmartTheme'))
const LightTheme = React.lazy(() => import('./LightTheme'))
const DarkTheme = React.lazy(() => import('./DarkTheme'))

export function Theme({ children }){
    
    function getTheme(){
        switch (process.env.REACT_APP_APP_NAME) {
            case "besmart":
                return <BesmartTheme />                
            default:
                return <TomatoTheme />
        }
    }

    function getColors(){
        const colors = localStorage.getItem("app-theme")

        switch (colors) {
            case "dark":
                return <DarkTheme />
            default:
                return <LightTheme />
        }
    }

    return(
        <>
            <React.Suspense fallback={<Loader />}>
                {getTheme()}
                {getColors()}
            </React.Suspense>
            {children}
        </>
    )
}