import React from 'react'
import FontAwesome from 'react-fontawesome'
import Tippy from '@tippyjs/react'

import './style.css'

export function FeedbackField({feedback}) {
  function getFace(number){
    switch (number) {
      case 1:
        return <FontAwesome name="frown" />
      case 2:
        return <FontAwesome name="meh" />
      case 3:
        return <FontAwesome name="smile" />
      default:
        return <FontAwesome name="minus" />
    }
  }

  return(
    <div className="feedback-field">
      {feedback && feedback.productsQuantitative &&
        <Tippy content={feedback.productsQualitative || "No message"}><div>{getFace(feedback.productsQuantitative)}</div></Tippy>
      }
    </div>
  )
}
