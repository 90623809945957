import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Pending } from '../../views'
import { Filters, IntlText } from '../../components'
import { useQueryBuilder } from '../../hooks'

export function Orders() {

  const navigate = useNavigate()
  const location = useLocation()
  const search = new URLSearchParams(location?.search)
  const [filter, setFilter] = useState()
  const { params, removeParam, isQuery, buildQuery, runQuery } = useQueryBuilder({
    data:{
      status: {key: "in-progress", label: <IntlText group="status-filters" id="in-progress" />, value: ["PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION"]}
    }
  })

  function setSearch(str){
    if (str === "") return clearSearch()
    search.set("search", str)
    navigate({search: search.toString()})
    setFilter(str)
  }

  function clearSearch(){
    search.delete("search")
    navigate({search: search.toString()})
    setFilter("")
  }

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  return(
    <div id="orders" className="container">
      <div className="row padding">
        <Filters.Bar>
          <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setSearch(target.value)} reset={() => clearSearch()} placeholder="Type here for searching" onKeyDown={onSearch}/>
          <Filters.Button style={{marginLeft: "auto"}} id="all" onClick={() => runQuery({instantParams: {status: {key: "in-progress", label: <IntlText group="status-filters" id="in-progress" />, value: ["PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION"]}}})} reset={() => removeParam("status")} active={params?.status?.key === "in-progress"}><IntlText group="status-filters" id="in-progress" /></Filters.Button>
          <Filters.Button id="all" onClick={() => runQuery({instantParams: {status: {key: "completed", label: <IntlText group="status-filters" id="completed" />, value: ["COMPLETED"]}}})} reset={() => removeParam("status")} active={params?.status?.key === "completed"}><IntlText group="status-filters" id="completed" /></Filters.Button>
          <Filters.Button id="all" onClick={() => runQuery({instantParams: {status: {key: "cancelled", label: <IntlText group="status-filters" id="cancelled" />, value: ["DELIVERYFAILED", "CANCELLED", "UNPROCESSED"]}}})} reset={() => removeParam("status")} active={params?.status?.key === "cancelled"}><IntlText group="status-filters" id="cancelled" /></Filters.Button>
        </Filters.Bar>
        <Pending buildQuery={buildQuery} isQuery={isQuery} filter={filter}/>
      </div>
    </div>
  )
}
