import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

import { useDetectOutsideClick } from '../../hooks'

import './style.css'

function Dropdown({button = <span>User</span>, icon = "user", children, ...rest}) {
  const wrapper = useRef(null)
  const menu = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu, initialState: false})

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }  

  function onClick(){
    setIsActive(!isActive)
  }

  return (
    <div className="dropdown" {...rest} ref={wrapper}>
      <button onClick={onClick} className="menu-trigger">
        {button}
        {showIcon()}
      </button>
      <nav
        ref={menu}
        className={`menu ${isActive ? "active" : "inactive"}`}
      >
        <ul>
          {children}
        </ul>
      </nav>
    </div>
  )
}

function Item({children, icon, ...rest}) {
  return(
    <li {...rest}>
      <div>
        {icon && <div className="icon">{icon}</div>}
        {children}
      </div>
    </li>
  )
}

function Link({children, icon, to, navlink = true, ...rest}) {
  return (
    <li {...rest}>
      <NavLink className={({ isActive }) => isActive && navlink ? "active-link" : "" } to={to}>
        {icon && <div className="icon">{icon}</div>}
        {children}
      </NavLink>
    </li>
  )
}

const exportedObject = {Dropdown, Item, Link}

export default exportedObject
