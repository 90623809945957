import { gql }from '@apollo/client'

import { PURCHASES_FRAGMENT } from './'

const SET_PREPARATION_TIME_MUTATION = gql`
  mutation setPreparationTime($id: ID!, $time:Int!){
    setPreparationTime(id: $id, time:$time){
      ...PURCHASES_FRAGMENT
    }
  }
  ${PURCHASES_FRAGMENT}
`

export default SET_PREPARATION_TIME_MUTATION
