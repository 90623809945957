import { gql }from '@apollo/client'

import { CUSTOMER_FRAGMENT } from './'

const CUSTOMERS_QUERY = gql`
  query customers($query: String){
     customers(query: $query) {
       ...CUSTOMER_FRAGMENT
     }
  }
  ${CUSTOMER_FRAGMENT}
`

export default CUSTOMERS_QUERY
