import { gql }from '@apollo/client'

export const VENDOR_BOOK_ENTRY_FRAGMENT = gql`
  fragment VENDOR_BOOK_ENTRY_FRAGMENT on VendorBookEntry {
    id
    book
    vendor
    relatedPurchase
    description{
      en
      es
    }
    value
    type
    event
    auditMessage
    note
    voided
    createdAt
    updatedAt
  }
`
