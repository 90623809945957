import React, { useContext, useState, useCallback } from 'react'
import FontAwesome from 'react-fontawesome'
import Cropper from 'react-easy-crop'

import { IntlContext } from '../../contexts'
import { useModal } from '../../hooks'
import { getCroppedImg, base64ToFile, toast } from '../../helpers'
import { noThumb } from '../../assets'
import { FileUploader, IntlText, Modalv3, Title, Button, Loader } from '../../components'

import './style.css'

export function ImageCropper({id, title, placeholder, image, uploadImage, deleteImage, aspect = 1, uploading, deleting}){
  
    const modal = useModal()
    const { getTranslation } = useContext(IntlContext)
    const [_image, setImage] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  
    function _uploadImage({target}){
      if (!target.files?.[0]) return toast.error(getTranslation({id: "nothing-to-upload"}))
      
      const file = target.files[0]
      const reader = new FileReader()
  
      reader.onload = (e) => {
        setImage(e.target.result)
        modal.show()
      }
  
      reader.readAsDataURL(file)
    }
  
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }, [])  
  
    async function __uploadImage(){
      try {
        const croppedImage = await getCroppedImg(
          _image,
          croppedAreaPixels
        )    
    
        const fileName = 'croppedImage.jpeg';
        const fileType = 'image/jpeg';
        const croppedFile = base64ToFile(croppedImage, fileName, fileType);
        
        await uploadImage(croppedFile)
        modal.hide()
  
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  
    return(
      <div className='image-cropper'>
        {deleting && 
            <Loader overlay />
        }         
          <div className="image-uploader">
            <div className="preview">
              {image && deleteImage &&
                <div className="delete"><FontAwesome name="trash" onClick={deleteImage}/></div>
              }
              <img src={image || noThumb} alt={title} title={title} />
            </div>
            <FileUploader id={id} placeholder={placeholder} onChange={_uploadImage}/>
          </div>      
          <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="image-uploader" id="crop-image" /></Title>}>
            <div className='crop-modal'> 
              {uploading && 
                  <Loader overlay />
              }            
              <div className="crop-container">
                {_image && 
                  <Cropper
                    image={_image}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect} // Set the aspect ratio as desired (width / height)
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />       
                }
              </div>
              <div className="controls">
                <div>
                  <IntlText group="image-uploader" id="zoom" />
                  <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(e.target.value)
                    }}
                    className="zoom-range"
                  />
                </div>
              </div>          
              <div className="actions">
                <Button onClick={modal.hide}><IntlText id="cancel" /></Button>
                <Button theme="main" onClick={__uploadImage}><IntlText id="upload" /></Button>
              </div>          
            </div>          
          </Modalv3>
      </div>
    )
  }