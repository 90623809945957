import React from 'react'

import { Theme } from './components'
import { ModalProvider, IntlProvider, AuthContextProvider } from './contexts'

import Router from './router'

function App() {
  return (
    <AuthContextProvider>
      <IntlProvider>
        <ModalProvider>
          <Theme>
            <Router />
          </Theme>
        </ModalProvider>
      </IntlProvider>
    </AuthContextProvider>
  )
}

export default App
