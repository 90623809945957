import { gql }from '@apollo/client'

import { PRODUCT_FRAGMENT } from './'

const PRODUCT_QUERY = gql`
  query product($id: ID){
     product(id: $id) {
       ...PRODUCT_FRAGMENT
     }
  }
  ${PRODUCT_FRAGMENT}
`

export default PRODUCT_QUERY
