import { gql }from '@apollo/client'

import { PRODUCT_FRAGMENT } from './'

const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct($data: ProductInput!){
    createProduct(data: $data){
      ...PRODUCT_FRAGMENT
    }
  }
  ${PRODUCT_FRAGMENT}
`

export default CREATE_PRODUCT_MUTATION
