import React from 'react'
import FontAwesome from 'react-fontawesome'

import "./Checkbox.css"

export default function Checkbox({id, checked, onChange, label, disabled, readOnly, children}){

  return(
    <div className="checkbox">
      <input id={id} type="checkbox" disabled={disabled} checked={checked} onChange={onChange} readOnly/>
      <label htmlFor={id}>
        {disabled ? <FontAwesome name="times" /> : <span></span>}
        {label && <div className="placeholder">{label}</div>}
        {children && <div className="placeholder">{children}</div>}
      </label>
    </div>
  )
}
