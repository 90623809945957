import { useMutation, useQuery } from '@apollo/client'

import { EDIT_VENDOR_MUTATION, HAS_ANY_AVAILABLE_PRODUCT } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { SwitchBox, IntlText, Form, Loader, Notification } from '../../../components'

export function VendorSettings({vendor}) {

  const { getTranslation } = useIntl()
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const { loading, data, error } = useQuery(HAS_ANY_AVAILABLE_PRODUCT, { variables:{ id: vendor.id }, fetchPolicy: "network-only" })

  if (loading || error) return <Loader theme='main' />

  async function changeSwitch(data){
    const alert = toast.loading()
    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      alert.success(getTranslation({id: "save-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  const { hasAnyAvailableProduct } = data

  return(
    <div id="vendor-settings">
      {hasAnyAvailableProduct ? 
        <Form.Form>
          <Form.Field>
            <SwitchBox id="technicalBreak" label={<IntlText group="vendor-settings" id="technical-break-desc" />} checked={vendor.technicalBreak} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
              <IntlText group='vendor-settings' id='technical-break' />
            </SwitchBox>                
          </Form.Field>
          <Form.Field>
            <SwitchBox id="onVacation" label={<IntlText group="vendor-settings" id="on-vacation-desc" />} checked={vendor.onVacation} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
              <IntlText group='vendor-settings' id='on-vacation' />
            </SwitchBox>               
          </Form.Field>
        </Form.Form>
        :
        <Notification title={<IntlText group='vendor-settings' id='no-available-products' />} icon="exclamation-triangle" type='error'>
          <IntlText group='vendor-settings' id='no-available-products-info' />
        </Notification>           
      }
    </div>
  )
}
