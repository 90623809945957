import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { SERVICE_FEE_PROFORMA_INVOICES } from '../../graphql'
import { useQueryBuilder } from '../../hooks'
import { formatPrice } from '../../helpers'
import { IntlContext } from '../../contexts'
import { Badge, Table, Pagination, ContextMenu, IntlText, Filters, QueryResults, DatePicker, Placeholder, Loader } from '../../components'

export function Invoices() {

  const { getTranslation } = useContext(IntlContext)
  const location = useLocation()
  const locationSearch = new URLSearchParams(location.search)
  const defaultDate = locationSearch.get("date") ? locationSearch.getAll("date") : [moment().subtract(30, "day").format("YYYY-MM-DD"), moment().startOf("day").format("YYYY-MM-DD")]
  const { params, addParam, removeParam, isQuery, buildQuery, runQuery, setPage, page, clearParams } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${defaultDate[0]} - ${defaultDate[1]}`, value: defaultDate, skip: defaultDate.length !== 2},
    },
    page: locationSearch.get("page"),
    limit: 50
  }, [{id: "date", validation: (obj) => !!obj?.value, error: "You have to select date"}])  
  const { loading, data, error } = useQuery(SERVICE_FEE_PROFORMA_INVOICES, {variables: buildQuery(), skip: !isQuery()})

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", value: [_date[0], _date[1]], label: `${_date[0]} - ${_date[1]}`, skip: !(_date[0] && _date[1])}})
  }  

  const _invoices = data?.serviceFeeProformaInvoices?.nodes

  if (loading || error) return (
    <div className="row padding">
      <Loader theme='main' />
    </div>
  )

  return(
    <div id="invoices" className="container">
      <div className="row padding">
        <Filters.Bar style={{justifyContent: "flex-end"}}>
          <Filters.Dropdown placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right run={runQuery} reset={() => removeParam("date")}>
            <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
          </Filters.Dropdown>
        </Filters.Bar>          
        <QueryResults pageInfo={data?.riderPayouts?.pageInfo} reset={clearParams} />          
        {isQuery() ?
          <div id="vendor-service-fee-invoices">
            <Table.Table>
              <Table.Head>
                <Table.Tr>
                  <Table.Th className="text-left"><IntlText group="vendor-service-fee-invoices" id="reference" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="vendor-service-fee-invoices" id="netInvoiceAmount" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="vendor-service-fee-invoices" id="periodicity" /></Table.Th>
                  <Table.Th><IntlText group="vendor-service-fee-invoices" id="created-at" /></Table.Th>
                  <Table.Th><IntlText group="vendor-service-fee-invoices" id="due-at" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="vendor-service-fee-invoices" id="status" /></Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.Head>
              <Table.Body>
                {_invoices?.length > 0 ? _invoices.map((invoice, key) =>
                  <Table.Tr key={key} className="invoice">
                    <Table.Td className="text-left">{invoice.reference}</Table.Td>
                    <Table.Td className="text-left">{formatPrice(invoice.grossInvoiceAmount)}</Table.Td>
                    <Table.Td className="text-left"><IntlText group="vendor-service-fee-invoices" id={invoice.periodicity} /></Table.Td>
                    <Table.Td>{moment(invoice.createdAt).format("MM/DD/YYYY HH:mm")}</Table.Td>
                    <Table.Td>
                      {moment(invoice.dueAt).format("MM/DD/YYYY HH:mm")}
                      {invoice.overdue &&
                        <Badge style={{marginLeft: 10}} icon="exclamation-triangle" theme='invalid'><IntlText group="vendor-service-fee-invoices" id="overdue" /></Badge>
                      }
                    </Table.Td>
                    <Table.Td className="text-left">
                      <Badge theme={invoice.status === "PAID" ? "valid" : invoice.status === "WAITING_FOR_PAYMENT" ? "warning" : null}><IntlText group="vendor-service-fee-invoices" id={invoice.status} /></Badge>
                      {invoice.overdue &&
                        <Badge style={{marginLeft: 10}} icon="exclamation-triangle" theme='invalid'><IntlText group="vendor-service-fee-invoices" id="overdue" /></Badge>
                      }                                   
                    </Table.Td>
                    <Table.Td>
                    <ContextMenu.Menu id={invoice?.id}>
                      <ContextMenu.Link to={`${invoice.id}`} icon="external-link-alt"><IntlText group="vendor-service-fee-invoices" id="view-invoice" /></ContextMenu.Link>
                    </ContextMenu.Menu>
                    </Table.Td>
                  </Table.Tr>
                ) :
                  <Table.Tr>
                    <Table.Td colSpan={7}><IntlText id="search-not-found" /></Table.Td>
                  </Table.Tr>
                }
              </Table.Body>
            </Table.Table>
            <Pagination page={page} setPage={setPage} pageInfo={data?.serviceFeeProformaInvoices?.pageInfo}/>
          </div>
        :
          <Placeholder.Search />
        }          
      </div>
    </div>
  )

}
