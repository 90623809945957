import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '../../hooks'
import { Title, Logo, IntlText } from '../../components'
import { LoginForm } from '../../views'

import './style.css'

export function Login() {

  const { isLoggedIn } = useAuth()
  
  if (isLoggedIn) return <Navigate to="/" />

  return(
    <div id="login" className="container">
      <div className="row wrapped">
        <div id="login-form-wrapper">
          <Logo />
          <div className="heading">
            <div className="inner">
              <Title tag="h2"><IntlText group="login" id="title" /></Title>
              <div className="desc"><IntlText group="login" id="desc" /></div>
            </div>
          </div>
          <LoginForm />
        </div>
      </div>
    </div>
  )
}
