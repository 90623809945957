import { gql }from '@apollo/client'

import { PURCHASES_FRAGMENT } from './'

export const PURCHASE_UPDATED = gql`
  subscription purchaseUpdated($id: ID){
     purchaseUpdated(id: $id) {
       ...PURCHASES_FRAGMENT
     }
  }
  ${PURCHASES_FRAGMENT}
`
