import React from 'react'

import { useAuth } from '../../../hooks'
import { formatPrice } from '../../../helpers'

export function ProductVendorPrice({product}){

  const { loading, user, error } = useAuth()

  if (loading || error) return null

  return(
    <div className="product-vendor-price">
        {formatPrice(product.price * (1-user?.serviceFee))}
    </div>
  )
}
