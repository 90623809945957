import React from 'react'

import { useAuth } from '../../../hooks'
import { formatPrice } from '../../../helpers'

export function AddonOptionVendorPrice({option}){

  const { user, loading, error} = useAuth()

  if (loading || error) return null

  return(
    <div className="product-vendor-price">
        {formatPrice(option.price * (1-user?.serviceFee))}
    </div>
  )
}
