import { gql }from '@apollo/client'

import { VENDOR_FRAGMENT, VENDOR_USER_FRAGMENT } from '../'

export const ME_QUERY = gql`
  query me{
     me {
       ...VENDOR_FRAGMENT
       ...VENDOR_USER_FRAGMENT
     }
  }
  ${VENDOR_FRAGMENT}
  ${VENDOR_USER_FRAGMENT}
`
