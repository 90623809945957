import React from 'react'

import './style.css'

function Skeleton({children}) {
    return(
        <div className="skeleton">
            {children}
        </div>
    )

}

function Col({children}) {
    return (
        <div className="skeleton-col">
            {children}
        </div>
    )
}

function Row({children}) {
    return (
        <div className="skeleton-row">
            {children}
        </div>
    )
}

function Line({short, bottom}) {
    return <div className={`skeleton__line ${short ? "short" : ""} ${bottom ? "bottom" : ""}`} />
}

function Image() {
    return <div className="skeleton__image" />
}

const skeleton = { Skeleton, Line, Image, Row, Col }

export default skeleton
