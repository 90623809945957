import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { useValidatedForm } from '../../../hooks'
import { returnGraphqlError } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { EDIT_ADDON_OPTION_MUTATION, CREATE_ADDON_OPTION_MUTATION, ADDON_OPTIONS_QUERY } from '../../../graphql'
import { LabeledInput, Button, Validation, IntlText, Form, LabeledCheckbox, InfoText } from '../../../components'
import { Price } from '../../../views'

const validations = {
  "nameEn": [{id: "required", validation: (val) => !!val}],
  "nameEs": [{id: "required", validation: (val) => !!val}],
  "price": [{id: "positive-number", validation: (val) => val >= 0}],
}

export function AddonOption({addonOption, success, cancel}) {

  const { getTranslation } = useContext(IntlContext)
  const [editAddonOption, { loading: editLoading }] = useMutation(EDIT_ADDON_OPTION_MUTATION)
  const [createAddonOption, { loading: createLoading }] = useMutation(CREATE_ADDON_OPTION_MUTATION)

  async function save(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

    let data = {
      name: {
        es: nameEs,
        en: nameEn
      },
      price: parseInt(price) || 0,
      quantifiable
    }

    if (addonOption?.id){
      await edit(data)
    } else {
      await create(data)
    }

  }

  async function create(data){
    try {
      await createAddonOption({variables: {data}, refetchQueries: [{query: ADDON_OPTIONS_QUERY}]})
      toast.success(getTranslation({id: "save-success"}))
      if (success) success()
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  async function edit(data){
    try {
      await editAddonOption({variables: {id: addonOption?.id, data}})
      toast.success(getTranslation({id: "edit-success"}))
      if (success) success()
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  const { form: {nameEn, nameEs, price, quantifiable}, errors, updateField, validate } = useValidatedForm({
    nameEn: addonOption?.name?.en, 
    nameEs: addonOption?.name?.es, 
    price: addonOption?.price || 0, 
    quantifiable: addonOption?.quantifiable
  }, validations)

  return(
    <div id="addon-option">
      <Form.Form>
        <div className="warning"><IntlText group="form" id="responsibility-warning" /></div>
        <Form.Field>
          <Validation errors={errors.nameEn}>
            <LabeledInput id="nameEn" placeholder={getTranslation({group: "form", id: "name-en"})} value={nameEn} onChange={({target}) => updateField({key: target.id, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.nameEs}>
            <LabeledInput id="nameEs" placeholder={getTranslation({group: "form", id: "name-es"})} value={nameEs} onChange={({target}) => updateField({key: target.id, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.price}>
            <Price price={price} setPrice={(_value) => updateField({key: "price", value: _value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <LabeledCheckbox id="quantifiable" name="quantifiable" checked={quantifiable} onChange={({target}) => updateField({key: target.name, value: target.checked})} rounded>
            <InfoText info={<IntlText group='form' id='quantifiable-info' />}>
              <IntlText group='form' id='quantifiable' />
            </InfoText>
          </LabeledCheckbox>
        </Form.Field>        
        <Form.Fieldset>
          <Form.Field fluid>
            <Button fluid theme="alt" onClick={cancel}><IntlText id="back" /></Button>
          </Form.Field>
          <Form.Field fluid>
            <Button fluid theme="main" loading={editLoading || createLoading} onClick={save}><IntlText id="save" /></Button>
          </Form.Field>
        </Form.Fieldset>
      </Form.Form>
    </div>
  )

}
