import React, { useContext } from 'react'
import moment from 'moment'

import { IntlText, Table, ContextMenu } from '../../../components'
import { FeedbackField } from '../'
import { IntlContext } from '../../../contexts'

import './style.css'

export function FeedbacksList({data}) {

  const { getTranslation } = useContext(IntlContext)

  return(
    <Table.Table id="feedbacks-list">
      <Table.Head>
        <Table.Tr>
          <Table.Th className="text-left"><IntlText group="feedbacks-list" id="table-header-created-at" /></Table.Th>
          <Table.Th><IntlText group="feedbacks-list" id="table-header-products-quantitative" /></Table.Th>
          <Table.Th className="text-left"><IntlText group="feedbacks-list" id="table-header-products-qualitative" /></Table.Th>
          <Table.Th><IntlText group="feedbacks-list" id="table-header-actions" /></Table.Th>
        </Table.Tr>
      </Table.Head>
      <Table.Body>
        {data && data.length > 0 ? data.map((feedback, key) =>
          <Table.Tr key={key} className="feedbacks-list-item">
            <Table.Td className="text-left" label={getTranslation({group: "feedbacks-list", id: "table-header-created-at"})}>{moment(feedback.createdAt).format('YYYY MMMM DD. HH:mm:ss')}</Table.Td>
            <Table.Td centered label={getTranslation({group: "feedbacks-list", id: "table-header-products-quantitative"})}><FeedbackField feedback={feedback} /></Table.Td>
            <Table.Td className="text-left" style={{whiteSpace: "normal"}} label={getTranslation({group: "feedbacks-list", id: "table-header-products-qualitative"})}>{feedback.productsQualitative}</Table.Td>
            <Table.Td label={getTranslation({group: "feedbacks-list", id: "table-header-actions"})}>
              <ContextMenu.Menu id={feedback.id} >
                <ContextMenu.Link icon="eye" to={`/orders/${feedback.purchase}`}><IntlText group="feedbacks-list" id="view-purchase" /></ContextMenu.Link>
              </ContextMenu.Menu>
            </Table.Td>
          </Table.Tr>
        )
        :
          <Table.Tr>
            <Table.Td colSpan={10}><IntlText group="feedbacks-list" id="no-items" /></Table.Td>
          </Table.Tr>
        }
      </Table.Body>
    </Table.Table>
  )

}
