import { gql }from '@apollo/client'

const CUSTOMER_FRAGMENT = gql`
  fragment CUSTOMER_FRAGMENT on Customer {
    id
    name
    email
    phone{
      countryCode
      phoneNumber
    }
  }
`

export default CUSTOMER_FRAGMENT
