import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Loader } from '../../components'
import { PromotionForm  } from '../../views'
import { PROMOTION } from '../../graphql'

export function Promotion(){
  const { id } = useParams()
  const {loading, error, data} = useQuery(PROMOTION, {variables: {id}})

  if (loading) return <Loader theme="main" />
  if (error) return `Error: ${error}`

  const { promotion } = data

  return(
    <div id="promotion" className="container">
      <div className="row padding">
        <PromotionForm promotion={promotion} />
      </div>
    </div>
  )
}
