import { gql }from '@apollo/client'

export const VENDOR_FRAGMENT = gql`
  fragment VENDOR_FRAGMENT on Vendor {
    id
    slug
    name
    username
    logo
    cover
    phone{
      countryCode
      phoneNumber
      phoneCode
    }
    location{
      type
      coordinates
    }
    pickupInformation
    email
    wallpaper
    contract
    type
    role
    rank
    tags
    isOpen
    productTypes
    serviceFee
    cashPaymentsDisabled
    bankCardPaymentsAllowed
    bankCardPaymentsAccepted
    numOfFeedbacks
    percentageOfPositive
    expressAccountId
    expressAccountDetailsSubmitted
    utensilsAvailable
    invoicing{
      name
      rfc
      email
      street
      exterior
      interior
      neighborhood
      city
      zip
      municipality
      state
      taxSystem
    }
    openingHours{
      from
      to
      isOpen
    }
    intro{
      en
      es
    }
    hidden
    suspended
    technicalBreak
    slow
    onVacation
    vendorSystem
    description{
      en
      es
    }
    performanceRank
    preparationTimeRange
    serviceFeeChargeType
    serviceFeeChargePeriod
    needsInvoiceOfServicefee
    lastChangelogReview
  }
`
