import { gql }from '@apollo/client'

export const SETTING_FRAGMENT = gql`
  fragment SETTING_FRAGMENT on Setting {
    id
    key
    description
    type
    value
  }
`
