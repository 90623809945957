import { useEffect } from 'react'

export function useInterval(callback, delay, dependency) {
  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if ((!delay && delay !== 0) || !callback) {
      return
    }

    const id = setInterval(() => callback(), delay)

    return () => clearInterval(id)
  }, [delay, dependency, callback])
}