import React, { useEffect } from "react"
import moment from "moment"

import { useModal, useAuth } from '../../hooks'
import { Modalv3, Title, IntlText, IntlBlock, Button } from '../../components'

export function AlertModal(){

    const modal = useModal()
    const { user } = useAuth()
    const hide = localStorage.getItem("hide-alert-modal")

    useEffect(() => {
        if (user?.role === "VENDOR"){
            if (user?.serviceFeeChargeType !== "PERIODICAL" && (moment(hide, 'X').isBefore(moment(), "day") || !hide)) modal.show()
        }
    // eslint-disable-next-line
    }, [])

    function dontShow(){
        localStorage.setItem("hide-alert-modal", moment().unix())
        modal.hide()
    }

    return(
        <Modalv3 modal={modal} header={<Title tag="h3"><IntlText id="attention" /></Title>}>
            <IntlBlock language="en">
                <div className="text-content">
                    <p>We wish to announce some changes that have been made to our platform.</p>
                    <p>The changes are listed below:</p>
                    <ul className="list">
                        <li>We changed our service fee collection method for orders paid in cash, from cash on delivery, to weekly periodical collection</li>
                        <li>We updated our <a href="https://vendor.tomato.mx/terminos-y-condiciones-afiliado-comercial" target="_blank" rel="noreferrer">Terms of Service</a></li>
                        <li>We updated our <a href="https://vendor.tomato.mx/aviso-de-privacidad-afiliado-comercial" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                        <li>We updated our <a href="https://vendor.tomato.mx/offers" target="_blank" rel="noreferrer">offers</a></li>
                    </ul>
                    <p>Please review our <a href="https://vendor.tomato.mx/offers" target="_blank" rel="noreferrer">updated offers</a> and send us all the requirements by email to <a href="mailto:partner@tomato.mx">partner@tomato.mx</a>.</p>
                    <p>The changes are effective from 2023, 1st of February, after this date we only provide service in accordance with our updated policies. We are proactively working on making this migration as easy and convenient as possible for all of our business partners.</p>
                    <p>If you have any question or need help with any aspect of the migration, please feel free to contact our support directly via What's app, we will be glad to help and assist you.</p>
                    <p>Tomato.mx Administration</p>
                </div>
            </IntlBlock>
            <IntlBlock language="es">
                <div className="text-content">
                    <p>Por este medio anunciamos algunos de los cambios realizados en nuestra plataforma.</p>
                    <p>Estos se muestran a continuación:</p>
                    <ul className="list">
                        <li>El método de cobro de la tarifa del servicio para pedidos pagados en efectivo ha cambiado, anteriormente pagaba en efectivo al momento de la recollecion de una entrega pero ahora esta se factura en periodos semanales.</li>
                        <li>Se actualizaron nuestros <a href="https://vendor.tomato.mx/terminos-y-condiciones-afiliado-comercial" target="_blank" rel="noreferrer">Términos y Condiciones</a></li>
                        <li>Se actualizaron nuestros <a href="https://vendor.tomato.mx/aviso-de-privacidad-afiliado-comercial" target="_blank" rel="noreferrer">Políticas de Privacidad</a></li>
                        <li>Se actualizaron nuestros <a href="https://vendor.tomato.mx/offers" target="_blank" rel="noreferrer">ofertas</a></li>
                    </ul>
                    <p>Favor de revisar nuestras <a href="https://vendor.tomato.mx/offers" target="_blank" rel="noreferrer">ofertas actualizadas</a> y enviarnos todos los requerimientos o solicitudes por correo a <a href="mailto:partner@tomato.mx">partner@tomato.mx</a>.</p>
                    <p>Estos cambios tienen efecto a partir del 1º de Febrero del 2023, despues de esta fecha se dará servicio de acuerdo a esta nueva actualización de nuestras políticas, nos encontramos trabajando proactivamente para que esta migración sea lo más fácil y conveniente posible para todos nuestros socios comerciales.</p>
                    <p>Si tiene alguna pregunta o duda en referencia a esta migración, los invitamos a ponerse en contacto con nuestro equipo de soporte directamente por WhatsApp, con mucho gusto los ayudaremos.</p>
                    <p>Tomato.mx Administracion</p>
                </div>
            </IntlBlock>
            <div className="actions">
                <Button onClick={dontShow}><IntlText id="dont-show-again" /></Button>
                <Button onClick={() => modal.hide()}><IntlText id="ok" /></Button>
            </div>
        </Modalv3>    
    )
}