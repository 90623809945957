import { gql }from '@apollo/client'

import { VENDOR_FRAGMENT, VENDOR_USER_FRAGMENT } from '../'

const LOGIN_MUTATION = gql`
  mutation login($uid: String!, $password:String!){
    login(uid: $uid, password:$password, role:VENDOR){
      token
      user{
        ...VENDOR_FRAGMENT
        ...VENDOR_USER_FRAGMENT
      }
    }
  }
  ${VENDOR_FRAGMENT}
  ${VENDOR_USER_FRAGMENT}
`

export default LOGIN_MUTATION
