import React from 'react'

import { NotFound as NotFoundView } from '../../views'

export function NotFound() {
  return(
    <div id="not-found" className="container">
      <div className="row padding">
        <NotFoundView fill />
      </div>
    </div>
  )
}
