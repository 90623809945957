import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { UPLOAD_VENDOR_MEDIA_MUTATION, DELETE_VENDOR_MEDIA_MUTATION } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { toast } from '../../../helpers'
import { IntlText, Form, ImageCropper} from '../../../components'

export function VendorMedia({vendor}) {

  const { getTranslation } = useContext(IntlContext)
  const [uploadMedia, {loading: uploadLoading}] = useMutation(UPLOAD_VENDOR_MEDIA_MUTATION)
  const [deleteMedia, {loading: deleteLoading}] = useMutation(DELETE_VENDOR_MEDIA_MUTATION)

  async function _uploadMedia({file, type}){
    const alert = toast.loading()
    try {
      await uploadMedia({variables: {vendor: vendor.id, file, type}})
      return alert.success(getTranslation({id: "upload-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function _deleteMedia(type){
    try {
      await deleteMedia({variables: {id: vendor.id, type}})
      return toast.success(getTranslation({id: "upload-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="vendor-media">
      <Form.Form>
        <Form.Field>
          <ImageCropper id="logo" title={<IntlText group="vendor-media" id="logo" />} placeholder={<IntlText group="vendor-media" id="logo" />} image={vendor.logo} uploadImage={(file) => _uploadMedia({file, type: "IMAGE_VENDOR_LOGO"})} uploading={uploadLoading} deleting={deleteLoading} />          
        </Form.Field>
        <Form.Field>
          <ImageCropper id="cover" aspect={1200/460} title={<IntlText group="vendor-media" id="cover" />} placeholder={<IntlText group="vendor-media" id="cover" />} image={vendor.cover} deleteImage={() => _deleteMedia("IMAGE_VENDOR_COVER")} uploadImage={(file) => _uploadMedia({file, type: "IMAGE_VENDOR_COVER"})} uploading={uploadLoading} deleting={deleteLoading} />          
        </Form.Field>
        <Form.Field>
          <ImageCropper id="wallpaper" aspect={1920/260} title={<IntlText group="vendor-media" id="wallpaper" />} placeholder={<IntlText group="vendor-media" id="wallpaper" />} image={vendor.wallpaper} deleteImage={() => _deleteMedia("IMAGE_VENDOR_WALLPAPER")} uploadImage={(file) => _uploadMedia({file, type: "IMAGE_VENDOR_WALLPAPER"})} uploading={uploadLoading} deleting={deleteLoading} />          
        </Form.Field>
      </Form.Form>
    </div>
  )
}
