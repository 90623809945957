import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { FAQS } from '../../graphql'
import { Loader } from '../../components'
import { Help as HelpView } from '../../views'

const topics = {
  "purchases": "VENDOR_PURCHASE",
  "get-started": "VENDOR_ONBOARDING"
}

export function Help() {

  const { id } = useParams()
  const { loading, data, error } = useQuery(FAQS, {variables: {topic: topics[id]}})

  if (loading || error) return <Loader theme="main" />

  return(
    <div className="container">
      <div className="row padding">
        <div className="relative">
          <HelpView items={data?.faqItems} />
        </div>
      </div>
    </div>
  )
}
