import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Heading, IntlText, Button } from '../../components'
import { VendorPaymentSettings } from '../../views'

export function Payment({vendor}) {

  const navigate = useNavigate()

  return(
    <div>
      <Heading spacing info={<IntlText group="wizard" id="payment-info" />}>
        <IntlText group='wizard' id='payment' />
      </Heading>          
      <VendorPaymentSettings vendor={vendor} />
      <div className='actions' style={{marginTop: 30}}>
        <Button onClick={() => navigate("../opening-hours")}><IntlText id="back" /></Button>
        <Button theme="main" onClick={() => navigate("../delivery")}><IntlText id="next" /></Button>
      </div>      
    </div>
  )
}
