import React from 'react'
import { useQuery } from '@apollo/client'

import { DELIVERY_ZONES } from '../../graphql'
import { Map } from '../../components'

export function DeliveryZone() {

  const { loading, data } = useQuery(DELIVERY_ZONES)

  function handleApiLoaded(map, maps){
    data?.getDeliveryZones.forEach((zone) => {
      const geometry = zone.geometry.coordinates[0].map((zone) =>
        ({lat: zone[1], lng: zone[0]})
      )
      const color = zone.disabled ? "red" : "green"
      var polygon = new maps.Polygon({
       paths: geometry,
       strokeColor: color,
       strokeOpacity: 0.8,
       strokeWeight: 1,
       fillColor: color,
       fillOpacity: 0.35
     })

     polygon.setMap(map)
    })
  }


  return(
    <div className="container">
      <div className="row padding">
        <div className="relative">
          <div className="map" style={{height: 800, borderRadius: 5}}>
            <Map.Map key={loading} zoom={12} yesIWantToUseGoogleMapApiInternals onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)} />      
          </div>
        </div>
      </div>
    </div>
  )
}
