import React from 'react'

import config from '../../config'
import { useAuth } from '../../hooks'
import { Heading, IntlText, CopyInput } from '../../components'
import { MaterialsList, ColorsList } from '../../views'

export function Materials() {

  const { user } = useAuth()

  const materials = [
    {id: "logo"},
    {id: "logo-horizontal"},
    {id: "logo-horizontal-black"},
    {id: "logo-color-background", background: "main"},
    {id: "logo-horizontal-color-background", background: "main"},
    {id: "logo-horizontal-main-background", background: "main"},
    {id: "logo-color-background", background: "secondary"},
    {id: "logo-horizontal-color-background", background: "secondary"},
    {id: "logo-horizontal-secondary-background", background: "secondary"},
  ]
  
  return(
    <div id="materials" className="container">
      <div className="row padding">
        <div className="relative">
          <p><IntlText group="materials" id="desc" /></p>
          {user && 
            <div className='section'>
              <Heading left tag="h4">
                <IntlText group="materials" id="links" />
              </Heading>                          
              <div className='links'>
                <div className='link'><CopyInput label={<IntlText group="materials" id="menu-link-label" />}>{`${config.clientHome}/${user?.slug}`}</CopyInput></div>
              </div>
            </div>
          }
          <div className='section'>
            <Heading left tag="h4">
              <IntlText group="materials" id="colors" />
            </Heading>               
            <ColorsList />
          </div>
          <div className='section'>
            <Heading left tag="h4">
              <IntlText group="materials" id="logos" />
            </Heading>                   
            <MaterialsList materials={materials} />
          </div>
        </div>
      </div>
    </div>
  )
}
