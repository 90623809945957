import { Title, IntlValue, IntlText } from '../../../components'

import './style.css'

export function PurchasePromotions({purchase: {promotions}}) {
  
  if (!(promotions?.length > 0)) return null

  return(
    <div id="purchase-promotions">
      <Title tag="h3"><IntlText group="purchase-promotions" id="title" /></Title>
      <div className='items'>
        {promotions.map((promotion, key) => 
          <div key={key} className="promotions-title">
            <i className="fas fa-badge-percent"></i>
            <div className="info">
              <Title tag='h4'><IntlValue>{promotion.label}</IntlValue></Title>
            </div>
          </div>
        )}
        {promotions.map((promotion, key) => 
          <div key={key} className="promotions-title">
            <i className="fas fa-badge-percent"></i>
            <div className="info">
              <Title tag='h4'><IntlValue>{promotion.label}</IntlValue></Title>
            </div>
          </div>
        )}
      </div>
    </div>      
  )
}