import { useEffect } from 'react'

export function useVisibilityChange(onVisibilityChange) {

    useEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange)

        return () => document.removeEventListener("visibilitychange", onVisibilityChange)
    }, [onVisibilityChange])

}
