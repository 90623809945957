import React from 'react'

import { IntlText, Heading } from '../../components'
import { VendorTerms } from '../../views'

export function Terms({vendor}) {

  return(
    <div>
      <Heading spacing info={<IntlText group="wizard" id="terms-info" />}>
        <IntlText group='wizard' id='terms' />
      </Heading>        
      <VendorTerms vendor={vendor} />
    </div>
  )
}
