import React from 'react'

import './style.css'

export function Loader({theme = "", overlay = false, size, loading = true}) {

  if (!loading) return null

  return(
    <div className={`loader-wrapper ${overlay ? "loader-overlay" : ""}`}>
      <div className={`loader ${theme} ${size ? size : null}`}>
        <span></span>
      </div>
    </div>
  )
}
