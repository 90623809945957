import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import { MY_PURCHASES_QUERY, MY_PURCHASES_UPDATED_SUBSCRIPTION } from '../../../graphql'
import { useVisibilityChange } from '../../../hooks'
import { Loader, Filter } from '../../../components'
import { OrdersList } from '../../../views'

export function Pending({buildQuery, filter}) {

  const { data, error, loading, networkStatus, subscribeToMore, refetch } = useQuery(MY_PURCHASES_QUERY, {variables: {date: moment().format("YYYY-MM-DD"), ...buildQuery()}, fetchPolicy: "network-only", notifyOnNetworkStatusChange: false})

  useVisibilityChange((e) => {
    if (document.visibilityState === "visible"){
      console.log("Background refresh");
      refetch()
    }
  })   
  useEffect(() => {
    const unsub = subscribeToMore({
      document: MY_PURCHASES_UPDATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const _data = [...prev.myPurchases]
        const index = _data.findIndex((purchase) => purchase.id === subscriptionData.data.myPurchasesUpdated.id)
        if (index >= 0){
          if (["CANCELLED", "DELIVERYFAILED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(_data[index].status)){
            _data.splice(index, 1)
          } else {
            _data[index] = subscriptionData.data.myPurchasesUpdated
          }
          return Object.assign({}, prev, {
            myPurchases: _data
          })
        } else if(subscriptionData.data.myPurchasesUpdated.status === "PROCESSED") {
          return Object.assign({}, prev, {
            myPurchases: [subscriptionData.data.myPurchasesUpdated, ..._data]
          })
        }
      }
    })

    return () => {
      unsub()
    }

  // eslint-disable-next-line
  }, [])

  const filters = [
    {filter: (data) => data.id},
    {filter: (data) => data.customer.name},
  ]
  const sortables = [
    {id: "createdAt", type: "date", data: (elem) => elem.createdAt}
  ]

  if (loading && networkStatus === 1) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`
  const  { myPurchases } = data || {}

  return(
    <div id="pending-orders">
      {loading && <Loader theme="main" overlay />}
      <Filter id="pending-orders" search={[{key: "search", value: filter}]} data={myPurchases || []} filters={filters} sortables={sortables} sortOrder="DESC" sortBy="createdAt">
        {(data) =>
          <OrdersList data={data} dateFormat="HH:mm" link={(_purchase) => `/${_purchase?.id}`} />
        }
      </Filter>
    </div>
  )
}
