import { gql }from '@apollo/client'

export const VENDOR_TRANSACTIONS_OF_PERIOD = gql`
  query vendorTransactionsOfPeriod($vendor: ID, $date: [String], $offset: Int, $limit: Int){
    vendorTransactionsOfPeriod(vendor: $vendor, date: $date, offset: $offset, limit: $limit){
        nodes{
            originalId
            relatedPurchase
            description{
                en
                es
            }
            value
            type
            event
            voided
            createdAt
        }
        pageInfo{
            limit
            totalNodes
            firstNode
            lastNode
            totalPages
            actualPage
            hasNextPage
        }
        completedPurchases
        unprocessedPurchases
        activePurchases
        purchasesWithCash
        purchasesWithCard
        totalRevenue
        totalCashRevenue
        totalCardRevenue
        totalServicefee
        cashServiceFee
        cardServiceFee
        netVendorPriceAdjument
        netCashMovement
        netCashBalanceMovement
    }
  }
`
