import React from 'react'

import { Heading, IntlText } from '../../components'
import { PackageBox } from '../../views'

export function Photoshoot() {

  return(
    <div className="container">
      <div className="row padding">
        <Heading left info={<IntlText group="photoshoot" id="intro" />}>
          <IntlText group="photoshoot" id="photoshoot-info" />
        </Heading>                   
        <Heading left info={<IntlText group="photoshoot" id="why-info" />} tag="h4">
          <IntlText group="photoshoot" id="why" />
        </Heading>                   
        <Heading left info={<IntlText group="photoshoot" id="customer-emotions-info" />} tag="h4">
          <IntlText group="photoshoot" id="customer-emotions" />
        </Heading>                   
        <Heading left info={<IntlText group="photoshoot" id="sale-increase-info" />} tag="h4">
          <IntlText group="photoshoot" id="sale-increase" />
        </Heading>                   
        <Heading left info={<IntlText group="photoshoot" id="effective-info" />} tag="h4">
          <IntlText group="photoshoot" id="effective" />
        </Heading>                             
        <Heading left info={<IntlText group="photoshoot" id="easy-choice-info" />} tag="h4">
          <IntlText group="photoshoot" id="easy-choice" />
        </Heading>                   
        <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
          <PackageBox title={<IntlText group="photoshoot" id="monthly" />} price={600}>
            <ul>
              <li><IntlText group="photoshoot" id="monthly-photos" /></li>
              <li><IntlText group="photoshoot" id="monthly-social-media" /></li>
              <li><IntlText group="photoshoot" id="monthly-license" /></li>              
              <li><IntlText group="photoshoot" id="monthly-revisit" /></li>              
            </ul>
          </PackageBox>
          <PackageBox title={<IntlText group="photoshoot" id="one-time" />} price={3000}>
            <ul>
              <li><IntlText group="photoshoot" id="one-time-photos" /></li>
              <li><IntlText group="photoshoot" id="one-time-social-media" /></li>
              <li><IntlText group="photoshoot" id="one-time-license" /></li>                    
            </ul>
          </PackageBox>
        </div>   
      </div>
    </div>
  )
}
