import React from 'react'
import { useQuery } from '@apollo/client'

import { SETTING } from '../../graphql'
import { Loader } from '../Loader'

export function Configurable({id, children}){

    const { loading, data, error } = useQuery(SETTING, {variables: {key: id}, skip: !id}) 

    if (loading) return <Loader theme='main' />
    if (error) return null
    if (data?.setting?.value !== "true") return null
 
    return children
}