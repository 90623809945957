import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Loader } from '../'

import './style.css'

export function Button({innerStyle, theme, icon, type="button", className, fluid, loading, children, active, ...rest}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return(
    <button type={type} className={`button ${className || ""} ${theme || ""} ${active ? "active" : ""} ${fluid ? "fluid" : ""}`} {...rest}>
      <span className="value-wrapper">
        {loading && <Loader small />}
        {icon && showIcon()}
        {children && <span style={innerStyle}>{children}</span>}
      </span>
    </button>
  )

}
