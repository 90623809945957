import { gql }from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

const PURCHASE_QUERY = gql`
  query purchase($slug: String, $id: ID){
     purchase(slug: $slug, id: $id) {
       ...PURCHASE_FRAGMENT
     }
  }
  ${PURCHASE_FRAGMENT}
`

export default PURCHASE_QUERY
