import React, { useState, useContext } from 'react'
import FontAwesome from 'react-fontawesome'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { toast } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { IntlText, Button, Checkbox } from '../../../components'

import './style.css'

export function VendorTerms({vendor, hideActions}) {

  const tags = new Set(vendor?.tags)
  const navigate = useNavigate()
  const [terms, setTerm] = useState({
    "confirm-order": tags.has("acceptedTerms"),
    "precise-time": tags.has("acceptedTerms"),
    "empaques": tags.has("acceptedTerms"),
    "menu-up-to-date": tags.has("acceptedTerms"),
    "check-items": tags.has("acceptedTerms"),
    "manage-schedule": tags.has("acceptedTerms")
  })
  const { getTranslation } = useContext(IntlContext)
  const [editVendor, { loading }] = useMutation(EDIT_VENDOR_MUTATION)

  async function save(){
    const alert = toast.loading()
    const valid = Object.entries(terms).filter(([key, item]) => item === false)
    
    try {
      if (valid.length > 0) throw new Error(getTranslation({group: "basic-rules", id: "accept-to-continue"}))

      tags.add("acceptedTerms")

      await editVendor({variables: {id: vendor.id, data: {tags: [...tags]}}})
      alert.success(getTranslation({group: "basic-rules", id: "all-accepted"}))
      navigate('../opening-hours')
    } catch (e) {
      alert.error(e.message)
    }
  }

  function acceptTerm(id, value){
    let _terms = terms
    _terms[id] = value
    setTerm({..._terms})
  }

  return(
    <div id="vendor-terms">
      <div className="note">
        <IntlText group="basic-rules" id="note" />
      </div>
      <div className="terms">
        {Object.entries(terms).map(([key, item]) =>
          <div key={key}  className="term">
            <Checkbox id={key} checked={item} onChange={() => acceptTerm(key, !item)} rounded disabled={hideActions} >
              <span><IntlText group="basic-rules" id={key} /></span>
              <span className="help"><IntlText group="basic-rules" id={`help-${key}`} /></span>
            </Checkbox>
          </div>
        )}
      </div>
      <div className="punishment">
        <FontAwesome name="lightbulb-exclamation" />
        <IntlText group="basic-rules" id="punishment" />
      </div>
      {!hideActions &&
        <div className='actions'>
          <Button onClick={() => navigate("../")}><IntlText id="back" /></Button>
          <Button loading={loading} fluid theme="main" onClick={save}><IntlText group="basic-rules" id="button" /></Button>
        </div>
      }
    </div>
  )
}
