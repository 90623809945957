import React, { useState } from 'react'

import { IntlText, Table } from '../../components'

import { Item } from './Item'

import './style.css'

const defaultFields = {
  customer: true,
  vendorCashPayment: false,
  vendorPriceAdjustmentByCustomerCashBalance: false,
  vendorPrice: false,
  serviceFeeVendor: false,
  total: true,
  tags: true,
  createdAt: true,
  status: true,
}

export function OrdersList({data, visibleFields = {}, dateFormat = 'MM/DD/YYYY HH:mm', link}) {

  const [_visibleFields, setVisibleFields] = useState({...defaultFields, ...visibleFields})

  return(
    <Table.Table id="orders-list">
      <Table.Head>
        <Table.Tr>
          <Table.Th>#</Table.Th>
          {_visibleFields.customer &&
            <Table.Th><IntlText group="orders-list" id="table-header-client" /></Table.Th>
          }
          {_visibleFields.total &&
            <Table.Th><IntlText group="orders-list" id="table-header-total" /></Table.Th>
          }
          {_visibleFields.serviceFeeVendor &&
            <Table.Th><IntlText group="orders-list" id="table-header-serviceFeeVendor" /></Table.Th>
          }
          {_visibleFields.vendorPrice &&
            <Table.Th><IntlText group="orders-list" id="table-header-vendorPrice" /></Table.Th>
          }
          {_visibleFields.vendorPriceAdjustmentByCustomerCashBalance &&
            <Table.Th><IntlText group="orders-list" id="table-header-vendorPriceAdjustmentByCustomerCashBalance" /></Table.Th>
          }
          {_visibleFields.vendorCashPayment &&
            <Table.Th><IntlText group="orders-list" id="table-header-vendorCashPayment" /></Table.Th>
          }
          {_visibleFields.createdAt &&
            <Table.Th><IntlText group="orders-list" id="table-header-date" /></Table.Th>
          }
          {_visibleFields.status &&
            <Table.Th><IntlText group="purchase" id="status" /></Table.Th>
          }
          {_visibleFields.tags &&
            <Table.Th><IntlText group="orders-list" id="table-header-tags" /></Table.Th>
          }
          <Table.Th><Table.VisibleFieldsFilter visibleFields={_visibleFields} setVisibleFields={setVisibleFields} /></Table.Th>
        </Table.Tr>
      </Table.Head>
      <Table.Body>
        {data && data.length > 0 ? data.map((purchase, key) =>
          <Item key={key} id={key} purchase={purchase} dateFormat={dateFormat} visibleFields={_visibleFields} link={link} />
        ) :
          <Table.Tr>
            <Table.Td colSpan={10}><IntlText group="orders-list" id="no-items" /></Table.Td>
          </Table.Tr>
        }
      </Table.Body>
    </Table.Table>
  )

}
