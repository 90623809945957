import { gql }from '@apollo/client'

import { PROMOTION_FRAGMENT } from './'

export const PROMOTIONS = gql`
  query promotions{
     promotions {
       ...PROMOTION_FRAGMENT
     }
  }
  ${PROMOTION_FRAGMENT}
`
