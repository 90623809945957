import { gql }from '@apollo/client'

import { VENDOR_BOOK_ENTRY_FRAGMENT } from './'

export const CREATE_VENDOR_BOOK_ENTRY = gql`
  mutation createVendorBookEntry($data: VendorBookEntryInput!){
     createVendorBookEntry(data: $data){
       ...VENDOR_BOOK_ENTRY_FRAGMENT
     }
  }
  ${VENDOR_BOOK_ENTRY_FRAGMENT}
`
