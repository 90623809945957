import React from 'react'
import { useQuery } from '@apollo/client'

import { returnGraphqlError } from '../../../helpers'
import { Loader, IntlValue, Modal, IntlText, Form, Button } from '../../../components'
import { ProductDetails } from '../../../views'
import { PRODUCT_QUERY } from '../../../graphql'

export function ProductModal() {
    return(
      <Modal.Modal id="product">
        {({attributes, closeModal}) =>
          <ModalInner attributes={attributes} closeModal={closeModal} />
        }
      </Modal.Modal>
    )
}


function ModalInner({attributes, closeModal}) {

  const { loading, data, error } = useQuery(PRODUCT_QUERY, {variables: {id: attributes?.product}, skip: !!!attributes?.product})

  if (loading) return <Loader theme="main" />
  if (error) {
    closeModal()
    return returnGraphqlError(error)
  }


  const { product } = data || {}

  return(
    <Modal.Info>
      <Modal.Header>
        {product ?
          <IntlValue>{product?.name}</IntlValue>
        :
          <IntlText group="product-modal" id="title">Add product</IntlText>
        }
      </Modal.Header>
      <Modal.Content>
        <ProductDetails product={product} onSuccess={closeModal} cancel={closeModal} refetch={attributes?.refetch} actions={
          <Form.Field fluid>
            <Button fluid onClick={closeModal}><IntlText id="back" /></Button>
          </Form.Field>
          } />
      </Modal.Content>
    </Modal.Info>
  )

}
