import { gql }from '@apollo/client'

import { RIDER_FRAGMENT } from './'

const RIDERS_QUERY = gql`
  query riders($active: Boolean){
     riders(active: $active){
       ...RIDER_FRAGMENT
     }
  }
  ${RIDER_FRAGMENT}
`

export default RIDERS_QUERY
