import React, { useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { useValidatedForm } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { returnGraphqlError } from '../../../helpers'
import { LabeledInput, LabeledCheckbox, Button, Validation, IntlText, Form } from '../../../components'
import { EDIT_ADDON_MUTATION, CREATE_ADDON_MUTATION, ADDONS_QUERY } from '../../../graphql'

const validations = {
  "nameEn": [{id: "required", validation: (val) => !!val}],
  "nameEs": [{id: "required", validation: (val) => !!val}],
  "instructionEn": [{id: "required", validation: (val) => !!val}],
  "instructionEs": [{id: "required", validation: (val) => !!val}],
}

export function Addon({addon, success, cancel}) {

  const { getTranslation } = useContext(IntlContext)
  const [editAddon, { loading: editLoading }] = useMutation(EDIT_ADDON_MUTATION)
  const [createAddon, { loading: createLoading }] = useMutation(CREATE_ADDON_MUTATION)
  const [quantityLimits, toggleLimits] = useState(!!(addon?.maxSelect || addon?.minSelect))
  const { form: {nameEn, nameEs, instructionEn, instructionEs, minSelect, maxSelect}, errors, updateField, updateFields, validate } = useValidatedForm({nameEn: addon?.name?.en, nameEs: addon?.name?.es, instructionEn: addon?.instruction?.en, instructionEs: addon?.instruction?.es, minSelect: addon?.minSelect, maxSelect: addon?.maxSelect, disabled: addon?.disabled}, validations)

  async function save(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

    let data = {
      name: {
        es: nameEs,
        en: nameEn
      },
      label: {
        es: nameEs,
        en: nameEn
      },
      instruction: {
        es: instructionEs || "",
        en: instructionEn || ""
      },
      required: !!(minSelect > 0),
      minSelect: parseInt(minSelect),
      maxSelect: parseInt(maxSelect)
    }

    if (addon?.id){
      await edit(data)
    } else {
      await create(data)
    }

  }

  async function create(data){
    try {
      await createAddon({variables: {data}, refetchQueries: [{query: ADDONS_QUERY}]})
      toast.success(getTranslation({id: "save-success"}))
      if (success) success()
    } catch (e) {
      console.log("e", e);
      returnGraphqlError(e)
    }
  }

  async function edit(data){
    try {
      await editAddon({variables: {id: addon?.id, data}})
      toast.success(getTranslation({id: "edit-success"}))
      if (success) success()
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  function enableQuantityLimits(){
    updateFields({fields: [{key: "minSelect", value: 0}, {key: "maxSelect", value: 0}]})
    toggleLimits(!quantityLimits)
  }

  return(
    <div id="addon">
      <Form.Form>
        <div className="warning"><IntlText group="form" id="responsibility-warning" /></div>
        <Form.Field>
          <Validation errors={errors.nameEn}>
            <LabeledInput id="nameEn" placeholder={getTranslation({group: "addon-form", id: "name-en"})} value={nameEn} onChange={({target}) => updateField({key: target.id, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.instructionEn}>
            <LabeledInput id="instructionEn" placeholder={getTranslation({group: "addon-form", id: "instruction-en"})} value={instructionEn} onChange={({target}) => updateField({key: target.id, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.nameEs}>
            <LabeledInput id="nameEs" placeholder={getTranslation({group: "addon-form", id: "name-es"})} value={nameEs} onChange={({target}) => updateField({key: target.id, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.instructionEs}>
            <LabeledInput id="instructionEs" placeholder={getTranslation({group: "addon-form", id: "instruction-es"})} value={instructionEs} onChange={({target}) => updateField({key: target.id, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <LabeledCheckbox id="limits" placeholder={getTranslation({group: "form", id: "quantity-limits"})} checked={quantityLimits} rounded onChange={enableQuantityLimits} />
        </Form.Field>
        {quantityLimits &&
          <Form.Fieldset>
            <Form.Field fluid>
              <LabeledInput id="minSelect" placeholder={getTranslation({group: "form", id: "quantity-limits-min"})} value={minSelect} onChange={({target}) => updateField({key: target.id, value: target.value})} />
            </Form.Field>
            <Form.Field fluid>
              <LabeledInput id="maxSelect" placeholder={getTranslation({group: "form", id: "quantity-limits-max"})} value={maxSelect} onChange={({target}) => updateField({key: target.id, value: target.value})} />
            </Form.Field>
          </Form.Fieldset>
        }
        <Form.Fieldset>
          <Form.Field fluid>
            <Button fluid theme="alt" onClick={cancel}><IntlText id="back">Back</IntlText></Button>
          </Form.Field>
          <Form.Field fluid>
            <Button fluid theme="main" loading={editLoading || createLoading} onClick={save}><IntlText id="save">Save</IntlText></Button>
          </Form.Field>
        </Form.Fieldset>
      </Form.Form>
    </div>
  )

}
