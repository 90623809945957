import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { VENDOR_USER, EDIT_VENDOR_USER } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Form, SwitchBox, Loader, EditBox } from '../../../components'

export function VendorUser() {

  const { id } = useParams()
  const { loading, data, error } = useQuery(VENDOR_USER, {variables: {id}})


  if (loading || error) return <Loader theme="main" />

  return(
    <div id="vendor-user">
     <UserForm user={data?.vendorUser} />
    </div>
  )
}

function UserForm({user}){

  const { getTranslation } = useIntl()
  const [editUser] = useMutation(EDIT_VENDOR_USER)

  async function _editUser(data){
    const alert = toast.loading()
    try {
      await editUser({variables: {id: user.id, data}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (error) {
      console.log("error", error);
      alert.apolloError(error)
    }
  }  

  function required(value){
    if (!value) throw new Error(getTranslation({group: "validation", id: "required"}))
  }
  
  return(
    <Form.Form>
      <Form.Field>
        <EditBox.Text placeholder={getTranslation({group: "vendor-users", id: "name"})} save={(value) => _editUser({name: value})} validation={required}>{user.name}</EditBox.Text>
      </Form.Field>
      <Form.Field>
        <EditBox.Data placeholder={getTranslation({group: "vendor-users", id: "username"})}>{user.username}</EditBox.Data>
      </Form.Field>
      <Form.Field>
        <EditBox.Text text="*******"  placeholder={getTranslation({group: "vendor-users", id: "password"})} save={(value) => _editUser({password: value})} validation={required} />
      </Form.Field>
      <Form.Field>
        <SwitchBox id="canReadPurchases" label={getTranslation({group: "vendor-users", id: "canReadPurchases"})} checked={user?.canReadPurchases} onChange={({target}) => _editUser({canReadPurchases: target.checked})} />          
      </Form.Field>
      <Form.Field>
        <SwitchBox id="canEditPurchases" label={getTranslation({group: "vendor-users", id: "canEditPurchases"})} checked={user?.canEditPurchases} onChange={({target}) => _editUser({canEditPurchases: target.checked})} />          
      </Form.Field>
      <Form.Field>
        <SwitchBox id="canReadFeedback" label={getTranslation({group: "vendor-users", id: "canReadFeedback"})} checked={user?.canReadFeedback} onChange={({target}) => _editUser({canReadFeedback: target.checked})} />          
      </Form.Field>
      <Form.Field>
        <SwitchBox id="canSetProductAvailability" label={getTranslation({group: "vendor-users", id: "canSetProductAvailability"})} checked={user?.canSetProductAvailability} onChange={({target}) => _editUser({canSetProductAvailability: target.checked})} />          
      </Form.Field>
    </Form.Form>
  )

}
