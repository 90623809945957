import React, { useContext, useState } from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { IntlContext } from '../../../contexts'
import { Map, IntlText, Button, Form, EditBox } from '../../../components'
import { ME_QUERY, EDIT_VENDOR_MUTATION } from '../../../graphql'

import './style.css'

export function VendorLocation({vendor: { id, location, pickupInformation}}) {
  const { getTranslation } = useContext(IntlContext)
  const [editVendor, {loading: editLoading}] = useMutation(EDIT_VENDOR_MUTATION, {refetchQueries: [{query: ME_QUERY}]})
  const [edit, toggleEdit] = useState(false)
  const [coordinates, setLocation] = useState(location?.coordinates || [Map.defaultCenter().lng, Map.defaultCenter().lat])

  function changeLocation(e){
    if (!edit) return false

    setLocation([e.lng, e.lat])
  }

  async function save(data){
    try {
      if (!data) return toast.error("Some error")
      await editVendor({variables: {id: id, data}})
      toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }

    toggleEdit(false)
  }

  return(
    <div id="vendor-location">
      <Form.Form>
        <Form.Field>
          <div className="map">
            {edit ?
              <Map.Actions>
                <Button onClick={() => toggleEdit(false)}><IntlText group="order-address" id="cancel">Cancel</IntlText></Button>
                <Button theme="main" loading={editLoading} onClick={() => save({location: {type: "Point", coordinates: coordinates}})}><IntlText id="save" /></Button>
              </Map.Actions>
            : !edit ?
              <Map.Actions>
                <Button onClick={() => toggleEdit(true)}><IntlText id="edit" /></Button>
              </Map.Actions>
            : null}
            <Map.Map center={{lng: coordinates[0], lat: coordinates[1]}} onClick={(e) => changeLocation(e)}>
              <Map.Pin lng={coordinates[0]} lat={coordinates[1]}><FontAwesome name="map-marker" /></Map.Pin>
            </Map.Map>
          </div>
        </Form.Field>
        <Form.Field>
          <EditBox.Text icon="info-circle" placeholder={<IntlText group="vendor-location" id="input-pickup-information" />} save={(value) => save({pickupInformation: value})}>{pickupInformation}</EditBox.Text>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
