import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts'
import FontAwesome from 'react-fontawesome'

import { formatPrice } from '../../helpers'
import { IntlContext } from '../../contexts'
import { VENDOR_STAT } from '../../graphql'
import { Title, IntlText, Table, IntlValue, Loader } from '../../components'

import './style.css'

export function VendorStat() {
  
  const { getTranslation } = useContext(IntlContext)
  const { id } = useParams()
  const { data, loading, error } = useQuery(VENDOR_STAT, {variables: {id}, fetchPolicy: "network-only"})
  
  const ordersData = [
    { name: getTranslation({group: "vendor-stat", id: "completed-orders", variables: {value: data?.vendorStat?.completedPurchases}}), value: data?.vendorStat?.completedPurchases, color: "var(--valid-color)" },
    { name: getTranslation({group: "vendor-stat", id: "unprocessed-orders", variables: {value: data?.vendorStat?.unprocessedPurchases}}), value: data?.vendorStat?.unprocessedPurchases, color: "var(--invalid-color)" },
    { name: getTranslation({group: "vendor-stat", id: "cancelled-orders", variables: {value: data?.vendorStat?.cancelledPurchases + data?.vendorStat?.rejectedPurchases}}), value: data?.vendorStat?.cancelledPurchases + data?.vendorStat?.rejectedPurchases, color: "grey" }
  ]

  const feedbackData = [
    { name: getTranslation({group: "vendor-stat", id: "positive-feedback", variables: {value: data?.vendorStat?.positiveFeedbacks}}), value: data?.vendorStat?.positiveFeedbacks, color: "var(--valid-color)" },
    { name: getTranslation({group: "vendor-stat", id: "neutral-feedback", variables: {value: data?.vendorStat?.neutralFeedbacks}}), value: data?.vendorStat?.neutralFeedbacks, color: "var(--warning-color)" },
    { name: getTranslation({group: "vendor-stat", id: "negative-feedback", variables: {value: data?.vendorStat?.negativeFeedbacks}}), value: data?.vendorStat?.negativeFeedbacks, color: "var(--invalid-color)" }
  ]

  if (loading || error) return <Loader theme='main' />

  return(
    <div id="vendor-stat" className="container">
      <div className="row slider">
        <div className='stat-cards'>
          <div className='stat'>
              <div className='label'><IntlText group="vendor-stat" id="gross-income" /></div>
              <div className='value'>{formatPrice(Math.round(data?.vendorStat?.grossIncome))}
                  <div className="value-change">
                      <span className={`${data?.vendorStat?.grossIncomeChange > 0 ? "positive" : "negative"}`}>{(data?.vendorStat?.grossIncomeChangePercentage * 100).toFixed(2)}%</span>
                  </div>                    
              </div>
          </div>                    
          <div className='stat'>
              <div className='label'><IntlText group="vendor-stat" id="service-fee" /></div>
              <div className='value'>{formatPrice(Math.round(data?.vendorStat?.servicefee))}
                  <div className="value-change">
                      <span className={`${data?.vendorStat?.servicefeeChange > 0 ? "positive" : "negative"}`}>{(data?.vendorStat?.servicefeeChangePercentage * 100).toFixed(2)}%</span>
                  </div>                    
              </div>
          </div>                    
          <div className='stat'>
              <div className='label'><IntlText group="vendor-stat" id="net-income" /></div>
              <div className='value'>{formatPrice(Math.round(data?.vendorStat?.netIncome))}
                  <div className="value-change">
                      <span className={`${data?.vendorStat?.netIncomeChange > 0 ? "positive" : "negative"}`}>{(data?.vendorStat?.netIncomeChangePercentage * 100).toFixed(2)}%</span>
                  </div>                    
              </div>
          </div>                    
          <div className='stat'>
              <div className='label'><IntlText group="vendor-stat" id="aov" /></div>
              <div className='value'>{formatPrice(Math.round(data?.vendorStat?.aov))}
                  <div className="value-change">
                      <span className={`${data?.vendorStat?.aovChange > 0 ? "positive" : "negative"}`}>{(data?.vendorStat?.aovChangePercentage * 100).toFixed(2)}%</span>
                  </div>                    
              </div>
          </div>
          <div className='stat'>
              <div className='label'><IntlText group="vendor-stat" id="completed" /></div>
              <div className='value'>{Math.round(data?.vendorStat?.completedPurchases)}
                  <div className="value-change">
                      <span className={`${data?.vendorStat?.completedPurchasesChange > 0 ? "positive" : "negative"}`}>{(data?.vendorStat?.completedPurchasesChangePercentage * 100).toFixed(2)}%</span>
                  </div>                    
              </div>
          </div>
          <div className='stat'>
              <div className='label'><IntlText group="vendor-stat" id="unprocessed" /></div>
              <div className='value'>{Math.round(data?.vendorStat?.unprocessedPurchases)}
                  <div className="value-change">
                      <span className={`${data?.vendorStat?.unprocessedPurchasesChange > 0 ? "positive" : "negative"}`}>{(data?.vendorStat?.unprocessedPurchasesChangePercentage * 100).toFixed(2)}%</span>
                  </div>                    
              </div>
          </div>
          <div className='stat'>
              <div className='label'><IntlText group="vendor-stat" id="feedbacks" /></div>
              <div className='value'>{Math.round(data?.vendorStat?.feedbacks)}
                  <div className="value-change">
                      <span className="positive"><FontAwesome name="smile" /> {data?.vendorStat?.positiveFeedbacks}</span>
                      <span className="neutral"><FontAwesome name="meh" /> {data?.vendorStat?.neutralFeedbacks}</span>
                      <span className="negative"><FontAwesome name="frown" /> {data?.vendorStat?.negativeFeedbacks}</span>
                  </div>                    
              </div>
          </div>
        </div>
      </div>
      <div className="row padding">
        <div className='pie-charts'>
          <div className='chart'>
            <Title tag="h3"><IntlText group="vendor-stat" id="number-of-orders" /></Title>
            <ResponsiveContainer height={300} className="chart-wrapper">
              <PieChart>
                <Legend />
                <Pie
                  data={ordersData}
                  labelLine={false}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >

                  {ordersData.map((_, index) => 
                    <Cell key={`cell-${index}`} fill={ordersData[index].color} stroke={ordersData[index].color} />
                  )}
                </Pie>
              </PieChart> 
            </ResponsiveContainer>
          </div>
          <div className='chart'>
            <Title tag="h3"><IntlText group="vendor-stat" id="number-of-feedbacks" /></Title>
            <ResponsiveContainer height={300} className="chart-wrapper">
              <PieChart>
                <Legend />
                <Pie
                  data={feedbackData}
                  labelLine={false}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {feedbackData.map((_, index) => 
                    <Cell key={`cell-${index}`} fill={feedbackData[index].color} stroke={feedbackData[index].color} />
                  )}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="row padding">
        <Table.Table>
          <Table.Head>
            <Table.Tr>
              <Table.Th className="text-left"><IntlText group="vendor-stat" id="name" /></Table.Th>
              <Table.Th><IntlText group="vendor-stat" id="category" /></Table.Th>
              <Table.Th><IntlText group="vendor-stat" id="qty" /></Table.Th>
            </Table.Tr>
          </Table.Head>
          <Table.Body>
          {data?.vendorStat?.mostOrderedProducts?.map((product, key) => 
            <Table.Tr key={key} >
              <Table.Td className="text-left"><IntlValue>{product.name}</IntlValue></Table.Td>
              <Table.Td><IntlValue>{product.categoryName}</IntlValue></Table.Td>
              <Table.Td>{product.count}</Table.Td>
            </Table.Tr>
          )}
          </Table.Body>  
        </Table.Table>              
      </div>
    </div>
  )
}
