import { useQuery } from '@apollo/client'

import { CHANGELOGS } from '../../graphql'
import { Loader } from '../../components'

export function Changelog() {

  const { loading, data, error } = useQuery(CHANGELOGS, {variables: {type: "VENDOR_WEB"}})

  if (loading || error) return <Loader theme="main" />

  const { changelogs } = data
  console.log("changelogs", changelogs);

  return(
    <div className="container">
      <div className="row padding">
        <div className="relative">

        </div>
      </div>
    </div>
  )
}
