import { useEffect } from 'react'

function fixLeft({element, bounding, offset}){
  console.log("Left is out")
  element.current.style.right = `${bounding.left - offset}px`
}

function fixRight({element, bounding, offset}){
  element.current.style.left = `${((window.innerWidth || document.documentElement.clientWidth) - bounding.right) - offset}px`
  console.log("Right is out")
}

function fixBottom({element, bounding, offset}){
  element.current.style.bottom = `0px`
  element.current.style.top = `auto`
  element.current.style.transform = `none`
  console.log("Bottom is out")
}

export function useFitInView({element, offset = 0, left = fixLeft, right = fixRight, bottom = fixBottom}){
  useEffect(() => {
    if (!element?.current) return
    const bounding = element?.current?.getBoundingClientRect()

    if (bounding.left < 0) {
      left({element, bounding, offset})
    }

    if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
      right({element, bounding, offset})
    }

    if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
      bottom({element, bounding, offset})
    }    

    // if (bounding.top < 0) {
    //   console.log("Top is out");
    // }

    //
    // if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
    // }

  })
}
