import { formatPrice } from '../../../helpers'
import { IntlText, Notification } from '../../../components'
import { PurchasePromotions } from '../'

import './style.css'

export function PurchaseTotals({purchase, purchase: { price, vendor, payment }}) {

  return(
    <div id="purchase-totals">
      <PurchasePromotions purchase={purchase} />
      <table className="totals-table">
        <tbody>
          <tr className="purchase-total">
            <td>
              <IntlText group="purchase-totals" id="subtotal" />
            </td>
            <td>{formatPrice(price["subtotal"])}</td>
          </tr>
          {(vendor.serviceFeeChargeType !== "PERIODICAL" || (payment === "CARD_STRIPE" || payment === "CARD_CONEKTA") ) &&
            <tr className="purchase-total">
              <td>
                <IntlText group="purchase-totals" id="service-fee" />
              </td>
              <td>-{formatPrice(price["serviceFeeVendor"])}</td>
            </tr>
          }
          {vendor.serviceFeeChargeType !== "PERIODICAL" &&
            <tr className="purchase-total">
              <td>
                <IntlText group="purchase-totals" id="net-income" />
              </td>
              <td>{formatPrice(price["vendorPrice"])}</td>
            </tr>          
          }
          {!!price["vendorCashBalanceAdjustment"] &&
            <tr className="purchase-total">
              <td>
                <IntlText group="purchase-totals" id="vendor-cash-balance-adjustment" />
              </td>
              <td>{formatPrice(-price["vendorCashBalanceAdjustment"])}</td>
            </tr>
          }
          {(!!price["vendorPriceAdjustmentByCustomerCashBalance"] || !!price['vendorPriceAdjustmentByRiderCashCreditBalance']) &&
            <tr className="purchase-total">
              <td>
                <IntlText group="purchase-totals" id="tomato-network-transaction" />
              </td>
              <td>{formatPrice(price["vendorPriceAdjustmentByCustomerCashBalance"] + price['vendorPriceAdjustmentByRiderCashCreditBalance'])}</td>
            </tr>
          }
          {(payment === "CARD_STRIPE" || payment === "CARD_CONEKTA") && 
            <tr className="purchase-total">
              <td>
                <IntlText group="purchase-totals" id="stripe-deposit" />
              </td>
              <td>-{formatPrice(price["vendorPrice"])}</td>
            </tr>            
          }
          <tr className="purchase-total vendorPrice">
            <td>
              <IntlText group="purchase-totals" id="cash-payment" />
            </td>
            <td>{formatPrice(price["vendorCashPayment"])}</td>
          </tr>
        </tbody>
      </table>
      {(payment === "CARD_STRIPE" || payment === "CARD_CONEKTA") && 
        <Notification icon="exclamation-triangle" type='ui'>
          <IntlText group="purchase-totals" id="stripe-deposit-info" />
        </Notification>           
      }
      {(!!price["vendorPriceAdjustmentByCustomerCashBalance"] || !!price['vendorPriceAdjustmentByRiderCashCreditBalance']) &&
        <Notification icon="exclamation-triangle" type='ui'>
          <IntlText group="purchase-totals" id="tomato-network-transaction-info" />
        </Notification>         
      }
      {price["vendorCashPayment"] < 0 && 
        <Notification icon="exclamation-triangle" type='ui'>
          <IntlText group="purchase-totals" id="negative-payment" variables={{amount: Math.abs(price["vendorCashPayment"])}} />
        </Notification>       
      }
    </div>
  )
}
