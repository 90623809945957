import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { IntlContext } from '../../contexts'
import { PAGE_QUERY } from '../../graphql'
import { Title, IntlValue, Text, Loader, IntlText } from '../../components'
import { WhatsAppHelp } from '../../views'

export function Page({slug, showContact}) {

  const { selectedLanguage } = useContext(IntlContext)
  const { loading, data, error } = useQuery(PAGE_QUERY, {variables: {slug}})
  
  if (loading || error) return <Loader theme='main' />
  if (!data?.page) return null
  
  const { title, description, content, template } = data.page
  let text = content ? content[selectedLanguage.shortcode] : ""

  function renderPage(){
    switch (template) {
      default:
        return(
          <div id="page" className="container">
            <div className="row wrapped">
              <div className="row-header">
                <div className="heading">
                  <Title tag="h2"><IntlValue>{title}</IntlValue></Title>
                  <div className="desc"><IntlValue>{description}</IntlValue></div>
                </div>
              </div>
              <Text>{text}</Text>      
              {showContact &&
                <WhatsAppHelp>
                  <IntlText id="send-message" />
                </WhatsAppHelp>
              }
            </div>
          </div>
        )
    }
  }

  return renderPage()

}
