import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { EDIT_VENDOR_MUTATION } from '../../graphql'
import { IntlContext } from '../../contexts'
import { toast } from '../../helpers'
import { Heading, IntlText, Button } from '../../components'
import { DeliverySettings } from '../../views'

export function Delivery({vendor}) {

  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const tags = new Set(vendor?.tags)
  
  async function done(){
    const alert = toast.loading()
    tags.delete("firstLogin")

    try {
      await editVendor({variables: {id: vendor.id, data: {tags: [...tags]}}})
      navigate("../")
      alert.success(getTranslation({group: "wizard", id: "done"}))
    } catch (e) {
      alert.error(e.message)
    }
  }
 

  return(
    <div>
      <Heading spacing info={<IntlText group="wizard" id="delivery-info" />}>
        <IntlText group='wizard' id='delivery' />
      </Heading>          
      <DeliverySettings vendor={vendor} />
      <div className='actions' style={{marginTop: 30}}>
        <Button onClick={() => navigate("../payment")}><IntlText id="back" /></Button>
        <Button theme="main" onClick={done}><IntlText id="done" /></Button>
      </div>      
    </div>
  )
}
