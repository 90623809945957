import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Heading, IntlText, Button } from '../../components'
import { HelpCenter as HelpCenterView } from '../../views'

export function HelpCenter() {

  const navigate = useNavigate()

  return(
    <div>
      <Heading spacing info={<IntlText group="help-center" id="info" />}>
        <IntlText group='help-center' id='title' />
      </Heading>          
      <HelpCenterView hideTerms />
      <div className='actions' style={{marginTop: 30}}>
        <Button onClick={() => navigate("/")}><IntlText id="back" /></Button>
      </div>      
    </div>
  )
}
