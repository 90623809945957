import { gql }from '@apollo/client'

const REORDER_PRODUCTS_MUTATION = gql`
  mutation reorderProducts($data: [ProductReorderInput]!){
    reorderProducts(data: $data){
      success
    }
  }
`

export default REORDER_PRODUCTS_MUTATION
