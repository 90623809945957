import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_ADDON_OPTION_MUTATION, DELETE_ADDON_OPTION_MUTATION } from '../../../graphql'
import { useModal, useConfirm, useIntl } from '../../../hooks'
import { toast, formatPrice } from '../../../helpers'
import { Loader, Filter, IntlText, IntlValue, LabeledCheckbox, StatusDot, Table, ContextMenu, Modalv2, Title, Restricted } from '../../../components'
import { AddonOption, AddonOptionVendorPrice } from '../../../views'


const defaultFields = {
  vendorPrice: false,
}

export function AddonOptionsList({refetch, data, filter, visibleFields = {}}){

  const modal = useModal()
  const { confirm } = useConfirm()
  const [_visibleFields, setVisibleFields] = useState({...defaultFields, ...visibleFields})  
  const { getTranslatedValue, getTranslation } = useIntl()
  const [editAddonOption, { loading: editLoading }] = useMutation(EDIT_ADDON_OPTION_MUTATION)
  const [deleteAddonOption, { loading: deleteLoading }] = useMutation(DELETE_ADDON_OPTION_MUTATION)


  async function _deleteAddonOption(id){
    const alert = toast.loading()
    try {
      await confirm()
      await deleteAddonOption({variables: {id}})
      refetch()
      alert.success(getTranslation({id: "delete-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function _editAddonOption(data){
    const alert = toast.loading()
    try {
      await editAddonOption({variables: data})
      refetch()
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function onSuccess(){
    await refetch()
    modal.hide()
  }    

  return(
    <div id="addon-options-list" className="relative">
      {(!!deleteLoading || !!editLoading) && <div className="full-size-loader"><Loader theme="main"/></div>}
      <Filter id="addons" data={data?.productAddonOptions || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => data.id}, {filter: (data) => getTranslatedValue(data.name)}, {filter: (data) => data.disabled}]}>
        {(data) =>
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th className="text-left"><IntlText group="table-header" id="name" /></Table.Th>
                <Table.Th><IntlText group="table-header" id="price" /></Table.Th>
                {_visibleFields.vendorPrice && 
                  <Table.Th><IntlText group="table-header" id="vendorPrice" /></Table.Th>
                }
                <Table.Th><IntlText group="table-header" id="disabled" /></Table.Th>
                <Table.Th><Table.VisibleFieldsFilter visibleFields={_visibleFields} setVisibleFields={setVisibleFields} /></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {data.map((_addonOption) =>
                <Table.Tr key={_addonOption.id} className="addon">
                  <Table.Td><StatusDot active={!_addonOption.disabled} /></Table.Td>
                  <Table.Td className="text-left"><IntlValue value={_addonOption.name} /></Table.Td>
                  <Table.Td>{formatPrice(_addonOption.price)}</Table.Td>
                  {_visibleFields.vendorPrice && 
                    <Table.Td><AddonOptionVendorPrice option={_addonOption} /></Table.Td>
                  }
                  <Table.Td centered><LabeledCheckbox id={`disabled-${_addonOption.id}`} checked={!_addonOption.disabled} onChange={(e) => _editAddonOption({id: _addonOption.id, data: {disabled: !e.target.checked}})} rounded/></Table.Td>
                  <Table.Td>
                    <Restricted>
                      <ContextMenu.Menu id={_addonOption.id} >
                        <ContextMenu.Button icon="edit" onClick={() => modal.show(_addonOption)}><IntlText id="quick-edit" /></ContextMenu.Button>
                        <ContextMenu.Button icon="trash" className='invalid' onClick={() => _deleteAddonOption(_addonOption.id)}><IntlText id="delete" /></ContextMenu.Button>
                      </ContextMenu.Menu>
                    </Restricted>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Table.Table>
        }
      </Filter>
      <Modalv2 id="add-addon-option" modal={modal} header={<Title tag="h3"><IntlValue value={modal.state?.name} /></Title>}>
        <AddonOption addonOption={modal.state} success={onSuccess} cancel={() => modal.hide()} />
      </Modalv2>          
    </div>
  )
}
