import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import history from './history'
import { useAuth } from './hooks'
import { Loader } from './components'

import { ScrollToTop, PrivateRoute, Wrapper, Configurable } from './components'
import { ChangelogModal, InvoiceAlertModal, AlertModal, AlertBar, CategoryModal, ProductModal, AddProductAddonModal, AddProductAddonOptionModal, ExclusiveOfferModal } from './views'
import { 
  NotFound, 
  NewPromotion, Promotion, Promotions, 
  Balance, 
  Orders, OrdersHistory, Purchase, 
  Vendor, VendorSetupWizard,
  Basic, Featured, 
  Product, NewProduct, Products, 
  HelpCenter, Help, DeliveryZone, Terms,
  Category, Categories, NewCategory, 
  Addons, AddonOptions, 
  Feedbacks, 
  Materials, Photoshoot, FoodMagazin,
  VendorStat, VendorStats,
  Invoices, Invoice,
  Login,
  Page,
  VendorTransactions,
  Changelogs, Changelog
} from './containers'

export default function AppRouter() {

  const { isLoggedIn, loading, user } = useAuth()

  if (loading) return <Loader theme="main"/>

  return (
    <BrowserRouter history={history}>
      {isLoggedIn && 
        <>
          <AlertBar />
          <Configurable id="OPERATION_CHANGE_MODAL">
            <AlertModal />
          </Configurable>
          <Configurable id="INVOICE_ALERT_MODAL">
            <InvoiceAlertModal />
          </Configurable>
          <Configurable id="EXCLUSIVE_PACKAGE_ACTIVE">
            <ExclusiveOfferModal />
          </Configurable>
          <ChangelogModal />
        </>
      }
      <div id="modal-portal" />
      <ScrollToTop>
        <Routes>
          {user?.tags?.includes("firstLogin") ? 
            <Route path="*" element={<VendorSetupWizard />} />         
          : user?.role === "VENDOR_USER" ? 
            <>
              <Route path="login" element={<Login />} />
              <Route path="*" element={<Wrapper><Outlet /></Wrapper>}>
                <Route path="*" element={<NotFound />} />       
                {user?.canReadPurchases &&
                  <>
                    <Route path='' element={<AuthenticatedRoute />}>
                      <Route path="" element={<Orders />} />
                      <Route path=":id" element={<Purchase />} />
                    </Route>                     
                    <Route path='orders' element={<AuthenticatedRoute />}>
                      <Route path="" element={<OrdersHistory />} />
                      <Route path=":id" element={<Purchase />} />
                    </Route>                          
                  </>
                }
                {user?.canReadFeedback && 
                  <Route path="feedbacks" element={
                    <PrivateRoute>
                      <Feedbacks />
                    </PrivateRoute>
                  } />                   
                }
                {user?.canSetProductAvailability && 
                  <>
                    <Route path='categories' element={<AuthenticatedRoute />}>
                      <Route path="" element={<Categories />} />
                    </Route>                
                    <Route path="products" element={<AuthenticatedRoute />}>
                      <Route path="" element={<Products />} />
                    </Route>            
                    <Route path='addons' element={<AuthenticatedRoute />}>
                      <Route path="" element={<Addons />} />
                      <Route path="options" element={<AddonOptions />} />
                    </Route>                 
                  </>
                }
              </Route>
            </>
          :
            <>
              <Route path="login" element={<Login />} />
              <Route path="*" element={<Wrapper><Outlet /></Wrapper>}>
                <Route path='' element={<AuthenticatedRoute />}>
                  <Route path="" element={<Orders />} />
                  <Route path=":id" element={<Purchase />} />
                </Route>                                   
                <Route path="aviso-de-privacidad-afiliado-comercial" element={<Page slug="aviso-de-privacidad-afiliado-comercial" />}/>
                <Route path="terminos-y-condiciones-afiliado-comercial" element={<Page slug="terminos-y-condiciones-afiliado-comercial" />}/>
                <Route path="become-exclusive" element={<Page slug="become-exclusive" showContact />}/>                  
                <Route path="transactions" element={
                  <PrivateRoute>
                    <VendorTransactions />
                  </PrivateRoute>
                } />                   
                <Route path='orders' element={<AuthenticatedRoute />}>
                  <Route path="" element={<OrdersHistory />} />
                  <Route path=":id" element={<Purchase />} />
                </Route>                
                <Route path='stats' element={<AuthenticatedRoute />}>
                  <Route path="" element={<VendorStats />} />
                  <Route path=":id" element={<VendorStat />} />
                </Route>            
                <Route path="balance" element={
                  <PrivateRoute>
                    <Balance />
                  </PrivateRoute>
                } />                    
                <Route path="feedbacks" element={
                  <PrivateRoute>
                    <Feedbacks />
                  </PrivateRoute>
                } />                            
                <Route path='categories' element={<AuthenticatedRoute />}>
                  <Route path="" element={<Categories />} />
                  <Route path="new" element={<NewCategory />} />
                  <Route path=":id" element={<Category />} />
                </Route>                
                <Route path="products" element={<AuthenticatedRoute />}>
                  <Route path="" element={<Products />} />
                  <Route path="new" element={<NewProduct />} />
                  <Route path="new/:id" element={<NewProduct />} />
                  <Route path=":id/*" element={<Product />} />
                </Route>            
                <Route path='addons' element={<AuthenticatedRoute />}>
                  <Route path="" element={<Addons />} />
                  <Route path="options" element={<AddonOptions />} />
                </Route>                
                <Route path='promotions' element={<AuthenticatedRoute />}>
                  <Route path="" element={<Promotions />} />
                  <Route path="new" element={<NewPromotion />} />
                  <Route path=":id" element={<Promotion />} />
                </Route>      
                <Route path='marketing' >
                  <Route path="brand-identity" element={<Materials />} />
                  <Route path="photoshoot" element={
                    <Configurable id="PHOTOSHOOT_AVAILABLE">
                      <PrivateRoute><Photoshoot /></PrivateRoute>
                    </Configurable>
                  } />
                  <Route path="food-magazin" element={
                    <Configurable id="FOOD_MAGAZINES_AVAILABLE">
                      <PrivateRoute><FoodMagazin /></PrivateRoute>
                    </Configurable>
                  } />
                </Route>
                <Route path="invoices/*" element={<AuthenticatedRoute />}>
                  <Route path="" element={<Invoices />} />
                  <Route path=":invoiceID" element={<Invoice />} />
                  <Route path="*" element={<NotFound />} />                     
                </Route>
                <Route path="settings/*" element={<AuthenticatedRoute />}>
                  <Route path="*" element={<Vendor />} />
                </Route>
                <Route path="help" element={<AuthenticatedRoute />}>
                  <Route path="" element={<HelpCenter />} />
                  <Route path="delivery-zone" element={<DeliveryZone />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="changelogs">
                    <Route path="" element={<Changelogs />} />
                    <Route path=':id' element={<Changelog />} />
                  </Route>
                  <Route path=":id" element={<Help />} />
                </Route>
                <Route path="packages">
                  <Route path="" element={<Basic />} />
                  <Route path="basic" element={<Basic />} />
                  <Route path="featured" element={<Featured />} />
                </Route>                
                <Route path="offers/:packageName" element={<Navigate to="/packages" />} /> 
                <Route path="offers" element={<Navigate to="/packages" />} /> 
                <Route path="faq" element={<Navigate to="/help" />} />                 
                <Route path="*" element={<NotFound />} />                     
              </Route>         
            </>
          }      
        </Routes>
      </ScrollToTop>
      <CategoryModal />
      <ProductModal />
      <AddProductAddonModal />
      <AddProductAddonOptionModal />
      <ToastContainer />
    </BrowserRouter>
  )
}

function AuthenticatedRoute(){
  return(
    <PrivateRoute>
      <Outlet />
    </PrivateRoute>
  )
}