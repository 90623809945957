import React from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'
import { Link } from 'react-router-dom'

import { SETTING } from '../../../graphql'
import { IntlText } from '../../../components'

import './style.css'

export function HelpCenter({hideTerms}) {

    const { loading, data, error } = useQuery(SETTING, {variables: {key: "supportContact"}}) 

    return(
        <div id="help-center">
            {!hideTerms &&
                <Link to="/help/terms">
                    <div className="help-topic">
                        <div className="icon"><FontAwesome name="balance-scale" /></div>
                        <div className="info">
                            <span><IntlText group="help" id="TERMS-title" /></span>
                            <span className="help"><IntlText group="help" id="TERMS-desc" /></span>
                        </div>
                    </div>
                </Link>
            }
            <Link to="/help/get-started">
                <div className="help-topic">
                    <div className="icon"><FontAwesome name="play" /></div>
                    <div className="info">
                        <span><IntlText group="help" id="VENDOR_ONBOARDING-title" /></span>
                        <span className="help"><IntlText group="help" id="VENDOR_ONBOARDING-desc" /></span>
                    </div>
                </div>
            </Link>
            <Link to="/help/purchases">
                <div className="help-topic">
                    <div className="icon"><FontAwesome name="shopping-cart" /></div>
                    <div className="info">
                        <span><IntlText group="help" id="VENDOR_PURCHASE-title" /></span>
                        <span className="help"><IntlText group="help" id="VENDOR_PURCHASE-desc" /></span>
                    </div>
                </div>
            </Link>
            {!loading && !error && 
                <a href={`https://wa.me/+${data?.setting?.value}`} target="_blank" rel="noreferrer">
                    <div className="help-topic">
                        <div className="icon"><span style={{color: "var(--whatsapp)"}} className="fab fa-whatsapp" /></div>
                        <div className="info">
                            <span><IntlText group="help" id="WHATSAPP-title" /></span>
                            <span className="help"><IntlText group="help"  id="WHATSAPP-desc" /></span>
                        </div>
                    </div>
                </a>
            }
        </div>
    )
}
