import React, { useState, useContext } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useNavigate, useLocation } from "react-router-dom"
import { useQuery } from '@apollo/client'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, ComposedChart, LabelList } from 'recharts'

import { useQueryBuilder } from '../../hooks'
import { vendorStat, valueAccessor } from '../../helpers'
import { IntlContext } from '../../contexts'
import { VENDOR_STATS } from '../../graphql'
import { IntlText, DatePicker, Filters, SegmentedControl, Link, Loader } from '../../components'
import { VendorStat } from '../../views'

export function VendorStats() {
  
  const [selected, select] = useState("orders")
  const navigation = useNavigate()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { getTranslation } = useContext(IntlContext)
  const { params, addParam, removeParam, isQuery, buildQuery, runQuery, updateQuery } = useQueryBuilder({
    data:{
      periodicity: {key: "periodicity", label: search.get("periodicity") || getTranslation({group: "vendor-stats", id: "WEEK"}), value: search.get("periodicity") || "WEEK"},
      start: {key: "start", label: search.get("start") || moment().subtract(90, 'days').startOf('day').format("YYYY-MM-DD"), value: search.get("start") || moment().subtract(90, 'days').startOf('day').format("YYYY-MM-DD")},
      end: {key: "end", label: search.get("end") || moment().format("YYYY-MM-DD"), value: search.get("end") || moment().format("YYYY-MM-DD")}      
    }
  }, [
    {id: "start", validation: (obj) => !!obj?.value, error: "You have to select date"},
    {id: "end", validation: (obj) => !!obj?.value, error: "You have to select date"},
    {id: "periodicity", validation: (obj) => !!obj?.value, error: "You have to select periodicity"},
  ])
  const { data, loading, error } = useQuery(VENDOR_STATS, {variables: buildQuery(), skip: !isQuery(), fetchPolicy: "network-only"})

  function setDate({selection: {startDate, endDate}}){
    const _start = moment(startDate).startOf("day")
    const _end = moment(endDate).add(1, "days").startOf("day")

    if (params?.periodicity?.value === "DAY" && _end.diff(_start, 'month') > 3) return toast.error(<IntlText group="history-filter" id="max-date" />)

    addParam({
      start: {key: "start", label: _start.format("YYYY-MM-DD"), value: _start.format("YYYY-MM-DD"), skip: !_start},
      end: {key: "end", label: _end.format("YYYY-MM-DD"), value: _end.format("YYYY-MM-DD"), skip: !_end},
    })
  }    

  function handleClick(item){
    navigation(`/stats/${item.id}`)
  }
  
  if (loading || error) return <Loader theme='main' />

  const vendorStats = Array.from(data?.vendorStats)

  if (vendorStats) vendorStats.sort((a, b) => a.start - b.start)

  return(
    <div id="vendor-stats" className="container">
      <div className="row padding">
        <div>
          <Filters.Bar >
            <Filters.Select value={params?.periodicity?.label} placeholder={getTranslation({group: "vendor-stats", id: "periodicity"})} reset={() => removeParam("periodicity")}>
              <li onClick={() => updateQuery({periodicity: {key: "periodicity", label: getTranslation({group: "vendor-stats", id: "MONTH"}), value: "MONTH"}})}><span>{getTranslation({group: "vendor-stats", id: "MONTH"})}</span></li>
              <li onClick={() => updateQuery({periodicity: {key: "periodicity", label: getTranslation({group: "vendor-stats", id: "WEEK"}), value: "WEEK"}})}><span>{getTranslation({group: "vendor-stats", id: "WEEK"})}</span></li>
              <li onClick={() => updateQuery({periodicity: {key: "periodicity", label: getTranslation({group: "vendor-stats", id: "DAY"}), value: "DAY"}})}><span>{getTranslation({group: "vendor-stats", id: "DAY"})}</span></li>
            </Filters.Select>
            <Filters.Dropdown style={{marginLeft: "auto"}} placeholder={getTranslation({group: "vendor-stats", id: "date"})} value={params?.start && params?.end && `${params?.start?.label} - ${params?.end?.label}`} disableInClickClose right reset={() => removeParam("date")} run={runQuery}>
              <DatePicker.Range startDate={params?.start?.value ? moment(`${params.start.value}`).toDate() : new Date()} endDate={params?.end?.value ? moment(`${params.end.value}`).toDate() : new Date()} onChange={setDate} />
            </Filters.Dropdown>
          </Filters.Bar>
        </div>
        <div>
          {vendorStats &&
            <div style={{marginTop: 20}}>
              <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 20}}>
                <SegmentedControl value={selected} options={[{id: "orders", label: getTranslation({group: "vendor-stats", id: "orders"})}, {id: "income", label: getTranslation({group: "vendor-stats", id: "income"})}, {id: "aov", label: getTranslation({group: "vendor-stats", id: "aov"})}, {id: "feedbacks", label: getTranslation({group: "vendor-stats", id: "feedbacks"})}]} onClick={(_option) => select(_option.id)}/>          
              </div>              
              {selected === "orders" ?
                <ResponsiveContainer width="100%" height={300} style={{background: "var(--ui-element)", borderRadius: 5}}>
                  <BarChart data={vendorStats} >
                    <CartesianGrid strokeDasharray="3 3" stroke="var(--ui-placeholder)" />
                    <XAxis dataKey={vendorStat.getLabel} />
                    <YAxis type='number' label={{ value: getTranslation({group: "vendor-stats", id: "orders"}), angle: -90, position: 'center', dx: -20 }} />
                    <Tooltip cursor={{fill: 'var(--ui-bg)', opacity: 0.5}} contentStyle={{background: "var(--ui-element)", border: 0, borderRadius: 5, padding: 20}} />
                    <Bar name={getTranslation({group: "vendor-stats", id: "unprocessed-purchases"})} dataKey="unprocessedPurchases" stackId="a" fill="var(--invalid-color)" onClick={handleClick} />
                    <Bar name={getTranslation({group: "vendor-stats", id: "cancelled-purchases"})} dataKey="cancelledPurchases" stackId="a" fill="var(--invalid-color)" onClick={handleClick} />
                    <Bar name={getTranslation({group: "vendor-stats", id: "rejected-purchases"})} dataKey="rejectedPurchases" stackId="a" fill="var(--invalid-color)" onClick={handleClick} />
                    <Bar name={getTranslation({group: "vendor-stats", id: "completed-purchases"})} dataKey="completedPurchases" stackId="a" fill="var(--valid-color)" radius={[10, 10, 0, 0]} onClick={handleClick} >
                      <LabelList valueAccessor={valueAccessor("completedPurchases")} style={{fill: "white", fontWeight: "bold"}} />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>     
              : selected === "income" ?
                <ResponsiveContainer width="100%" height={300} style={{background: "var(--ui-element)", borderRadius: 5}}>
                  <ComposedChart data={vendorStats}>
                    <CartesianGrid strokeDasharray="3 3" stroke="var(--ui-placeholder)" />
                    <XAxis dataKey={vendorStat.getLabel} />
                    <YAxis type='number' label={{ value: getTranslation({group: "vendor-stats", id: "income"}), angle: -90, position: 'center', dx: -30 }} />
                    <Tooltip cursor={{fill: 'var(--ui-bg)', opacity: 0.5}} contentStyle={{background: "var(--ui-element)", border: 0, borderRadius: 5, padding: 20}} />
                    <Line strokeWidth={2} type="monotone" dataKey="netIncome" name={getTranslation({group: "vendor-stats", id: "income"})} unit={" MXN"} stroke="var(--main-color)" />
                  </ComposedChart>
                </ResponsiveContainer>                 
              : selected === "aov" ?
                <ResponsiveContainer width="100%" height={300} style={{background: "var(--ui-element)", borderRadius: 5}}>
                  <ComposedChart data={vendorStats}>
                    <CartesianGrid strokeDasharray="3 3" stroke="var(--ui-placeholder)" />
                    <XAxis dataKey={vendorStat.getLabel} />
                    <YAxis type='number' label={{ value: getTranslation({group: "vendor-stats", id: "aov"}), angle: -90, position: 'center', dx: -30 }} />
                    <Tooltip cursor={{fill: 'var(--ui-bg)', opacity: 0.5}} contentStyle={{background: "var(--ui-element)", border: 0, borderRadius: 5, padding: 20}} />
                    <Line strokeWidth={2} type="monotone" dataKey={(_vendorStat) => _vendorStat.aov || 0} name={getTranslation({group: "vendor-stats", id: "aov"})} unit={" MXN"} stroke="var(--main-color)" />
                  </ComposedChart>
                </ResponsiveContainer>                 
              : selected === "feedbacks" ?
                <ResponsiveContainer width="100%" height={300} style={{background: "var(--ui-element)", borderRadius: 5}}>
                  <BarChart data={vendorStats}>
                    <CartesianGrid strokeDasharray="3 3" stroke="var(--ui-placeholder)" />
                    <XAxis dataKey={vendorStat.getLabel} />
                    <YAxis type='number' label={{ value: getTranslation({group: "vendor-stats", id: "feedbacks"}), angle: -90, position: 'center', dx: -20 }} />
                    <Tooltip cursor={{fill: 'var(--ui-bg)', opacity: 0.5}} contentStyle={{background: "var(--ui-element)", border: 0, borderRadius: 5, padding: 20}} />
                    <Bar name={getTranslation({group: "vendor-stats", id: "negative-feedbacks"})} dataKey={"negativeFeedbacks"} stackId="a" fill="var(--invalid-color)">
                      <LabelList valueAccessor={valueAccessor("negativeFeedbacks")} style={{fill: "white", fontWeight: "bold"}} />
                    </Bar>
                    <Bar name={getTranslation({group: "vendor-stats", id: "neutral-feedbacks"})} dataKey={"neutralFeedbacks"} stackId="a" fill="var(--warning-color)" >
                      <LabelList valueAccessor={valueAccessor("neutralFeedbacks")} style={{fill: "white", fontWeight: "bold"}} />
                    </Bar>
                    <Bar name={getTranslation({group: "vendor-stats", id: "positive-feedbacks"})} dataKey="positiveFeedbacks" stackId="a" fill="var(--valid-color)" radius={[10, 10, 0, 0]}>
                      <LabelList valueAccessor={valueAccessor("positiveFeedbacks")} style={{fill: "white", fontWeight: "bold"}} />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>                   
              : null }
            </div>
          }
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: 15}}>
          {vendorStats?.map((_vendorStat, key) => 
            <Link key={key} to={`/stats/${_vendorStat.id}`}>
              <VendorStat data={_vendorStat} />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}