import React from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { extractSettings } from '../../helpers'
import { SETTINGS_QUERY } from '../../graphql'
import { IntlText, Link } from '../../components'

import './style.css'

export function Footer() {

  const settingsQuery = useQuery(SETTINGS_QUERY, {variables: {keys: ["supportEmail", "supportContact", "home"]}})
  const { supportContact, supportEmail, home } = extractSettings(settingsQuery.data?.settings)

  return(
    <div id='footer'>
        {false &&
          <div className="icons">
            {home &&
              <div className="icon">
                <FontAwesome name="globe" /><a href={home.value} target="_blank" rel="noreferrer"><IntlText group="offer" id="footer-web" /></a>
              </div>
            }
            {supportEmail && 
              <div className="icon">
                <FontAwesome name="envelope" /><a href={`mailto:${supportEmail.value}`} target="_blank" rel="noreferrer" ><IntlText group="offer" id="footer-email" /></a>
              </div>
            }
            {supportContact && 
              <div className="icon">
                <span className="fab fa-whatsapp" /><a href={`https://api.whatsapp.com/send?phone=${supportContact.value}`} target="_blank" rel="noreferrer"><IntlText group="offer" id="footer-phone" /></a>
              </div>
            }
          </div>      
        }
        <div className='links'>
            <Link to="/aviso-de-privacidad-afiliado-comercial"><IntlText group='footer' id='privacy-policy' /></Link>
            <Link to="/terminos-y-condiciones-afiliado-comercial"><IntlText group='footer' id='terms-of-service' /></Link>
        </div>
    </div>
  )
}
