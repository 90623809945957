import React from 'react'
import { useMutation } from '@apollo/client'

import { IntlText, LabeledCheckbox, Form } from '../../../components'
import { EDIT_PRODUCT_MUTATION } from '../../../graphql'

import './style.css'

export function ProductStatus({product: {id, disabled, hidden}}) {

  const [editProduct, { loading }] = useMutation(EDIT_PRODUCT_MUTATION)

  return(
    <Form.Form id="product-status">
      <Form.Field>
        <LabeledCheckbox loading={loading} id={`disabled-${id}`} checked={!disabled} onChange={(e) => editProduct({variables: {id: id, data: {disabled: !e.target.checked}}})} rounded>
          <span><IntlText group="product-status" id="disabled" /></span>
          <span className="help"><IntlText group="product-status" id="disabled-info" /></span>
        </LabeledCheckbox>
      </Form.Field>
      <Form.Field>
        <LabeledCheckbox loading={loading} id={`hidden-${id}`} checked={!hidden} onChange={(e) => editProduct({variables: {id: id, data: {hidden: !e.target.checked}}})} rounded>
          <span><IntlText group="product-status" id="hidden" /></span>
          <span className="help"><IntlText group="product-status" id="hidden-info" /></span>
        </LabeledCheckbox>
      </Form.Field>
    </Form.Form>
  )
}
