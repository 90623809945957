import React from 'react'
import { useQuery } from '@apollo/client'

import { PromotionsList  } from '../../views'
import { PROMOTIONS } from '../../graphql'

export function Promotions(){

  const { data, loading, refetch } = useQuery(PROMOTIONS, {fetchPolicy: "network-only"})

  return(
    <div id="promotions" className="container">
      <div className="row padding">
        <PromotionsList data={data} refetch={refetch} loading={loading} />
      </div>
    </div>
  )
}
