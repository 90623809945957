import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { Button, LabeledInput, IntlText, Form, Validation } from '../../components'
import { IntlContext } from '../../contexts'
import { AuthKit } from '../../helpers'
import { useValidatedForm } from '../../hooks'
import { LOGIN_MUTATION, IS_LOGGED_IN } from '../../graphql'

const validations = {
  "uid": [{id: "required", validation: (val) => !!val}],
  "password": [{id: "required", validation: (val) => !!val}],
}

export function LoginForm() {

  const { getTranslation } = useContext(IntlContext)
  const { form: {uid, password}, updateField, validate, errors } = useValidatedForm({}, validations)
  const [login, { loading }] = useMutation(LOGIN_MUTATION, { update: updateStore})

  async function submit(){
    try {
      const { valid } = validate()
      if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

      await login({variables: {uid, password}})
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  function updateStore(cache, { data: { login: { token, user } } }){
    cache.writeQuery({
      query: IS_LOGGED_IN,
      data: { isLoggedIn: !!user.id},
    })

    AuthKit.login(user, token)
  }

  return(
    <div id="login-form">
      <Form.Form>
        <Form.Field>
          <Validation errors={errors.uid}>
            <LabeledInput name="uid" placeholder={getTranslation({group: "login-form", id: "input-username"})} value={uid} onChange={(e) => updateField({key: e.target.name, value: e.target.value.toLowerCase().replace(/\s/g, '')})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.password}>
            <LabeledInput name="password" placeholder={getTranslation({group: "login-form", id: "input-password"})} type="password" value={password} onChange={(e) => updateField({key: e.target.name, value:e.target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Button className="full" theme="main" loading={loading} onClick={submit}><IntlText group="login-form" id="login" /></Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
