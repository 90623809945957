import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { PURCHASE_FEEDBACKS_QUERY } from '../../graphql'
import { useIntl, useQueryBuilder } from '../../hooks'
import { Filters, Loader, Pagination, DatePicker } from '../../components'
import { FeedbacksList } from '../../views'

export function Feedbacks(){

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { getTranslation } = useIntl()
  const date = search.get("date") ? [search.getAll("date")[0], search.getAll("date")[1]] : [moment().subtract(30, "days").startOf("day").format("YYYY-MM-DD"), moment().add(1, "day").endOf("day").format("YYYY-MM-DD")]
  const { params, removeParam, isQuery, buildQuery, runQuery, page, setPage } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${date[0]} - ${date[1]}`, value: [date[0], date[1]]},
    }
  })
  const { data, loading } = useQuery(PURCHASE_FEEDBACKS_QUERY, {variables: buildQuery(), skip: !isQuery(), fetchPolicy: "network-only"})

  function setDate({selection: {startDate, endDate}}){
    const _start = moment(startDate)
    const _end = moment(endDate)

    // if (_end.diff(_start, 'month') > 3) return toast.error(<IntlText group="history-filter" id="max-date" />)

    const _date = [
      _start.format("YYYY-MM-DD"),
      _end.add(1, 'd').format("YYYY-MM-DD")
    ]
    runQuery({instantParams: {date: {key: "date", label: `${_date[0]} - ${_date[1]}`, value: [_date[0], _date[1]], skip: !(_date[0] && _date[1])}}})
  }

  return(
    <div id="products" className="container">
      {loading && <Loader theme="main" overlay/>}
      <div className="row padding">
        <Filters.Bar vertical>
          <div key="date" style={{marginLeft: "auto"}}>
            <Filters.Dropdown placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right reset={() => removeParam("date")}>
              <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
            </Filters.Dropdown>
          </div>
        </Filters.Bar>
        <FeedbacksList data={data?.purchaseFeedbacksPaginated?.nodes || []} />
        <Pagination page={page} setPage={setPage} pageInfo={data?.purchaseFeedbacksPaginated?.pageInfo}/>
      </div>
    </div>
  )
}
