import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { EDIT_VENDOR_MUTATION, CONNECT_TO_STRIPE } from '../../../graphql'
import { IntlText, Button, Form, SwitchBox, Configurable, Link } from '../../../components'
import { IntlContext } from '../../../contexts'

import './style.css'

export function VendorPaymentSettings({vendor}) {

  const { getTranslation } = useContext(IntlContext)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const [connectToStripe, {loading}] = useMutation(CONNECT_TO_STRIPE)

  async function changeSwitch(data){
    console.log("ASD");
    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      return toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _connectToStripe(){
    try {
      const {data: {connectVendorToStripe: {url}}} = await connectToStripe()
      window.open(url)
      return toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      console.log("Error: ", e)
      toast.error(getTranslation({group: "payment-settings", id: "connect-error"}))
    }
  }

  return(
    <div id="payment-settings">
      <Form.Form>
        <Configurable id="CASH_PAYMENT_OPTIONABLE">
          <Form.Field>
            <SwitchBox id="cashPaymentsDisabled" label={<IntlText group="payment-settings" id="cashCardPaymentsAccepted-description" />} checked={!vendor.cashPaymentsDisabled} onChange={(e) => changeSwitch({[e.target.id]: !e.target.checked})}>
              <IntlText group='payment-settings' id='cashCardPaymentsAccepted' />
            </SwitchBox>
          </Form.Field>
        </Configurable>
        {vendor?.expressAccountId && vendor?.expressAccountDetailsSubmitted ?
          <>
            <Form.Field>
              <SwitchBox id="bankCardPaymentsAccepted" label={<IntlText group="payment-settings" id="bankCardPaymentsAccepted-description" />} checked={vendor.bankCardPaymentsAccepted} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
                <IntlText group='payment-settings' id='bankCardPaymentsAccepted' />
              </SwitchBox>          
            </Form.Field>
            <Form.Field>
              <Link to="https://dashboard.stripe.com" button external><IntlText group='payment-settings' id='go-to-stripe' /></Link>
            </Form.Field>
          </>
        :
          <Button onClick={_connectToStripe} className="full" loading={loading}><IntlText group="payment-settings" id="connect-stripe" /></Button>
        }
      </Form.Form>
    </div>
  )
}
