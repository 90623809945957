import { gql }from '@apollo/client'

import { VENDOR_FRAGMENT } from '../'

export const VENDOR_EDITED_SUBSCRIPTION = gql`
  subscription vendorEdited{
    vendorEdited{
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`
