import { gql }from '@apollo/client'

import { PURCHASES_FRAGMENT } from './'

const MY_PURCHASES_QUERY = gql`
  query myPurchases($date: [String!], $type: PURCHASE_TYPE, $status: [PURCHASE_STATUS!]){
    myPurchases(date: $date, type: $type, status: $status){
      ...PURCHASES_FRAGMENT
    }
  }
  ${PURCHASES_FRAGMENT}
`

export default MY_PURCHASES_QUERY
