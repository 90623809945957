import React from 'react'

import { IntlText, Heading } from '../../components'
import { PackageBox } from '../../views'

export function FoodMagazin() {

  return(
    <div className="container">
      <div className="row padding">
        <Heading left info={<IntlText group="food-magazin" id="intro" />}>
          <IntlText group="food-magazin" id="food-magazin" />
        </Heading>                 
        <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
          <PackageBox title={<IntlText group="food-magazin" id="tulum-restaurants" />} >
            <p><IntlText group="food-magazin" id="tulum-restaurants-info" /></p>              
          </PackageBox>
          <PackageBox title={<IntlText group="food-magazin" id="tulum-eats" />} >
            <p><IntlText group="food-magazin" id="tulum-eats-info" /></p>
          </PackageBox>
        </div>   
      </div>
    </div>
  )
}
