import React from 'react'

import { useAuth } from '../../hooks'
import { VendorBalance, VendorBookEntries } from '../../views'

export function Balance() {

  const { user } = useAuth()

  return(
    <div id="balance" className="container">
      <div className="row padding">
        <VendorBalance vendor={user?.id} />
        <VendorBookEntries vendor={user?.id} />
      </div>
    </div>
  )
}
