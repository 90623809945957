import { gql } from '@apollo/client'

import { CHANGELOG_FRAGMENT } from './'

export const WHATS_NEW = gql`
  query whatsNewForMe($type: CHANGELOG_TYPE!){
    whatsNewForMe(type: $type){
      ...CHANGELOG_FRAGMENT
    }
  }
  ${CHANGELOG_FRAGMENT}
`
