import React from 'react'

import { IntlText, Badge, Timer } from '../../../components'

import './style.css'

export function PurchaseStatusBadge({purchase}) {

  function getColor(){
    switch (purchase.status) {
      case "PROCESSED":
        return "grey"
      case "INPREPARATION":
        return "warning"
      case "READYFORCOLLECTION":
        return "orange"
      case "ONTHEWAY":
        return "blue"
      case "ATLOCATION":
        return "lightgreen"
      case "COMPLETED":
        return "darkgreen"
      case "CANCELLED":
      case "UNPROCESSED":
      case "DELIVERYFAILED":
        return "invalid"
      default:
        return "grey"
    }
  }

  return(
    <div className="purchase-status-badge">
      <Badge theme={getColor()}>
        <span><IntlText group="purchase" id={purchase.status} variables={{time: null}} /></span>      
        {purchase.status === "INPREPARATION" && 
          <Timer time={purchase?.preparationTime * 60} start={purchase.timestamps?.vendorConfirmed} notSet={<IntlText group="orders-list-item" id="not-set" />}><IntlText group="orders-list-item" id="time-expired" /></Timer>
        }
      </Badge>
    </div>
  )
}
