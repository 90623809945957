import React from 'react'

import { IntlText } from '../../../components'

import './style.css'

export function ColorsList() {

    return(
        <div id="colors-list">
            <div className='color main'>
                <span className='color-box' />
                <div className='info'>
                    <span><IntlText group="colors-list" id="main-color" /></span>
                    <span><IntlText group="colors-list" id="main-color-desc" /></span>
                </div>
            </div>
            <div className='color secondary'>
                <span className='color-box' />
                <div className='info'>
                    <span><IntlText group="colors-list" id="secondary-color" /></span>
                    <span><IntlText group="colors-list" id="secondary-color-desc" /></span>
                </div>
            </div>
        </div>
    )
}
