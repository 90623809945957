import React from 'react'
import { useQuery } from '@apollo/client'

import { Loader, IntlValue, ToggleList, RadioDot } from '../../../components'
import { ADDONS_QUERY } from '../../../graphql'

import './style.css'

export function AddProductAddon({addon, onChange}) {

  const {loading, error, data} = useQuery(ADDONS_QUERY)

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { productAddons } = data

  return(
    <div id="add-product-addon">
      <ToggleList items={productAddons} selected={addon}>
        {(item) =>
          <div className="item" onClick={() => onChange(item)}>
            <RadioDot checked={item.id === addon?.id} readOnly/>
            <div className="name">
              <span><IntlValue>{item.name}</IntlValue></span>
              <span className="instruction"><IntlValue>{item.instruction}</IntlValue></span>
            </div>
            <div className="details">
              {item.minSelect ? <div className="detail"><span>min</span><span>{item.minSelect}</span></div> : null}
              {item.maxSelect ? <div className="detail"><span>max</span><span>{item.maxSelect}</span></div> : null}
            </div>
          </div>
        }
      </ToggleList>
    </div>
  )
}
