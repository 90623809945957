import React, { useState, useEffect } from 'react'
import FontAwesome from 'react-fontawesome'

import { Button, IntlText } from '../'

import './ToggleList.css'

export default function ToggleList({selected, items, children}){

  const [edit, toggleEdit] = useState(false)

  useEffect(() => {
    toggleEdit(false)
  }, [selected])

  return(
    <div className="toggle-list">
      {selected && !edit ?
        <div className="selected">
          {children && children(selected)}
          <Button onClick={() => toggleEdit(true)}><FontAwesome name="edit" /><IntlText id="choose" /></Button>
        </div>
      :
        <div className="list">
          {items.map((item, key) =>
            <div key={key} className="list-item">
              {children && children(item)}
            </div>
          )}
        </div>
      }
    </div>
  )

}
