import { useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { EDIT_PRODUCT_MUTATION, REORDER_PRODUCTS_MUTATION, DELETE_PRODUCT_MUTATION } from '../../../graphql'
import { IntlContext, ModalContext } from '../../../contexts'
import { useConfirm } from '../../../hooks'
import { formatPrice } from '../../../helpers'
import { LabeledCheckbox, Loader, IntlText, IntlValue, Filter, Table, ContextMenu, StatusDot, Sortable, Restricted } from '../../../components'
import { ProductTags, ProductVendorPrice } from '../../../views'

const defaultFields = {
  vendorPrice: false,
  tags: true,
  disabled: true,
  hidden: true,
}

export function ProductsList({data, refetch, filter, visibleFields = {}}) {

  const { confirm } = useConfirm()
  const [_visibleFields, setVisibleFields] = useState({...defaultFields, ...visibleFields})
  const { getTranslation, getTranslatedValue } = useContext(IntlContext)
  const { openModal } = useContext(ModalContext)
  const [editProduct, { loading: editLoading }] = useMutation(EDIT_PRODUCT_MUTATION)
  const [deleteProduct, { loading: deleteLoading }] = useMutation(DELETE_PRODUCT_MUTATION)
  const [reorder, { loading: reorderLoading }] = useMutation(REORDER_PRODUCTS_MUTATION)

  async function _deleteProduct(id){
    try {
      await confirm()
      await deleteProduct({variables: {id}})
      refetch()
      toast.success(getTranslation({id: "delete-success", defaultValue: "Successfully deleted!"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function onSort(orderedItems){
    try {
      await reorder({variables: {data: orderedItems}})
      refetch()
      toast.success(getTranslation({id: "order-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  const renderHeader = () => (
    <>
      <Table.Th></Table.Th>
      <Table.Th></Table.Th>
      <Table.Th className="text-left"><IntlText group="table-header" id="name" /></Table.Th>
      <Table.Th><IntlText group="table-header" id="price" /></Table.Th>
      {_visibleFields.vendorPrice && 
        <Table.Th><IntlText group="table-header" id="vendorPrice" /></Table.Th>
      }
      {_visibleFields.tags && 
        <Table.Th><IntlText group="table-header" id="tags" /></Table.Th>
      }
      {_visibleFields.disabled && 
        <Table.Th><IntlText group="table-header" id="disabled" /></Table.Th>
      }
      {_visibleFields.hidden && 
        <Table.Th><IntlText group="table-header" id="hidden" /></Table.Th>
      }
      <Table.Th><Table.VisibleFieldsFilter visibleFields={_visibleFields} setVisibleFields={setVisibleFields} /></Table.Th>  
    </>
  )

  const renderItem = (product) => (
    <>
      <Table.Td className="status"><StatusDot active={!(product.disabled || product.hidden)} /></Table.Td>
      <Table.Td className="text-left"><IntlValue value={product.name} /></Table.Td>
      <Table.Td>{formatPrice(product.price)}</Table.Td>
      {_visibleFields.vendorPrice && 
        <Table.Td><ProductVendorPrice product={product} /></Table.Td>
      }
      {_visibleFields.tags && 
        <Table.Td centered><ProductTags product={product}/></Table.Td>
      }
      {_visibleFields.disabled && 
        <Table.Td centered><LabeledCheckbox id={`disabled-${product.id}`} checked={!product.disabled} onChange={(e) => editProduct({variables: {id: product.id, data: {disabled: !e.target.checked}}})} rounded/></Table.Td>
      }
      {_visibleFields.hidden && 
        <Table.Td centered><LabeledCheckbox id={`hidden-${product.id}`} checked={!product.hidden} onChange={(e) => editProduct({variables: {id: product.id, data: {hidden: !e.target.checked}}})} rounded/></Table.Td>
      }
      <Table.Td>
        <Restricted>
          <ContextMenu.Menu id={product?.id} >
            <ContextMenu.Link to={`/products/${product.id}`} icon="external-link-alt"><IntlText id="edit" /></ContextMenu.Link>
            <ContextMenu.Button icon="edit" onClick={() => openModal("product", {product: product.id})}><IntlText id="quick-edit" /></ContextMenu.Button>
            <ContextMenu.Button icon="trash" className='invalid' onClick={() => _deleteProduct(product.id)}><IntlText id="delete" /></ContextMenu.Button>
          </ContextMenu.Menu>
        </Restricted>
      </Table.Td>
    </>
  )

  return(
    <div id="products-list" className="relative">
      {(!!deleteLoading || !!reorderLoading || !!editLoading) && <div className="full-size-loader"><Loader theme="main"/></div>}
      <Filter id="products" search={[{key: "search", value: filter}]} data={data?.products || []} filters={[{filter: (data) => getTranslatedValue(data.name)}]}>
        {(data) =>
          <Sortable.Table data={data} onSort={onSort} renderItem={renderItem} renderHeader={renderHeader} />
        }
      </Filter>
    </div>
  )
}

/*

*/
