import React, { useContext } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

import { DatePicker, Filters, IntlText } from '../../components'
import { IntlContext } from '../../contexts'

const statuses = [
  {id: "CANCELLED"},
  {id: "UNPROCESSED"},
  {id: "COMPLETED"},
]

const sorts = [
  {id: "CREATEDAT_ASCENDING", label: "ASC"},
  {id: "CREATEDAT_DESCENDING", label: "DESC"},
]

export function HistoryFilter({params, addParam, removeParam, sort, updateQuery, setSort, runQuery}) {

  const { getTranslation } = useContext(IntlContext)

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  function setDate({selection: {startDate, endDate}}){
    const _start = moment(startDate)
    const _end = moment(endDate)

    if (_end.diff(_start, 'month') > 3) return toast.error(<IntlText group="history-filter" id="max-date" />)

    const _date = [
      _start.format("YYYY-MM-DD"),
      _end.add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", label: `${_date[0]} - ${_date[1]}`, value: [_date[0], _date[1]], skip: !(_date[0] && _date[1])}})
  }

  return(
    <div id="history-filter">
      <Filters.Bar >
        <Filters.Input id="query" name="query" value={params?.query?.value} onChange={({target}) => addParam({query: {value: target?.value, id: "search", label: <IntlText group="history-filter" id="searched-for" variables={{str: target.value}}/>, skip: !target?.value}})} reset={() => removeParam("query")} placeholder={getTranslation({group: "history-filter", id: "start-searching"})} onKeyDown={onSearch}/>
        <Filters.Select style={{marginLeft: "auto"}} value={sorts.find(s => s.id === sort)?.label} placeholder={getTranslation({group: "history-filter", id: "sort"})} reset={() => setSort(undefined)}>
          {sorts.map((_status, key) =>
            <li key={key} onClick={() => setSort(_status?.id)}><span>{getTranslation({group: "sort", id: _status.id})}</span></li>
          )}
        </Filters.Select>
        <Filters.Select value={params?.status?.value ? getTranslation({group: "history-filter", id: params?.status?.value}) : undefined} placeholder={getTranslation({group: "history-filter", id: "status"})} reset={() => removeParam("status")}>
          {statuses.map((_status, key) =>
            <li key={key} onClick={() => updateQuery({status: {key: "status", value: _status?.id, skip: !_status?.id}})}><span><IntlText group="history-filter" id={_status.id} /></span></li>
          )}
        </Filters.Select>
        <Filters.Select value={params?.payment?.label} placeholder={getTranslation({group: "history-filter", id: "payment"})} reset={() => removeParam("payment")}>
          <li onClick={() => updateQuery({payment: {key: "payment", label: "Cash", value: "CASH"}})}><span>{getTranslation({group: "payment", id: "CASH"})}</span></li>
          <li onClick={() => updateQuery({payment: {key: "payment", label: "Stripe", value: "CARD_STRIPE"}})}><span>{getTranslation({group: "payment", id: "STRIPE"})}</span></li>
        </Filters.Select>
        <Filters.Dropdown placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right reset={() => removeParam("date")} run={runQuery}>
          <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
        </Filters.Dropdown>
      </Filters.Bar>
    </div>
  )

}
