import React from 'react'

import './style.css'

function Grids({className = "", children, wrapped, ...rest}) {

  return(
    <div className={`grids ${className} ${wrapped ? "wrapped" : ""}`} {...rest}>
      {children}
    </div>
  )
}

function Grid({size = 1, className = "", children, ...rest}) {

  return(
    <div className={`grid ${className}`} {...rest} style={{flex: size}}>
      {children}
    </div>
  )
}

const grids = { Grids, Grid }

export default grids
