import React, { useState, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import moment from 'moment'

import { SET_PREPARATION_TIME_MUTATION } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { pad, toast } from '../../../helpers'
import { SetTime, Loader, IntlText } from '../../../components'

import './style.css'

export function PurchaseSetPreparationTime({purchase: { id, preparationTime, status, timestamps: {vendorConfirmed}}}) {
  
  const { getTranslation } = useIntl()
  const [setPreparationTime, { loading }] = useMutation(SET_PREPARATION_TIME_MUTATION, {onCompleted})
  const [edit, toggleEdit] = useState(false)
  const [time, setTime] = useState({})
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    let interval = undefined

    const updateTimer = () => {
      if (!preparationTime || !isMounted?.current) return false

      let now = moment(vendorConfirmed).add(preparationTime, "m")
      let future = moment()
      let diff = future - now

      if (diff >= 0){
        setTime({mins: 0, secs: 0})
        return clearInterval(interval)
      }

      let mins  = Math.floor( diff / (1000*60) )
      let secs  = Math.floor( diff / 1000 ) - mins  * 60

      mins = secs === 0 ? -mins : -mins - 1
      secs = secs === 0 ? secs : 60 - secs

      setTime({mins, secs})
    }

    updateTimer()
    interval = setInterval(() => updateTimer(), 1000 )

    return () => clearInterval(interval)
  }, [preparationTime, vendorConfirmed])

  async function _setPreparationTime(_time){
    const alert = toast.loading()
    try {
      await setPreparationTime({variables: {id: id, time: parseInt(_time)}})
      if (isMounted.current) toggleEdit(false)
      alert.success(getTranslation({group: "purchase-set-preparation-time", id: "success"}))
  } catch (error) {
      alert.apolloError(error)
    }
  }

  function onCompleted(){
    if (isMounted.current) toggleEdit(false)
  }

  let { mins, secs } = time

  if ((preparationTime && mins === undefined) || loading) return (
    <div className="purchase-set-preparation-time">
      <Loader theme="main"/>
    </div>
  )

  return(
    <div className="purchase-set-preparation-time">
      {!edit && preparationTime ?
        <div className="current-time">
          {(mins === 0 && secs === 0) || status === "READYFORCOLLECTION" ?
            <div className="ready-for-collect">
              <span className="label">
                <span className="text">
                  {status === "READYFORCOLLECTION" ?
                    <IntlText group="purchase-set-preparation-time" id="cooked" />
                    :
                    <IntlText group="purchase-set-preparation-time" id="expired" />
                  }
                </span>
                <span className="preparation-time">{preparationTime} <IntlText id="minutes" /></span>
              </span>
            {/*<FontAwesome name="edit" onClick={() => toggleEdit(true)}/>*/}
            </div>
          :
            <div>
              <span className="mins">{pad(mins)}</span>
              <span className="separator">:</span>
              <span className="secs">{pad(secs)}</span>
              <span style={{color: "var(--ui-placeholder)"}}> <IntlText id="minutes" /></span>
              {/*<FontAwesome name="edit" onClick={() => toggleEdit(true)}/>*/}
            </div>
          }
        </div>
      :
        <SetTime min={15} max={60} defaultValue={15} accept={_setPreparationTime} cancel={() => toggleEdit(false)}/>
      }
    </div>
  )
}
