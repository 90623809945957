import React, { useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import './style.css'

export function Select({selected, options, placeholder, defaultValue, onChange, labelId = "label"}) {

  const [opened, toggle] = useState(false)
  const element = useRef(null)

  useEffect(() => {
    function handleClick(e){
      if (!element.current || element.current.contains(e.target)) return false
      toggle(false)
    }

    document.addEventListener("click", handleClick)
    document.addEventListener("touchend", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
  }, [])

  function _onChange(option){
    onChange(option)
    toggle(false)
  }

  let value = selected || { [labelId]: defaultValue || placeholder }

  return(
    <div className={`select ${placeholder ? "placeholder" : ""}`} ref={element}>
      {placeholder && <span className="placeholder">{placeholder}</span>}
      <span className="selected" onClick={() => toggle(!opened)}>{value[labelId]}</span>
      {opened &&
        <ul>
          {options.map((option, key) =>
            <SelectItem key={key} onChange={_onChange} option={option} labelId={labelId} />
          )}
        </ul>
      }
      <FontAwesome name={opened ? "chevron-up" : "chevron-down"} />
    </div>
  )
}

function SelectItem({option, onChange, labelId}){

  function _onChange(){
    onChange(option)
  }

  return(
    <li onClick={_onChange}>{option[labelId]}</li>
  )
}