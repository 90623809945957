import React from 'react'

import './style.css'

export function CustomerField({purchase}) {
  return(
    <div className="customer-field">
      <span className="link">{purchase.customer.name}</span>
    </div>
  )
}
