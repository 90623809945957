import React from 'react'

import './style.css'

export function Actions({fluid, children, ...rest}){

  return(
    <div className={`actions ${fluid ? "fluid" : ""}`} {...rest}>
      {children}
    </div>
  )

}
