import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import { EDIT_VENDOR_MUTATION, WHATS_NEW } from '../../../graphql'
import { useAuth, useIntl, useModal } from '../../../hooks'
import { toast } from '../../../helpers'
import { Modalv3, Title, IntlText, Button } from '../../../components'

import './style.css'

export function ChangelogModal(){

    const modal = useModal()
    const navigate = useNavigate()
    const { getTranslatedValue, getTranslation } = useIntl()
    const { user } = useAuth()
    const { data } = useQuery(WHATS_NEW, {variables: {type: "VENDOR_WEB"}})
    const [edit] = useMutation(EDIT_VENDOR_MUTATION)
    const whatsNewForMe = data?.whatsNewForMe
    
    useEffect(() => {
        if (whatsNewForMe?.length > 0){
            modal.show()
        }
    // eslint-disable-next-line
    }, [whatsNewForMe])

    function viewHistory(){
        modal.hide()
        navigate(`/help/changelogs`)
    }
    
    function onClick(item){
        modal.hide()
        navigate(`/help/changelogs/${item.id}`)
    }

    async function ok(){
        const alert = toast.loading()
        try {
            await edit({variables: {data: {lastChangelogReview: new Date()}}})
            alert.hide()
        } catch (error) {
            alert.apolloError(error)
        }        
        modal.hide()
    }
    if (user?.role !== "VENDOR") return null

    return(
        <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="changelogs" id="title" /></Title>}>
            <div className='changelogs'>
                {whatsNewForMe?.map((item, key) => 
                    <div key={key} className='changelog' onClick={() => onClick(item)}>
                        <Title tag='h4'>{getTranslatedValue(item.title)}</Title>
                        <div className='desc'>{getTranslatedValue(item.description)}</div>
                        <div className='read-more'>
                            {getTranslation({id: "read-more"})}
                        </div>
                    </div>
                )}
            </div>
            <div className="actions" style={{marginTop: 20}}>
                <Button onClick={viewHistory}><IntlText group="changelogs" id="view-changelogs" /></Button>
                <Button theme="main" onClick={ok}><IntlText group="changelogs" id="ok" /></Button>
            </div>
        </Modalv3>    
    )
}