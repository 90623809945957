import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useAuth, useModal } from '../../hooks'
import { SERVICE_FEE_PROFORMA_INVOICES } from '../../graphql'
import { Modalv3, Title, IntlText, Button } from '../../components'

export function InvoiceAlertModal(){

    const { user } = useAuth()
    const modal = useModal()
    const navigate = useNavigate()
    const hide = localStorage.getItem("hide-overdue-invoice-modal")
    const { data } = useQuery(SERVICE_FEE_PROFORMA_INVOICES, {variables: {limit: 5, date: [moment().subtract(60, "day").format("YYYY-MM-DD"), moment().startOf("day").format("YYYY-MM-DD")]}})
    const overdue = useMemo(
        () => data?.serviceFeeProformaInvoices?.nodes?.filter(i => i.status === "WAITING_FOR_PAYMENT" && i.overdue),
        [data]
    )

    useEffect(() => {
        if (user?.role === "VENDOR"){
            if (overdue?.length > 0 && (moment(hide, 'X').isBefore(moment(), "day") || !hide)) modal.show()
        }
    // eslint-disable-next-line
    }, [overdue])

    function dontShow(){
        localStorage.setItem("hide-overdue-invoice-modal", moment().unix())
        modal.hide()
    }

    function checkInvoice(){
        navigate(`/invoices/${proforma.id}`)
        modal.hide()
    }

    const proforma = overdue?.[0]

    return(
        <Modalv3 modal={modal} header={<Title tag="h3"><IntlText id="attention" /></Title>}>
            <div className="text-content">
                <p><IntlText group="invoice-alert" id="notice" variables={{reference: proforma?.reference, dueAt: moment(proforma?.dueAt).format("YYYY-MM-DD")}} /></p>
            </div>
            <div className="actions">
                <Button onClick={dontShow}><IntlText id="dont-show-again" /></Button>
                <Button onClick={checkInvoice}><IntlText group="invoice-alert" id="go-to-invoice" /></Button>
            </div>
        </Modalv3>    
    )
}