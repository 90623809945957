import React from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'

import { Title, IntlText, IntlValue } from '../'

import './MultiTag.css'

export default function MultiTag({values, options, onChange, max}) {

  const data = {
    options: values ? options.filter((option, key) => !values.includes(option.id)) : options,
    values: values ? options.filter((option, key) => values.includes(option.id)) : []
  }

  function add(item, source, destination){
    if (data?.values?.length >= max) return toast.error("Maximum reached")

    let _data = {
      [source.key]: source.data.filter((i) => i.id !== item.id),
      [destination.key]: destination.data.concat(item)
    }

    onChange(_data)
  }

  function remove(item, source, destination){
    let _data = {
      [source.key]: source.data.filter((i) => i.id !== item.id),
      [destination.key]: destination.data.concat(item)
    }

    onChange(_data)
  }

  return (
    <div className="multi-tag">
        <div className="options">
          <Title tag="h3"><IntlText group="multi-tag" id="available-options" /></Title>
          <div className="items-list">
            {data.options.length > 0 ? data.options.map((item, key) =>
              <div key={key} className="item" onClick={() => add(item, {key: "options", data: data.options}, {key: "values", data: data.values})}>
                {item.icon && <span className="icon">{item.icon}</span>}
                <span><IntlValue>{item.value}</IntlValue></span>
                <FontAwesome name="plus-circle" />
              </div>
            )
            :
              <div className="items-list">
                <span className="empty"><IntlText group="multi-tag" id="empty" /></span>
              </div>
            }
          </div>
        </div>
        <div className="values">
          <Title tag="h3"><IntlText group="multi-tag" id="selected-options" /></Title>
          {data.values.length > 0 ? data.values.map((item, key) =>
            <div key={key} className="item" onClick={() => remove(item, {key: "values", data: data.values}, {key: "options", data: data.options})}>
              {item.icon && <span className="icon">{item.icon}</span>}
              <span><IntlValue>{item.value}</IntlValue></span>
              <FontAwesome name="minus-circle" />
            </div>
          )
          :
            <div className="items-list">
              <span className="empty"><IntlText group="multi-tag" id="empty"> /</IntlText>></span>
            </div>
          }
        </div>
    </div>
  )

}
