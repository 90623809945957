import { useState } from 'react'
import FontAwesome from 'react-fontawesome'

import { useAuth } from '../../hooks'
import { IntlText, SidebarMenu, Logo, Loader, Configurable } from '../../components'

import './style.css'

export function LeftMenu() {
  const [opened, toggle] = useState(getDefault())
  const {loading, user, error} = useAuth()

  if (loading) return <Loader theme="main" />
  if (error) return null

  function getDefault(){
    const parsed = JSON.parse(localStorage.getItem('left-menu'))
    if (typeof parsed == "boolean") return parsed

    return window.innerWidth > 800
  }

  function _toggle(val){
    localStorage.setItem('left-menu', val)
    toggle(val)
  }

  return(
    <div id="left" className={`${opened ? "opened" : ""}`}>
      <div className="sticky">
        <div className="menu-header">
          <Logo small={!opened}/>
        </div>
        {user?.role === "VENDOR_USER" ? 
          <SidebarMenu.Menu>           
            {user?.canReadPurchases && 
              <>
                <SidebarMenu.Link icon="calendar-day" to="/"><IntlText group="left-menu" id="home" /></SidebarMenu.Link>
                <SidebarMenu.Link icon="history" to="/orders"><IntlText group="left-menu" id="order-history" /></SidebarMenu.Link>
              </>
            }
            {user?.canReadFeedback && 
              <SidebarMenu.Link icon="comment-smile" to="/feedbacks"><IntlText group="left-menu" id="order-feedbacks" /></SidebarMenu.Link>
            }
            {user?.canSetProductAvailability && 
              <>
                <SidebarMenu.Link icon="list-alt" to="/categories"><IntlText group="left-menu" id="all-categories" /></SidebarMenu.Link>
                <SidebarMenu.Link icon="utensils" to="/products"><IntlText group="left-menu" id="all-products" /></SidebarMenu.Link>
                <SidebarMenu.SubMenu icon="tag" label={<IntlText group="left-menu" id="addons" />} path="/addons">
                  <SidebarMenu.Link to="/addons"><IntlText group="left-menu" id="all-addons" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="/addons/options"><IntlText group="left-menu" id="all-addon-options" /></SidebarMenu.Link>
                </SidebarMenu.SubMenu>
              </>
            }
          </SidebarMenu.Menu>             
        : user ? 
          <SidebarMenu.Menu>
            <SidebarMenu.Link icon="calendar-day" to="/"><IntlText group="left-menu" id="home" /></SidebarMenu.Link>
            <SidebarMenu.Link icon="history" to="/orders"><IntlText group="left-menu" id="order-history" /></SidebarMenu.Link>
            {user?.serviceFeeChargeType === "PERIODICAL" &&
              <SidebarMenu.Link icon="money-check-edit-alt" to="/transactions"><IntlText group="left-menu" id="transactions" /></SidebarMenu.Link>
            }
            <SidebarMenu.Link icon="money-bill"  to="/balance"><IntlText group="left-menu" id="balance" /></SidebarMenu.Link>
            <SidebarMenu.Link icon="chart-bar" to="/stats"><IntlText group="left-menu" id="stats" /></SidebarMenu.Link>
            <SidebarMenu.Link icon="comment-smile" to="/feedbacks"><IntlText group="left-menu" id="order-feedbacks" /></SidebarMenu.Link>
            <SidebarMenu.SubMenu icon="list-alt" label={<IntlText group="left-menu" id="categories" />} path="/categories" >
              <SidebarMenu.Link to="/categories"><IntlText group="left-menu" id="all-categories" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/categories/new"><IntlText group="left-menu" id="new-category" /></SidebarMenu.Link>
            </SidebarMenu.SubMenu>
            <SidebarMenu.SubMenu icon="utensils" label={<IntlText group="left-menu" id="products" />} path="/products">
              <SidebarMenu.Link to="/products"><IntlText group="left-menu" id="all-products" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/products/new"><IntlText group="left-menu" id="new-product" /></SidebarMenu.Link>
            </SidebarMenu.SubMenu>
            <SidebarMenu.SubMenu icon="tag" label={<IntlText group="left-menu" id="addons" />} path="/addons">
              <SidebarMenu.Link to="/addons"><IntlText group="left-menu" id="all-addons" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/addons/options"><IntlText group="left-menu" id="all-addon-options" /></SidebarMenu.Link>
            </SidebarMenu.SubMenu>
            <SidebarMenu.SubMenu icon="star" label={<IntlText group="left-menu" id="promotions" />} path="/promotions">
              <SidebarMenu.Link to="/promotions" ><IntlText group="left-menu" id="all-promotions" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/promotions/new" ><IntlText group="left-menu" id="new-promotion" /></SidebarMenu.Link>
            </SidebarMenu.SubMenu>
            <SidebarMenu.SubMenu icon="megaphone" label={<IntlText group="marketing" id="marketing" />} path="/marketing">
              <SidebarMenu.Link to="/marketing/brand-identity"><IntlText group="marketing" id="brand-identity" /></SidebarMenu.Link>
              <Configurable id="PHOTOSHOOT_AVAILABLE">
                <SidebarMenu.Link to="/marketing/photoshoot" help={<IntlText group="photoshoot" id="photoshoot-info" />}><IntlText group="photoshoot" id="photoshoot" /></SidebarMenu.Link>
              </Configurable>
              <Configurable id="FOOD_MAGAZINES_AVAILABLE">
                <SidebarMenu.Link to="/marketing/food-magazin" help={<IntlText group="food-magazin" id="food-magazin-info" />}><IntlText group="food-magazin" id="food-magazin" /></SidebarMenu.Link>
              </Configurable>
            </SidebarMenu.SubMenu>
            {user?.serviceFeeChargeType === "PERIODICAL" &&
              <SidebarMenu.Link icon="file-invoice"  to="/invoices" end={false}><IntlText group="left-menu" id="invoices" /></SidebarMenu.Link>
            }
            <SidebarMenu.Link icon="cog"  to="/settings" end={false}><IntlText group="left-menu" id="settings" /></SidebarMenu.Link>
            <SidebarMenu.SubMenu icon="question-circle" label={<IntlText group="left-menu" id="faq" />} path="/help">
              <SidebarMenu.Link to="/help" help={<IntlText group="left-menu" id="help-center-info" />}><IntlText group="left-menu" id="help-center" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/help/terms" help={<IntlText group="left-menu" id="terms-info" />}><IntlText group="left-menu" id="terms" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/help/get-started" help={<IntlText group="left-menu" id="faq-onboarding-info" />}><IntlText group="left-menu" id="faq-onboarding" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/help/purchases" help={<IntlText group="left-menu" id="faq-purchases-info" />}><IntlText group="left-menu" id="faq-purchases" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/help/delivery-zone" help={<IntlText group="left-menu" id="delivery-zone-info" />}><IntlText group="left-menu" id="delivery-zone" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/help/changelogs" help={<IntlText group="left-menu" id="changelogs-info" />}><IntlText group="left-menu" id="changelogs" /></SidebarMenu.Link>
            </SidebarMenu.SubMenu>
            <SidebarMenu.SubMenu icon="box-usd" label={<IntlText group="left-menu" id="packages" />} path="/packages" help={<IntlText group="left-menu" id="packages-info" />}>
              <SidebarMenu.Link to="/packages/basic" help={<IntlText group="left-menu" id="packages-basic-info" />}><IntlText group="left-menu" id="packages-basic" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/packages/featured" help={<IntlText group="left-menu" id="packages-featured-info" />}><IntlText group="left-menu" id="packages-featured" /></SidebarMenu.Link>
              <Configurable id="EXCLUSIVE_PACKAGE_ACTIVE">
                <SidebarMenu.Link to="/become-exclusive" help={<IntlText group="left-menu" id="packages-exclusive-info" />}><IntlText group="left-menu" id="packages-exclusive" /></SidebarMenu.Link>
              </Configurable>
            </SidebarMenu.SubMenu>
          </SidebarMenu.Menu>        
        :
          <SidebarMenu.Menu>           
            <SidebarMenu.SubMenu icon="box-usd" label={<IntlText group="left-menu" id="packages" />} path="/packages" help={<IntlText group="left-menu" id="packages-info" />}>
              <SidebarMenu.Link to="/packages/basic" help={<IntlText group="left-menu" id="packages-basic-info" />}><IntlText group="left-menu" id="packages-basic" /></SidebarMenu.Link>
              <SidebarMenu.Link to="/packages/featured" help={<IntlText group="left-menu" id="packages-featured-info" />}><IntlText group="left-menu" id="packages-featured" /></SidebarMenu.Link>
            </SidebarMenu.SubMenu>
            <SidebarMenu.Link icon="megaphone" to="/marketing/brand-identity" help={<IntlText group="marketing" id="brand-identity-info" />}><IntlText group="marketing" id="brand-identity" /></SidebarMenu.Link>
          </SidebarMenu.Menu>           
        }
        <div className="menu-toggle" onClick={() => _toggle(!opened)}>
          <FontAwesome name="chevron-left" />
        </div>
      </div>
    </div>
  )
}
