import React from 'react'
import FontAwesome from 'react-fontawesome'

import { AuthKit } from '../../helpers'
import { IntlText, Button } from '../../components'

import './style.css'

export function AuthenticationError() {
  return(
    <div id="authentication-error" className="container">
      <div className="row wrapped">
        <div id="authentication-error-wrapper">
          <FontAwesome name="exclamation-triangle"/>
          <p className="error-text"><IntlText group="authentication-error" id="message" /></p>
          <Button onClick={AuthKit.logout}><IntlText group="authentication-error" id="login" /></Button>
        </div>
      </div>
    </div>
  )
}
