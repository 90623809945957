import { gql }from '@apollo/client'

const PURCHASE_CUSTOMER_FRAGMENT = gql`
  fragment PURCHASE_CUSTOMER_FRAGMENT on PurchaseCustomer {
    originalId
    name
    email
    phone{
      countryCode
      phoneNumber
      phoneCode
    }
  }
`

export default PURCHASE_CUSTOMER_FRAGMENT
