import { gql }from '@apollo/client'

import { FAQ_FRAGMENT } from './'

export const FAQS = gql`
  query faqItems($topic: FAQ_TOPIC){
     faqItems(topic: $topic){
       ...FAQ_FRAGMENT
     }
  }
  ${FAQ_FRAGMENT}
`
