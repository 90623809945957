import { gql }from '@apollo/client'

import { SETTING_FRAGMENT } from './'

export const SETTING = gql`
  query setting($key: String!){
    setting(key: $key){
      ...SETTING_FRAGMENT
    }
  }
  ${SETTING_FRAGMENT}
`
