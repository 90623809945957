import { useMutation } from '@apollo/client'

import { CREATE_VENDOR_USER } from '../../../graphql'
import { useAuth, useIntl, useValidatedForm } from '../../../hooks'
import { toast } from '../../../helpers'
import { LabeledInput, Form, SwitchBox, Button, Validation } from '../../../components'
import { useNavigate } from 'react-router'

export function NewVendorUser() {

  const { user } = useAuth()
  const validations = {
    "name": [{id: "required", validation: (val) => !!val}],
    "username": [{id: "required", validation: (val) => !!val}],
    "password": [{id: "required", validation: (val) => !!val}],
  }
  const navigate = useNavigate()
  const { getTranslation } = useIntl()
  const [createUser] = useMutation(CREATE_VENDOR_USER)
  const { form: { username, name, password, canReadPurchases, canEditPurchases, canReadFeedback, canSetProductAvailability }, updateField, errors, validate } = useValidatedForm({
    canReadPurchases: true,
    canEditPurchases: true,
    canReadFeedback: true,
    canSetProductAvailability: true,
  }, validations)

  async function _createUser(){
    const alert = toast.loading()
    try {
      const { valid } = validate()

      if (!valid) throw new Error(getTranslation({group: "form", id: "error"}))

      await createUser({variables: {data: {
        username,
        name,
        password,
        canReadPurchases,
        canEditPurchases,
        canReadFeedback,
        canSetProductAvailability
      }}})
      alert.success(getTranslation({id: "edit-success"}))
      navigate('../')
    } catch (error) {
      console.log("error", error);
      alert.apolloError(error)
    }
  }

  console.log("user", user);

  return(
    <div id="vendor-users">
      <Form.Form>
        <Form.Field>
          <Validation errors={errors["name"]}>
            <LabeledInput name="name" value={name} placeholder={getTranslation({group: "vendor-users", id: "name"})} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["username"]}>
            <LabeledInput name="username" prefix={`${user.username}-`} value={username} placeholder={getTranslation({group: "vendor-users", id: "username"})} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["password"]}>
            <LabeledInput name="password" value={password} placeholder={getTranslation({group: "vendor-users", id: "password"})} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <SwitchBox id="canReadPurchases" label={getTranslation({group: "vendor-users", id: "canReadPurchases"})} checked={canReadPurchases} onChange={() => updateField({key: "canReadPurchases", value: !canReadPurchases})} />          
        </Form.Field>
        <Form.Field>
          <SwitchBox id="canEditPurchases" label={getTranslation({group: "vendor-users", id: "canEditPurchases"})} checked={canEditPurchases} onChange={() => updateField({key: "canEditPurchases", value: !canEditPurchases})} />          
        </Form.Field>
        <Form.Field>
          <SwitchBox id="canReadFeedback" label={getTranslation({group: "vendor-users", id: "canReadFeedback"})} checked={canReadFeedback} onChange={() => updateField({key: "canReadFeedback", value: !canReadFeedback})} />          
        </Form.Field>
        <Form.Field>
          <SwitchBox id="canSetProductAvailability" label={getTranslation({group: "vendor-users", id: "canSetProductAvailability"})} checked={canSetProductAvailability} onChange={() => updateField({key: "canSetProductAvailability", value: !canSetProductAvailability})} />          
        </Form.Field>
        <Form.Field>
          <Button theme="main" onClick={_createUser} fluid>{getTranslation({id: "save"})}</Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
