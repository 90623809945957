import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { AddonOptionsList  } from '../../views'
import { Filters, Loader, PriorityBar } from '../../components'
import { ADDON_OPTIONS_QUERY } from '../../graphql'
import { useQueryBuilder } from '../../hooks'

export function AddonOptions(){

  const navigate = useNavigate()
  const location = useLocation()
  const search = new URLSearchParams(location?.search)
  const [filter, setFilter] = useState()
  const { getParams, removeParam, buildQuery, runQuery, clearParams } = useQueryBuilder({})
  const { data, loading, refetch } = useQuery(ADDON_OPTIONS_QUERY, {variables: buildQuery(), fetchPolicy: "network-only"})

  function setSearch(str){
    if (str === "") return clearSearch()
    search.set("search", str)
    navigate({search: search.toString()})
    setFilter(str)
  }

  function clearSearch(){
    search.delete("search")
    navigate({search: search.toString()})
    setFilter("")
  }

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  const navigationItems = [
    <div key="query" className="left">
      <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setSearch(target.value)} reset={() => clearSearch()} placeholder="Type here for searching" onKeyDown={onSearch}/>
    </div>
  ]

  return(
    <div id="addons" className="container">
      {loading && <Loader theme="main" overlay/>}
      <div className="row padding">
        <Filters.Bar vertical>
          <PriorityBar navigationItems={navigationItems} itemMinWidth={150} />
          <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams} />
        </Filters.Bar>
        <AddonOptionsList data={data} refetch={refetch} filter={filter}/>
      </div>
    </div>
  )
}
