import React, { useContext } from 'react'

import { useAuth } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { IntlText, LabeledInput, Loader } from '../../../components'

import './style.css'

export function Price({price, setPrice}){

    const { getTranslation } = useContext(IntlContext)
    const { user, loading, error} = useAuth()

    function addComission(value){
        try {
            const _price = value/(1-user?.serviceFee)
            setPrice(Math.round(_price))
        } catch (error) {
            console.log("Price error", error);
        }
    }
    
    function deductComission(value){
        try {
            if (!value) return undefined
            
            const _price = value * (1-user?.serviceFee)
            return Math.round(_price)
        } catch (error) {
            console.log("Price error", error);
        }
    }

    if (loading || error) return <Loader theme='main'/>

    return(
        <div className='product-price'>
            <div>
                <LabeledInput id="client-price" type="number" onChange={({target}) => setPrice(target.value)} placeholder={getTranslation({group: "price", id:"client-price"})} value={price} />
                <span className='help'><IntlText group="price" id="client-price-info"/></span>
            </div>
            <div>
                <LabeledInput id="vendor-price" type="number" onChange={({target}) => addComission(target.value)} placeholder={getTranslation({group: "price", id:"vendor-price"})} value={deductComission(price)} />
                <span className='help'><IntlText group="price" id="vendor-price-info"/></span>
            </div>
        </div>        
    )
}