import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useValidatedForm } from '../../../hooks'
import { getPromotionTypes } from '../../../helpers'
import { EDIT_PROMOTION_MUTATION } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { IntlText, Form, LabeledInput, Button, Validation, IntlValue } from '../../../components'
import { PromotionPreview  } from '../../../views'

export function PromotionForm({promotion}){

  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const [editPromotion, { loading }] = useMutation(EDIT_PROMOTION_MUTATION)
  const { form: {minBasket, discount}, updateField, errors } = useValidatedForm({
    minBasket: promotion.restrictions?.find(p => p.key === "MINBASKETVALUE")?.value,
    discount: promotion.configuration?.find(p => p.key === "DISCOUNT")?.value*100,
  }, {"minBasket": [{id: "isNumber", validation: (val) => Number.isInteger(Number(val))}],})
  const _types = getPromotionTypes({discount, minBasket})
  const selectedType = _types.find(_type => _type.id === promotion.type)

  async function _editPromotion(){
    let data = {}

    if (promotion.type === "FREEDELIVERYOVERBASKETVALUE"){
      data = {
        label: _types[0].label,
        description: _types[0].description,
        restrictions: [{key: "MINBASKETVALUE", value: minBasket || "0"}]
      }
    }

    if (promotion.type === "PERCENTAGEOVERBASKETVALUE"){
      data = {
        label: _types[1].label,
        description: _types[1].description,
        restrictions: [{key: "MINBASKETVALUE", value: minBasket || "0"}],
        configuration: [{key: "DISCOUNT", value: (discount / 100).toString()}]
      }
    }    

    try {
      await editPromotion({variables: {id: promotion.id, data}})
      toast.success(getTranslation({id: "save-success"}))
      navigate("/promotions")
    } catch (e) {
      console.log("e", e)
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <Form.Form>
      <Form.Field>
        <PromotionPreview type={_types.find(_type => _type.id === promotion.type)} />
      </Form.Field>
      <Form.Field>
        <LabeledInput type="number" name="minBasket" placeholder={getTranslation({group: "promotion-form", id: "min-basket"})} value={minBasket} onChange={(e) => updateField({key: "minBasket", value: e.target.value})} />
      </Form.Field>
      {promotion.type === "PERCENTAGEOVERBASKETVALUE" &&
        <Form.Field>
          <Validation errors={errors["discount"]}>
            <LabeledInput type='number' min="0" max="100" name="discount" placeholder={getTranslation({group: "promotion-form", id: "discount"})} value={discount} onChange={(e) => updateField({key: "discount", value: e.target.value})} />
          </Validation>
        </Form.Field>
      }            
      {selectedType?.vendorDescription &&
        <Form.Field>
          <div className='help'>
            <IntlValue value={selectedType?.vendorDescription} />
          </div>
        </Form.Field>
      }
      <Form.Field>
        <Button loading={loading} theme="main" className="full" onClick={_editPromotion}><IntlText id="save" /></Button>
      </Form.Field>
    </Form.Form>
  )
}
