import React, { useContext } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router'
import { useQuery } from '@apollo/client'

import { useQueryBuilder } from '../../../hooks'
import { formatPrice, getAsset } from '../../../helpers'
import { VENDOR_BOOK_ENTRIES } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { IntlValue, ContextMenu, StatusDot, Pagination, Loader, IntlText, Filters, PriorityBar, DatePicker } from '../../../components'

import './style.css'

export function VendorBookEntries() {

  const { getTranslation } = useContext(IntlContext)
  const location = useLocation()
  const search = new URLSearchParams(location.search)

  const {runQuery, getParams, params, removeParam, clearParams, buildQuery, isQuery, setPage, page, processQuery } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${search.getAll("date")[0]} - ${search.getAll("date")[1]}`, value: search.getAll("date")?.length === 2 ? [search.getAll("date")[0], search.getAll("date")[1]] : undefined, skip: !search.get("date")},
    },
    page: search.get("page"),
    sort: search.get("sort")
  })

  const { data, loading, error } = useQuery(VENDOR_BOOK_ENTRIES, {variables: buildQuery(), skip: !isQuery(), onCompleted: (_data) => processQuery(_data?.vendorBookEntries?.pageInfo)})

  if (error) return `Error! ${error.message}`

  function setDate({selection: {startDate, endDate}}){
    const _start = moment(startDate)
    const _end = moment(endDate)

    if (_end.diff(_start, 'month') > 3) return toast.error(<IntlText group="history-filter" id="max-date" />)

    const _date = [
      _start.format("YYYY-MM-DD"),
      _end.add(1, 'd').format("YYYY-MM-DD")
    ]
    runQuery({instantParams: {date: {key: "date", label: `${_date[0]} - ${_date[1]}`, value: [_date[0], _date[1]], skip: !(_date[0] && _date[1])}}})
  }

  const navigationItems = [
    <div style={{marginLeft: "auto"}} key="date">
      <Filters.Dropdown placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right reset={() => removeParam("date")}>
        <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
      </Filters.Dropdown>
    </div>
  ]

  return(
    <div id="vendor-book">
      <Filters.Bar vertical>
        <PriorityBar navigationItems={navigationItems} itemMinWidth={150} stickLast/>
        <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams}/>
      </Filters.Bar>
      {loading && <Loader theme="main" overlay/>}
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Type</th>
            <th>Note</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.vendorBookEntries?.nodes?.map((historyItem, key) =>
            <tr className="item" key={key}>
              <td><StatusDot active={!historyItem.voided} /></td>
              <td className="date">{moment(historyItem?.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</td>
              <td className="description-field">
                <div>
                  <span><IntlValue>{historyItem?.description}</IntlValue></span>
                </div>
              </td>
              <td className="note">{historyItem?.note}</td>
              <td className={`price-field ${historyItem.value > 0 ? "plus" : "minus"}`}>
                {historyItem?.type === "TOMATO_POINTS" ?
                  <div className="tomato-point"><span>{historyItem?.value}</span><img alt="tomato points" src={getAsset("icon.png")} className="icon" /></div>
                :
                  formatPrice(historyItem?.value)
                }
              </td>
              <td>
                <ContextMenu.Menu id={historyItem.id} >
                  {historyItem.relatedPurchase &&
                    <ContextMenu.Link icon="external-link-alt" to={`/order/${historyItem.relatedPurchase}`}><IntlText group="vendor-book" id="view" /></ContextMenu.Link>
                  }
                </ContextMenu.Menu>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination page={page} setPage={setPage} pageInfo={data?.vendorBookEntries?.pageInfo}/>
    </div>
  )
}
