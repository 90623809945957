import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { Button, IntlText, Validation, PhoneNumberInput, Form } from '../../../components'
import { useValidatedForm } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { EDIT_VENDOR_MUTATION } from '../../../graphql'

const validations = {
  "phone": [{id: "required", validation: (val) => !!val}]
}

export function ContactDetails({vendor, onSuccess}) {

  const { getTranslation } = useContext(IntlContext)
  const [editVendor, {loading}] = useMutation(EDIT_VENDOR_MUTATION)
  const { form: {phone}, updateField, validate, errors, edited, done } = useValidatedForm({phone: vendor?.phone}, validations)
  async function save(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))
    let data = {
      phone: {phoneCode: phone.phoneCode, countryCode: phone.countryCode, phoneNumber: phone.phoneNumber},
    }

    try {
      await editVendor({variables: {id: vendor.id, data}})
      toast.success(getTranslation({id: "save-success"}))
      if (onSuccess) onSuccess()
      done()
    } catch (e) {
      console.log("e", e);
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="basic-details">
      <Form.Form>
        <Form.Field>
          <Validation errors={errors.phone}>
            <PhoneNumberInput name="phone" placeholder={getTranslation({group: "contact-details", id: "input-phone"})} value={phone} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(value) => updateField({key: "phone", value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          {edited && <Button theme="main" type="button" className="full" loading={loading} onClick={() => save()}><IntlText id="save" /></Button>}
        </Form.Field>
      </Form.Form>
    </div>
  )
}
