import { gql }from '@apollo/client'

const PURCHASE_ADDRESS_FRAGMENT = gql`
  fragment PURCHASE_ADDRESS_FRAGMENT on PurchaseAddress {
    originalId
    name
    slug
    zone
    building
    unit
    type
    instructions
    location{
      coordinates
    }
  }
`

export default PURCHASE_ADDRESS_FRAGMENT
