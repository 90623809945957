import { getAsset } from '../helpers'
import { notification, error } from '../assets'

var audio = new Audio(notification)
var errorAudio = new Audio(error)

export function notify({title, message, url, isError = false, onError = error => console.log("Notification error:", error)}) {

  // If let's create a notification
  function _notify(){
    const notification = new Notification(title, {
      icon: getAsset("icon.png"),
      body: message,
    })

    if (isError){
      errorAudio.play().catch(onError)
    } else {
      audio.play().catch(onError)
    }

    if (url){
      notification.onclick = function() {
        window.open(url)
      }
    }    
  }

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification")
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    _notify()
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        _notify()
      }
    })
  }

}
