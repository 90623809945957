import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { UPLOAD_PRODUCT_MEDIA_MUTATION, DELETE_PRODUCT_MEDIA_MUTATION } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { useAuth } from '../../../hooks'
import { toast } from '../../../helpers'
import { IntlText , Loader, Form, ImageCropper } from '../../../components'

export function ProductMedia({product, product: { media, name }}) {

  const { getTranslation, getTranslatedValue } = useContext(IntlContext)
  const { loading, user, error } = useAuth()
  const [uploadProductImage, { loading: uploadLoading}] = useMutation(UPLOAD_PRODUCT_MEDIA_MUTATION)
  const [deleteProductImage, { loading: deleteLoading}] = useMutation(DELETE_PRODUCT_MEDIA_MUTATION)

  if (loading || error) return <Loader theme="main" />

  async function uploadMedia({file, type}){
    const alert = toast.loading()
    try {
      if (!user?.tags?.includes("canUploadPhotos")) throw new Error(getTranslation({id: "no-sufficient-permissions"}))

      await uploadProductImage({variables: {product: product.id, file, type}})
      alert.success(getTranslation({id: "upload-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function deleteMedia(type){
    try {
      await deleteProductImage({variables: {id: product.id, type}})
      return toast.success(getTranslation({id: "delete-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="product-media" className="relative">
      <Form.Form>
        <Form.Field>     
          <ImageCropper id="cover" aspect={1200/460} title={getTranslatedValue(name)} placeholder={<IntlText id="cover" />} image={media?.cover} deleteImage={() => deleteMedia("IMAGE_PRODUCT_COVER")} uploadImage={(file) => uploadMedia({file, type: "IMAGE_PRODUCT_COVER", width: 1200, height: 260})} uploading={uploadLoading} deleting={deleteLoading} />
        </Form.Field>
        <Form.Field>    
          <ImageCropper id="thumbnail" title={getTranslatedValue(name)} placeholder={<IntlText id="thumbnail" />}  image={media?.thumbnail} deleteImage={() => deleteMedia("IMAGE_PRODUCT_THUMBNAIL")} uploadImage={(file) => uploadMedia({file, type: "IMAGE_PRODUCT_THUMBNAIL", width: 600, height: 600})} uploading={uploadLoading} deleting={deleteLoading} />
        </Form.Field>
      </Form.Form>
    </div>
  )
}

