import React, { useContext } from 'react'
import parse from 'html-react-parser'

import { IntlContext } from '../../../contexts'
import { IntlValue } from '../../../components'

import './style.css'

export function Help({items}) {
  return(
    <div id="help">
        <div className="items">
            {items?.map((faq, key) =>
                <Item key={key} faq={faq} />
            )}
        </div>
    </div>

  )
}

function Item({faq}){
    const { getTranslatedValue } = useContext(IntlContext)
  
    return(
      <div className="item">
        <div className="question"><i className="fas fa-question-circle"></i><IntlValue>{faq.title}</IntlValue></div>
        <div className="answer">
          <span>{parse(getTranslatedValue(faq.content))}</span>
        </div>
      </div>
    )
  }
  