import { useIntl } from '../../../hooks'

import { Link, Title } from '../../../components'
import { NotFound } from '../../../views'

import './style.css'

export function ChangelogsHistory({changelogs}) {

  const { getTranslatedValue, getTranslation } = useIntl()

  return(
    <div className="container">
        <div className="row padding">
            <div className="relative">
                <div className='changelogs-history'>
                    {changelogs?.length > 0 ? changelogs?.map((item, key) => 
                        <div key={key} className='changelog'>
                            <Title tag='h3'>{getTranslatedValue(item.title)}</Title>
                            <div className='desc'>{getTranslatedValue(item.description)}</div>
                            <Link to={`./${item.id}`} button>{getTranslation({id: "read-more"})}</Link>
                        </div>
                    ) : 
                        <NotFound fill icon='bullhorn' text={getTranslation({group: "changelogs", id: "nothing-new"})} />
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
