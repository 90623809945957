import React, { useContext } from 'react'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { formatPrice } from '../../helpers'
import { IntlContext } from '../../contexts'
import { IntlText, Table, Link } from '../../components'
import { CustomerField, PurchaseStatusBadge } from '../../views'

export function Item({id, purchase, dateFormat, visibleFields, link = (_purchase) => `/orders/${_purchase?.id}`}) {

  const { getTranslation } = useContext(IntlContext)

  return(
    <Table.Tr className={`orders-list-item ${purchase.status.toLowerCase()}`}>
      <Table.Td className="id" label="#">{++id}</Table.Td>
      {visibleFields.customer &&
        <Table.Td><CustomerField purchase={purchase} /></Table.Td>
      }
      {visibleFields.total &&
        <Table.Td>{formatPrice(purchase.price.subtotal)}</Table.Td>
      }
      {visibleFields.serviceFeeVendor &&
        <Table.Td>-{formatPrice(purchase.price.serviceFeeVendor)}</Table.Td>
      }
      {visibleFields.vendorPrice &&
        <Table.Td>{formatPrice(purchase.price.vendorPrice)}</Table.Td>
      }
      {visibleFields.vendorPriceAdjustmentByCustomerCashBalance &&
        <Table.Td>{formatPrice(purchase.price.vendorPriceAdjustmentByCustomerCashBalance)}</Table.Td>
      }
      {visibleFields.vendorCashPayment &&
        <Table.Td>{formatPrice(purchase.price.vendorCashPayment)}</Table.Td>
      }
      {visibleFields.createdAt &&
        <Table.Td>{moment(purchase.createdAt).format(dateFormat)}</Table.Td>
      }
      {visibleFields.status &&
        <Table.Td><PurchaseStatusBadge purchase={purchase} /></Table.Td>
      }
      {visibleFields.tags &&
        <Table.Td label={getTranslation({group: "orders-list", id: "table-header-tags" })}>
          <div className="order-tags">
            {(purchase?.payment === "CARD_CONEKTA" || purchase?.payment === "CARD_STRIPE") &&
              <FontAwesome name="credit-card" />
            }
          </div>
        </Table.Td>
      }
      <Table.Td>
        <div className='actions'>
          <Link button to={link(purchase)} icon="external-link-alt"><IntlText id="view-purchase" /></Link>
        </div>
      </Table.Td>
    </Table.Tr>
  )

}
