import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { IntlContext } from '../../../contexts'
import { IntlText, Button, Heading, Link } from '../../../components'

import './style.css'

export function VendorWelcome(){

    const { getTranslation } = useContext(IntlContext)
    const navigate = useNavigate()

    return(
        <div id='vendor-welcome'>
            <div className='icon'>
                <img src='https://d2l0zqfhpatq4j.cloudfront.net/icons/become-a-partner.webp' alt={getTranslation({group: "vendor-welcome", id:"welcome"})} />
            </div>
            <Heading spacing info={<IntlText group="wizard" id="welcome-info" />}>
                <IntlText group="vendor-welcome" id="welcome" />
            </Heading>
            <div className='text-content'>
                <p><IntlText group="vendor-welcome" id="welcome-info" /></p>
                <p><IntlText group="vendor-welcome" id="welcome-info-2" /><Link styled to="../help"><IntlText group="vendor-welcome" id="go-to-help-center" /></Link></p>
            </div>
            <Button theme="main" className="jumbo" onClick={() => navigate("../terms")}><IntlText group="vendor-welcome" id="start" /></Button>
        </div>
    )
}