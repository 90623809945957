import FontAwesome from 'react-fontawesome'
import { NavLink } from 'react-router-dom'

import { Loader } from '../'

import './style.css'

export function Link({className, theme, loading, button, children, icon, external, ...rest}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  if (external) return (
      <a href={rest.to} target="_blank" rel="noreferrer" className={`link ${className || ""} ${button ? "button" : ""} ${icon ? "icon" : ""}`} {...rest}>
        {icon && showIcon()}
        {children}
      </a>
  )  

  return(
    <NavLink  className={(navData) => `link ${className || ""} ${button ? "button" : ""} ${icon ? "icon" : ""} ${navData.isActive ? "active" : ''}`} {...rest}>
      {icon && showIcon()}
      <>{loading && <Loader />}{children}</>
    </NavLink>
  )

}
