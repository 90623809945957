import { ApolloClient, InMemoryCache, from, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { createUploadLink } from 'apollo-upload-client'

import { IS_LOGGED_IN } from './graphql'
import config from './config'

let cache = new InMemoryCache({
  data: {
    isLoggedIn: !!localStorage.getItem(config.authToken)
  }
})

const wsLink = new WebSocketLink({
  uri: `${config.subscriptionUrl}/graphql`,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const token = await localStorage.getItem(config.authToken)
      return ({authToken: `Bearer token-${token}`})
    },
    keepAlive: 30000,
    retryAttempts: Infinity,    
  }
})

// Create an http link:
const httpLink = createUploadLink({
  uri: `${config.remoteUrl}/graphql`
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,),
    )

  if (networkError) console.log(`[Network error]: ${networkError}`);
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(config.authToken)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer token-${token}` : "",
    }
  }
})

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(link)]),
  cache: cache,
  resolvers: {}
})
cache.writeQuery({
  query: IS_LOGGED_IN,
  data: {
    isLoggedIn: !!localStorage.getItem(config.authToken)
  }
})

client.onResetStore(() => {
  cache.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      isLoggedIn: !!localStorage.getItem(config.authToken)
    }
  })
})

export { wsLink }
export default client
