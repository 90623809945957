import { gql }from '@apollo/client'

import { PRODUCT_FRAGMENT } from './'

const UPLOAD_PRODUCT_MEDIA_MUTATION = gql`
  mutation uploadProductMedia($file: Upload!, $product: ID, $type: PRODUCT_UPLOAD_TYPE!){
    uploadProductMedia(file: $file, product: $product, type: $type){
      ...PRODUCT_FRAGMENT
    }
  }
  ${PRODUCT_FRAGMENT}
`

export default UPLOAD_PRODUCT_MEDIA_MUTATION
