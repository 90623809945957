import { gql }from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const UPLOAD_VENDOR_MEDIA_MUTATION = gql`
  mutation uploadVendorMedia($file: Upload!, $vendor: ID, $type: VENDOR_UPLOAD_TYPE!){
    uploadVendorMedia(file: $file, vendor: $vendor, type: $type){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`
