import React from 'react'
import { useQuery } from '@apollo/client'

import { Modal, Loader, IntlValue, IntlText, Form, Button } from '../../../components'
import { CATEGORY_QUERY } from '../../../graphql'
import { returnGraphqlError } from '../../../helpers'
import { CategoryForm } from '../../../views'

export function CategoryModal() {

  return(
    <Modal.Modal id="category">
      {({attributes, closeModal}) =>
        <ModalInner attributes={attributes} closeModal={closeModal} />
      }
    </Modal.Modal>
  )

}

function ModalInner({attributes, closeModal}) {

  const { loading, data, error } = useQuery(CATEGORY_QUERY, {variables: {id: attributes?.category?.id}, skip: !!!attributes?.category?.id})

  if (loading) return <Loader theme="main" />
  if (error) {
    closeModal()
    return returnGraphqlError(error)
  }


  const { category } = data || {}

  return(
    <Modal.Info>
      <Modal.Header>
        {category ?
          <IntlValue>{category?.name}</IntlValue>
        :
          <IntlText group="category-modal" id="title">Add category</IntlText>
        }
      </Modal.Header>
      <Modal.Content>
        <CategoryForm category={category} success={closeModal} actions={
          <Form.Field fluid>
            <Button onClick={closeModal} fluid><IntlText id="back" /></Button>
          </Form.Field>
        }/>
      </Modal.Content>
    </Modal.Info>
  )

}
