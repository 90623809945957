import { useMutation } from '@apollo/client'

import { ME_QUERY, EDIT_VENDOR_MUTATION } from '../../../graphql'
import { useIntl, useValidatedForm } from '../../../hooks'
import { sentenceCase, toast } from '../../../helpers'
import { LabeledInput, Button, MultilangInput, IntlText, Validation, Form, Notification } from '../../../components'

const validations = {
  "intro": [
    {id: "required", validation: (val) => !!val},
    {id: "missingEn", validation: (val) => !!val?.en},
    {id: "missingEs", validation: (val) => !!val?.es},
  ]
}

export function BasicDetails({vendor}) {
  
  const { getTranslation } = useIntl()
  const [editVendor, {loading}] = useMutation(EDIT_VENDOR_MUTATION, {refetchQueries: [{query: ME_QUERY}]})
  const { form: {name, intro}, updateField, edited, errors, done, validate } = useValidatedForm({name: vendor.name, intro: {...vendor.intro} || {}}, validations)

  function validateIntro(value){
    if (value.length && value.length > 30){
      toast.warning(getTranslation({group: "basic-details", id: "intro-maxlength"}))
      value = value.substring(0,29)
    }

    return sentenceCase(value)
  }

  async function save(){
    let data = {}

    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

    data.intro = {en: intro.en, es: intro.es}

    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      toast.success(getTranslation({id: "save-success"}))
      done()
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="basic-details">
      <Form.Form>
        <Form.Field>
          <LabeledInput name="name" placeholder={getTranslation({group: "basic-details", id: "input-name"})} value={name} disabled/>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.intro}>
            <MultilangInput name="intro" value={intro} format={(value) => validateIntro(value)} placeholder={getTranslation({group: "basic-details", id: "input-intro"})} onChange={(e, value) => updateField({key: [e.target.name], value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <LabeledInput name="type" placeholder={getTranslation({group: "basic-details", id: "input-type"})} value={vendor.type} disabled/>
        </Form.Field>
        <Form.Field>
          <LabeledInput name="contract" placeholder={getTranslation({group: "basic-details", id: "input-contract"})} value={vendor.contract} disabled/>
        </Form.Field>
        <Form.Field>
          <LabeledInput name="comission" placeholder={getTranslation({group: "basic-details", id: "input-comission"})} value={`${vendor.serviceFee * 100}%`} disabled/>
        </Form.Field>
        {vendor?.tags?.includes("exclusive") ? 
          <Form.Field>
            <Notification icon="badge-percent" type='success' title={getTranslation({group: "basic-details", id: "exclusive"})}>
              {getTranslation({group: "basic-details", id: "exclusive-info"})}              
            </Notification>            
          </Form.Field>        
        : null}
        <Form.Field>
          {edited && <Button theme="main" className="full" loading={loading} onClick={() => save()}><IntlText id="save" /></Button>}
        </Form.Field>
      </Form.Form>
    </div>
  )
}
