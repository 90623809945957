import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Button, IntlText } from '../../components'

import './style.css'

export function Pagination({page, setPage, pageInfo}){

  function _setPage(_page){
    setPage(_page)
  }

  if (!pageInfo) return null;

  return(
    <div className="pagination">
      <div className="left">
        <Button onClick={() => _setPage(parseInt(pageInfo?.actualPage)-1)} disabled={!(pageInfo?.actualPage > 1)}><FontAwesome name="angle-double-left"/></Button>
      </div>
      <div className="label">
        <div><IntlText group="pagination" id="page"/> {parseInt(pageInfo?.actualPage)}/{pageInfo?.totalPages}</div>
        <div className="total-results">
          <IntlText group="pagination" id="total" variables={{total: pageInfo?.totalNodes}} />
        </div>
      </div>
      <div className="right">
        <Button onClick={() => _setPage(parseInt(pageInfo?.actualPage)+1)} className="left" disabled={!pageInfo?.hasNextPage}><FontAwesome name="angle-double-right"/></Button>
      </div>
    </div>
  )

}
