import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { EDIT_PROMOTION_MUTATION, DELETE_PROMOTION_MUTATION } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { LabeledCheckbox, ContextMenu, Loader, IntlText, IntlValue } from '../../../components'

import './style.css'

export function PromotionsList({data, refetch, loading}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [deletePromotion, { loading: deleteLoading }] = useMutation(DELETE_PROMOTION_MUTATION)
  const [editPromotion, { loading: editLoading}] = useMutation(EDIT_PROMOTION_MUTATION)

  async function _editPromotion(vars){
    try {
      await editPromotion({variables: vars})
      refetch()
      toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _deletePromotion(id){
    try {
      await confirm()
      await deletePromotion({variables: {id}})
      refetch()
      toast.success(getTranslation({id: "delete-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="promotions-list" className="relative">
      {(deleteLoading || editLoading) && <Loader theme="main" overlay/>}
      {data?.promotions?.length > 0 ? data.promotions.map((promotion, key) =>
        <div key={key} className="promotion">
          <LabeledCheckbox id={`${promotion.id}-active`} loading={loading} checked={promotion.active} rounded onChange={({target}) => _editPromotion({id: promotion.id, data: {active: !!target.checked}})}/>
          <div className="info">
            <span><IntlValue>{promotion.label}</IntlValue></span>
            <span className="desc"><IntlValue>{promotion.description}</IntlValue></span>
          </div>
          <ContextMenu.Menu id={promotion?.id} >
            <ContextMenu.Link to={`/promotions/${promotion.id}`} icon="external-link-alt"><IntlText id="edit" /></ContextMenu.Link>
            <ContextMenu.Button icon="trash" className="invalid" onClick={() => _deletePromotion(promotion.id)}><IntlText id="delete" /></ContextMenu.Button>
          </ContextMenu.Menu>
        </div>
      ) :
        <div className="empty-list">
          <div className='empty-placeholder'>
            <FontAwesome name="folder-open" />
            <span className="text"><IntlText id="empty-list" variables={{type: "promotions"}} /></span>
          </div>
          <Link to="/promotions/new" className='button main'><IntlText group="promotion-list" id="add-new-promotion" /></Link>
        </div>
      }
    </div>
  )
}
