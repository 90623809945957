import { gql }from '@apollo/client'

export const VENDOR_STAT_FRAGMENT = gql`
  fragment VENDOR_STAT_FRAGMENT on VendorStat {
    id
    vendor
    period
    start
    end
    completedPurchases
    completedPurchasesChange
    completedPurchasesChangePercentage
    cancelledPurchases
    cancelledPurchasesChange
    cancelledPurchasesChangePercentage
    rejectedPurchases
    rejectedPurchasesChange
    rejectedPurchasesChangePercentage
    unprocessedPurchases
    unprocessedPurchasesChange
    unprocessedPurchasesChangePercentage
    unprocessedPurchasesRatio
    grossIncome
    grossIncomeChange
    grossIncomeChangePercentage
    netIncome
    netIncomeChange
    netIncomeChangePercentage
    servicefee
    servicefeeChange
    servicefeeChangePercentage
    aov
    aovChange
    aovChangePercentage
    feedbacks
    positiveFeedbacks
    negativeFeedbacks
    neutralFeedbacks
    mostOrderedProducts{
      name{
        en
        es
      }
      categoryName{
        en
        es
      }
      rank
      count
    }
  }
`
