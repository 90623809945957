import React, { useEffect, useState } from 'react'
import { useSubscription, useQuery } from '@apollo/client'
import moment from 'moment'

import { MY_PURCHASES_QUERY, MY_PURCHASES_UPDATED_SUBSCRIPTION, VENDOR_EDITED_SUBSCRIPTION } from '../../graphql'
import { useAuth, useIntl, useVisibilityChange, useInterval } from '../../hooks'
import { notify } from '../../helpers'
import { Dropdown, Button } from '../../components'


import './style.css'

export function Notifications(){ 
  const { isLoggedIn } = useAuth()
  const { getTranslation } = useIntl()
  const [muted, toggleMuted ] = useState(false)
  const { data, subscribeToMore, refetch } = useQuery(MY_PURCHASES_QUERY, {variables: {date: moment().format("YYYY-MM-DD"), status: ["PROCESSED"]}, notifyOnNetworkStatusChange: true, skip: !isLoggedIn})

  useEffect(() => {
    if (data?.myPurchases?.length > 0) notify({title: getTranslation({group: "notifications", id: "processed"}), message: getTranslation({group: "notifications", id: "processed-info",  variables: {number: data?.myPurchases.length}}), onError: () => toggleMuted(true)})
    // eslint-disable-next-line
  }, [data])
  useInterval(() => {
    if (data?.myPurchases?.length > 0) {
      notify({title: getTranslation({group: "notifications", id: "processed"}), message: getTranslation({group: "notifications", id: "processed-info",  variables: {number: data?.myPurchases.length}}), onError: () => toggleMuted(true)})
    }
  }, 1000 * 120, [data])  
  useVisibilityChange((e) => {
    if (document.visibilityState === "visible"){
      console.log("Background refresh");
      refetch()
    }
  })   
  useSubscription(VENDOR_EDITED_SUBSCRIPTION, {skip: !isLoggedIn})
  useEffect(() => {
    const unsub = subscribeToMore({
      document: MY_PURCHASES_UPDATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const _data = [...prev.myPurchases]
        const index = _data.findIndex((purchase) => purchase.id === subscriptionData.data.myPurchasesUpdated.id)

        if (subscriptionData.data?.myPurchasesUpdated?.status === "CANCELLED"){
          notify({title: getTranslation({group: "notifications", id: "cancelled"}), message: getTranslation({group: "notifications", id: "view-orders"}), onError: () => toggleMuted(true), isError: true})
        } else if (subscriptionData.data?.myPurchasesUpdated?.status === "UNPROCESSED"){
          notify({title: getTranslation({group: "notifications", id: "cancelled"}), message: getTranslation({group: "notifications", id: "view-orders"}), onError: () => toggleMuted(true), isError: true})
        } else if (subscriptionData.data?.myPurchasesUpdated?.status === "PROCESSED"){
          notify({title: getTranslation({group: "notifications", id: "processed"}), message: getTranslation({group: "notifications", id: "view-orders"}), onError: () => toggleMuted(true)})
        }                

        if (index >= 0){
          if (subscriptionData.data.myPurchasesUpdated.status !== "PROCESSED"){
            _data.splice(index, 1)

            return Object.assign({}, prev, {
              myPurchases: _data
            })
          }
          _data[index] = subscriptionData.data.myPurchasesUpdated
        } else if(subscriptionData.data.myPurchasesUpdated.status === "PROCESSED") {
          return Object.assign({}, prev, {
            myPurchases: [subscriptionData.data.myPurchasesUpdated, ..._data]
          })
        }
      }
    })

    return () => {
      unsub()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    function handleClick(){
      toggleMuted(false)
    }

    if (muted){
      document.addEventListener('click', handleClick)
    }

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [muted])

  if (!isLoggedIn) return null

  return(
    <>
      {muted && 
        <Button theme="reject" icon="volume-slash" onClick={() => toggleMuted(false)} />
      }
      <Dropdown.Dropdown button={data?.myPurchases?.length > 0 ? <span className='unread'><span>{data?.myPurchases?.length}</span></span> : null} icon='bell'>
        <Dropdown.Link to='/' navlink={false}>{getTranslation({group: "notifications", id: "processed-info",  variables: {number: data?.myPurchases?.length}})}</Dropdown.Link>
      </Dropdown.Dropdown>        
    </>
  )

}
