import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { useQueryBuilder } from '../hooks'
import { IntlContext } from '../contexts'
import { formatPrice } from '../helpers'
import { VENDOR_TRANSACTIONS_OF_PERIOD } from '../graphql'
import { IntlText, Loader, ContextMenu, StatusDot, Pagination, IntlValue, Table, Filters, DatePicker } from '../components'

export function VendorTransactions() {

    const { getTranslation } = useContext(IntlContext)
    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const defaultDate = search.getAll("date")?.length > 0 ? [search.getAll("date")[0], search.getAll("date")[1]] : [moment().startOf("day").format("YYYY-MM-DD"), moment().startOf("day").add(1, "day").format("YYYY-MM-DD")]
    const { params, addParam, removeParam, isQuery, buildQuery, runQuery, setPage, page } = useQueryBuilder({
        data: {
            date: {key: "date", label: `${defaultDate[0]} - ${defaultDate[1]}`, value: [defaultDate[0], defaultDate[1]]}
        },
        page: search.get("page"),
        sort: search.get("sort")
    })
    const { data, loading, error } = useQuery(VENDOR_TRANSACTIONS_OF_PERIOD, {variables: buildQuery(), skip: !isQuery()})

    function setDate({selection: {startDate, endDate}}){
        const _start = moment(startDate).startOf("day")
        const _end = moment(endDate).add(1, "days").startOf("day")
    
        addParam({
          date: {key: "start", label: `${_start.format("YYYY-MM-DD")} - ${_end.format("YYYY-MM-DD")}`, value: [_start.format("YYYY-MM-DD"), _end.format("YYYY-MM-DD")], skip: !(_start || _end)},
        })
    }        

    if (loading || error) return <Loader theme="main" />

    return(
        <div id="vendor-transactions" className="container">
            <div className="row padding">
                <div>
                    <Filters.Bar >
                        <Filters.Dropdown style={{marginLeft: "auto"}} placeholder={getTranslation({group: "vendor-stats", id: "date"})} value={params?.date && `${params?.date?.label}`} disableInClickClose right reset={() => removeParam("date")} run={runQuery}>
                            <DatePicker.Range startDate={params?.date?.value ? moment(`${params.date.value[0]}`).toDate() : new Date()} endDate={params?.date?.value ? moment(`${params.date.value[1]}`).toDate() : new Date()} onChange={setDate} />
                        </Filters.Dropdown>
                    </Filters.Bar>
                </div>           
                {data?.vendorTransactionsOfPeriod &&
                    <div className='stat-cards'>
                        <div className='stat'>
                            <div className='label'><IntlText group="vendor-transactions" id="purchasesWithCash" /></div>
                            <div className='value'>{data?.vendorTransactionsOfPeriod?.purchasesWithCash}                                  </div>
                        </div>            
                        <div className='stat'>
                            <div className='label'><IntlText group="vendor-transactions" id="totalCashRevenue" /></div>
                            <div className='value'>{formatPrice(Math.round(data?.vendorTransactionsOfPeriod?.totalCashRevenue))}</div>
                        </div>      
                        <div className='stat'>
                            <div className='label'><IntlText group="vendor-transactions" id="netVendorPriceAdjument" /></div>
                            <div className='value'>{formatPrice(Math.round(data?.vendorTransactionsOfPeriod?.netVendorPriceAdjument))}</div>
                        </div>      
                        <div className='stat'>
                            <div className='label'><IntlText group="vendor-transactions" id="netCashMovement" /></div>
                            <div className='value'>{formatPrice(Math.round(data?.vendorTransactionsOfPeriod?.netCashMovement))}</div>
                        </div>      
                        <div className='stat'>
                            <div className='label'><IntlText group="vendor-transactions" id="cashServiceFee" /></div>
                            <div className='value'>{formatPrice(Math.round(data?.vendorTransactionsOfPeriod?.cashServiceFee))}</div>
                        </div>      
                    </div>      
                }
                <Table.Table>
                    {data?.vendorTransactionsOfPeriod?.nodes?.map((item, key) =>
                        <Table.Body key={key}>
                            <Table.Tr>
                                <Table.Th colSpan={5}><IntlText group="vendor-transactions" id="order-transactions" variables={{id: item[0]?.relatedPurchase}} /></Table.Th>
                            </Table.Tr>
                            {item.map((historyItem, key) =>
                                <tr className="item" key={key}>
                                    <td><StatusDot active={!historyItem.voided} /></td>
                                    <td className="date">{moment(historyItem?.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</td>
                                    <td className="description-field">
                                        <div>
                                        <span><IntlValue>{historyItem?.description}</IntlValue></span>
                                        </div>
                                    </td>
                                    <td className={`price-field ${historyItem.value > 0 ? "plus" : "minus"}`}>
                                        {formatPrice(historyItem?.value)}
                                    </td>
                                    <td>
                                        <ContextMenu.Menu id={historyItem.id} >
                                        {historyItem.relatedPurchase &&
                                            <ContextMenu.Link icon="external-link-alt" to={`/orders/${historyItem.relatedPurchase}`}><IntlText group="vendor-book" id="view" /></ContextMenu.Link>
                                        }
                                        </ContextMenu.Menu>
                                    </td>
                                </tr>
                            )}
                        </Table.Body>
                    )}
                </Table.Table>
                <Pagination page={page} setPage={setPage} pageInfo={data?.vendorTransactionsOfPeriod?.pageInfo}/>            
            </div>                
        </div>
    )
}
