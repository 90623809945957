import { gql }from '@apollo/client'

import { PRODUCT_FRAGMENT } from './'

const DELETE_PRODUCT_MEDIA_MUTATION = gql`
  mutation deleteProductMedia($id: ID!, $type: PRODUCT_UPLOAD_TYPE!){
    deleteProductMedia(id: $id, type: $type){
      ...PRODUCT_FRAGMENT
    }
  }
  ${PRODUCT_FRAGMENT}
`

export default DELETE_PRODUCT_MEDIA_MUTATION
