import React from 'react'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { formatPrice, vendorStat } from '../../../helpers'
import { IntlText } from '../../../components'

import './style.css'

export function VendorStat({data}) {

    return(
        <div className='vendor-stat'>
            <div className='title'>
                <span className='label'>{vendorStat.getLabel(data)}</span>
                <span className='date'>{moment(data.start).format("YYYY-MM-DD")} - {moment(data.end).format("YYYY-MM-DD")}</span>
            </div>
            <div className='stats'>
                <div className='stat'>
                    <div className='label'><IntlText group="vendor-stat" id="net-income" /></div>
                    <div className='value'>{formatPrice(Math.round(data.netIncome))}
                        <div className="value-change">
                            <span className={`${data.netIncomeChange > 0 ? "positive" : "negative"}`}>{(data.netIncomeChangePercentage * 100).toFixed(2)}%</span>
                        </div>                    
                    </div>
                </div>                    
                <div className='stat'>
                    <div className='label'><IntlText group="vendor-stat" id="aov" /></div>
                    <div className='value'>{formatPrice(Math.round(data.aov))}
                        <div className="value-change">
                            <span className={`${data.aovChange > 0 ? "positive" : "negative"}`}>{(data.aovChangePercentage * 100).toFixed(2)}%</span>
                        </div>                    
                    </div>
                </div>
                <div className='stat'>
                    <div className='label'><IntlText group="vendor-stat" id="completed" /></div>
                    <div className='value'>{Math.round(data.completedPurchases)}
                        <div className="value-change">
                            <span className={`${data.completedPurchasesChange > 0 ? "positive" : "negative"}`}>{(data.completedPurchasesChangePercentage * 100).toFixed(2)}%</span>
                        </div>                    
                    </div>
                </div>
                <div className='stat'>
                    <div className='label'><IntlText group="vendor-stat" id="unprocessed" /></div>
                    <div className='value'>{Math.round(data.unprocessedPurchases)}
                        <div className="value-change">
                            <span className={`${data.unprocessedPurchasesChange > 0 ? "positive" : "negative"}`}>{(data.unprocessedPurchasesChangePercentage * 100).toFixed(2)}%</span>
                        </div>                    
                    </div>
                </div>
                <div className='stat'>
                    <div className='label'><IntlText group="vendor-stat" id="feedbacks" /></div>
                    <div className='value'>{Math.round(data.feedbacks)}
                        <div className="value-change">
                            <span className="positive"><FontAwesome name="smile" /> {data.positiveFeedbacks}</span>
                            <span className="neutral"><FontAwesome name="meh" /> {data.neutralFeedbacks}</span>
                            <span className="negative"><FontAwesome name="frown" /> {data.negativeFeedbacks}</span>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    )
}
