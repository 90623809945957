import React, { useRef, useEffect } from 'react'
import FontAwesome from 'react-fontawesome'
import { NavLink, useMatch } from 'react-router-dom'

import { useDetectOutsideClick } from '../../hooks'

import './style.css'

function Menu({className = "", bordered, children, rounded, ...rest}) {
  return(
    <nav className={`sidebar-menu ${className} ${bordered ? "bordered" : ""} ${rounded ? "rounded" : ""}`} {...rest}>
      <ul>
        {children}
      </ul>
    </nav>
  )
}

function SubMenu({className = "", icon = "dot-circle", label, children, path, help, ...rest}){

  const wrapper = useRef(null)
  const menu = useRef(null)
  const match = useMatch(`${path}/*`)
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu, initialState: !!match, disableOutclick: true})
  
  useEffect(() => {
    setIsActive(!!match)
  // eslint-disable-next-line    
  }, [match])

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return (
    <li className={`${className} ${isActive ? "opened" : ""}`} ref={wrapper} {...rest}>
      <button onClick={() => setIsActive(!isActive)}>
        {icon && showIcon()}
        <div className="text">
          <span>{label}</span>
          {help && <span className="help-text">{help}</span>}
        </div>
        <FontAwesome className="toggle" name="chevron-up" />     
      </button>
      <ul className="submenu" ref={menu} style={isActive ? {height: menu?.current?.scrollHeight || "auto"} : null}>
        {children}
      </ul>
    </li>
  )
}

function Link({icon = "dot-circle", activeClassName = "active", children, exact = true, help, end = true, ...rest}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return (
    <li>
      <NavLink className={({ isActive }) => isActive ? activeClassName : undefined} exact="false" {...rest} end={end}>
        {icon && showIcon()}
        <div className="text">
          <span>{children}</span>
          {help && <span className="help-text">{help}</span>}
        </div>
      </NavLink>
    </li>)
}

function Button({icon = "dot-circle", activeClassName = "active", children, ...rest}){
  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return (
    <li>
      <button {...rest}>
        {icon && showIcon()}
        <span>{children}</span>
      </button>
    </li>)
}

const sidebarMenu = { Menu, SubMenu, Link, Button}

export default sidebarMenu
