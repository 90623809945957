import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { CATEGORY_QUERY } from '../../graphql'
import { Loader } from '../../components'
import { CategoryForm } from '../../views'

export function Category() {

  const { id } = useParams()
  const {loading, error, data} = useQuery(CATEGORY_QUERY, {variables: {id}})

  if (loading) return <Loader theme="main" />
  if (error) return `Error: ${error}`

  const { category } = data

  return(
    <div id="product" className="container">
      <div className="row padding">
        <CategoryForm category={category} />
      </div>
    </div>
  )

}
