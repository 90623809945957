import React from 'react'

import { AuthKit } from '../../helpers'
import { useAuth } from '../../hooks'
import { ConnectionStatus, Dropdown, IntlText, LanguageSwitcher, ColorSchemeSelector, Notifications, Link } from '../../components'

import './style.css'

export function Header({children}) {

  const { isLoggedIn, user } = useAuth()
  
  return(
    <div id="header">
      <div className="block left">
        {children}
      </div>
      <div className="user block right">    
        <ConnectionStatus />
        <Notifications />
        <ColorSchemeSelector />
        <div><LanguageSwitcher /></div>
        {isLoggedIn ?
          <Dropdown.Dropdown button={<span>{user?.name}</span>}>
            <Dropdown.Item icon={<i className="fas fa-sign-out-alt" />} onClick={() => AuthKit.logout()}><IntlText id="logout" /></Dropdown.Item>
          </Dropdown.Dropdown>        
        :
          <Link icon="sign-in" to="login" button><IntlText group='auth' id='sign-in' /></Link>
        }
      </div>
    </div>
  )
}
