import React from 'react'

import { useAuth } from '../../hooks'
import { Loader, Title, IntlText } from '../../components'
import { VendorCard } from '../../views'

import './Preview.css'

export default function Preview() {

  const { loading, user, error } = useAuth()
  
  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  return(
    <div id="preview">
      <Title tag="h3"><IntlText id="live-preview" /></Title>
      <VendorCard vendor={user} />
    </div>
  )
}
