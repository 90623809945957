import React from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlText, Title } from '../../../components'

import './style.css'

export function PackageBox({children, price, title}) {

  return(
    <div className="package-box">
      <Title tag="h4">{title}</Title>
      <div className="package-box-inner">
          {children}
          <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
            {price && 
              <>
                <div className="icon">
                  <FontAwesome name="dollar-sign" />
                </div>
                <div className="price">{price} MXN</div>
              </>
            }
            <a className='link button main' href="https://wa.me/529842592973" target="_blank" rel="noreferrer"><IntlText group="photoshoot" id="interested" /></a>
          </div>
      </div>
    </div>
  )
}
