import React from 'react'

import { Title } from '../'

import './style.css'

export function Heading({children, info, spacing, left, tag = "h2"}) {
  return(
    <div className={`heading ${spacing ? "spacing" : ""} ${left ? "left" : ""}`}>
        <Title tag={tag}>{children}</Title>
        {info && <span className="info">{info}</span>}
    </div>        
  )
}
