import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'

import { IntlContext } from '../../contexts'

import './style.css'

export function CopyInput({label, children}){

    const { getTranslation } = useContext(IntlContext)

    function copy(){
        navigator.clipboard.writeText(children)
        toast.success(getTranslation({id: "copy-success"}))
    }

    return(
        <div className='copy-input' onClick={copy}>
            <div className='label'>
                <div className='icon'><FontAwesome name="link" /></div>
                <div className='text'>{label}</div>
            </div>
            <div className='value'><input value={children} readOnly disabled/></div>
            <div className='copy-icon'><FontAwesome name="copy" /></div>
        </div>
    )
}