import React from 'react'

import { getAsset } from '../../../helpers'
import { IntlText, Title, Button } from '../../../components'

import './style.css'

export function MaterialsList({materials = []}) {

  return(
    <div className='materials-list'>
        {materials.map((_material, key) => {
            const _image = getAsset(`materials/${_material.id}.png`)

            if (!_image) return null

            return(
                <div key={key} className={`material ${_material.background}`}>
                    <div className="image"><img src={_image} alt={_material.id} title={_material.id} /></div>
                    <Title tag="h3" className="no-desc"><IntlText group="materials" id={_material.id} /></Title>
                    <div className='note'><p><IntlText group="materials" id={`${_material.id}-desc`} /></p></div>
                    <a href={_image} download >
                    <Button icon="download"><IntlText id="download" /></Button>
                    </a>                    
                </div>  
            )
        })}
    </div>            
  )
}
