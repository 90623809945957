import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'

import { IntlContext } from '../../contexts'
import { CATEGORIES_QUERY } from '../../graphql'
import { Filters, Loader } from '../../components'
import { CategoyList  } from '../../views'

export function Categories(){

  const { getTranslation } = useContext(IntlContext)
  const [filter, setFilter] = useState()
  const { data, loading, refetch } = useQuery(CATEGORIES_QUERY, {fetchPolicy: "network-only"})

  return(
    <div id="categories" className="container">
      {loading && <Loader theme="main" overlay/>}
      <div className="row padding">
        <Filters.Bar>
          <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter(null)} placeholder={getTranslation({id: "search-placeholder"})} />
        </Filters.Bar>
        <CategoyList data={data} refetch={refetch} filter={filter}/>
      </div>
    </div>
  )
}
