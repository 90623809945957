import { gql }from '@apollo/client'

import { PURCHASES_FRAGMENT } from '../'

export const MY_PURCHASES_UPDATED_SUBSCRIPTION = gql`
  subscription myPurchasesUpdated{
    myPurchasesUpdated{
      ...PURCHASES_FRAGMENT
    }
  }
  ${PURCHASES_FRAGMENT}
`

