import { gql }from '@apollo/client'

const RIDER_FRAGMENT = gql`
  fragment RIDER_FRAGMENT on Rider {
    id
    name
    slug
    active
    available
    phone{
      countryCode
      phoneNumber
    }
  }
`

export default RIDER_FRAGMENT
