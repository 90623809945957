import { gql }from '@apollo/client'

const ADMIN_FRAGMENT = gql`
  fragment ADMIN_FRAGMENT on Admin {
    id
    name
    username
  }
`

export default ADMIN_FRAGMENT
