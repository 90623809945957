import React, { useEffect } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import { useAuth, useModal } from '../../hooks'
import { Modalv3, Title, IntlText, Button } from '../../components'

export function ExclusiveOfferModal(){

    const modal = useModal()
    const { user } = useAuth()
    const navigate = useNavigate()
    const hide = localStorage.getItem("hide-exclusive-modal")
    const feedback = user?.percentageOfPositive
    const daysPassed = moment.duration(moment().diff(moment(hide, 'X')))?.asDays() || 0
    const alreadyFeatured = !!user?.tags?.includes("exclusive")

    useEffect(() => {
        if (user?.role === "VENDOR"){
            if (!alreadyFeatured && feedback > 0.8 && (moment(hide, 'X').isBefore(moment(), "day") || !hide || daysPassed > 7)) modal.show()
        }
    // eslint-disable-next-line
    }, [feedback, alreadyFeatured])

    function dontShow(){
        localStorage.setItem("hide-exclusive-modal", moment().unix())
        modal.hide()
    }

    function checkInvoice(){
        navigate(`/become-exclusive`)
        modal.hide()
    }

    return(
        <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="exclusive-offer" id="title" /></Title>}>
            <div className="text-content">
                <p><IntlText group="exclusive-offer" id="description" /></p>
                <p><IntlText group="exclusive-offer" id="description2" /></p>
                <p><IntlText group="exclusive-offer" id="description3" /></p>
            </div>
            <div className="actions">
                <Button onClick={dontShow}><IntlText group="exclusive-offer" id="not-interested" /></Button>
                <Button theme="main" onClick={checkInvoice}><IntlText group="exclusive-offer" id="check-details" /></Button>
            </div>
        </Modalv3>    
    )
}