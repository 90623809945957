import React from 'react'

import { HelpCenter as HelpCenterView } from '../../views'

export function HelpCenter() {

  return(
    <div className="container">
      <div className="row padding">
        <div className="relative">
          <HelpCenterView />
        </div>
      </div>
    </div>
  )
}
