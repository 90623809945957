import { useMutation } from '@apollo/client'

import { EDIT_ADDON_MUTATION, DELETE_ADDON_MUTATION } from '../../../graphql'
import { useModal, useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Loader, Filter, IntlText, IntlValue, LabeledCheckbox, StatusDot, Table, ContextMenu, Modalv2, Title, Restricted } from '../../../components'
import { Addon } from '../../../views'

export function AddonsList({data, refetch, filter}){

  const modal = useModal()
  const { confirm } = useConfirm()
  const { getTranslatedValue, getTranslation } = useIntl()
  const [editAddon, { loading: editLoading }] = useMutation(EDIT_ADDON_MUTATION)
  const [deleteAddon, { loading: deleteLoading }] = useMutation(DELETE_ADDON_MUTATION)

  async function _deleteAddon(id){
    const alert = toast.loading()
    try {
      await confirm()
      await deleteAddon({variables: {id}})
      refetch()
      alert.success(getTranslation({id: "delete-success", defaultValue: "Successfully deleted!"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function onSuccess(){
    await refetch()
    modal.hide()
  }  
  
  return(
    <div id="addons-list" className="relative">
      {(!!deleteLoading || !!editLoading) && <div className="full-size-loader"><Loader theme="main"/></div>}
      <Filter id="addons" data={data?.productAddons || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => data.id}, {filter: (data) => getTranslatedValue(data.name)}, {filter: (data) => data.disabled}]}>
        {(data) =>
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th className="text-left"><IntlText group="table-header" id="name" /></Table.Th>
                <Table.Th><IntlText group="table-header" id="instruction" /></Table.Th>
                <Table.Th className="spacer center"><IntlText group="table-header" id="min" /></Table.Th>
                <Table.Th className="spacer center"><IntlText group="table-header" id="max" /></Table.Th>
                <Table.Th><IntlText group="table-header" id="disabled" /></Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {data.map((_addon) =>
                <Table.Tr key={_addon.id} className="addon">
                  <Table.Td><StatusDot active={!_addon.disabled} /></Table.Td>
                  <Table.Td className="text-left"><IntlValue value={_addon.name} /></Table.Td>
                  <Table.Td><IntlValue value={_addon.instruction} /></Table.Td>
                  <Table.Td>{_addon.minSelect}</Table.Td>
                  <Table.Td>{_addon.maxSelect}</Table.Td>
                  <Table.Td centered><LabeledCheckbox id={`disabled-${_addon.id}`} checked={!_addon.disabled} onChange={(e) => editAddon({variables: {id: _addon.id, data: {disabled: !e.target.checked}}})} rounded/></Table.Td>
                  <Table.Td>
                    <Restricted>
                      <ContextMenu.Menu id={_addon.id} >
                        <ContextMenu.Button icon="edit" onClick={() => modal.show(_addon)}><IntlText id="quick-edit" /></ContextMenu.Button>
                        <ContextMenu.Button icon="trash" className='invalid' onClick={() => _deleteAddon(_addon.id)}><IntlText id="delete" /></ContextMenu.Button>
                      </ContextMenu.Menu>
                    </Restricted>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Table.Table>
        }
      </Filter>
      <Modalv2 id="add-addon" modal={modal} header={
        <Title tag="h3">        
          {modal.state ?
            <IntlValue>{modal.state?.name}</IntlValue>
          :
            <IntlText group="addon-modal" id="title" />
          }
        </Title>
      }>
          <Addon addon={modal.state} success={onSuccess} cancel={modal.hide} />
      </Modalv2>           
    </div>
  )
}
