import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Title, HourPicker, IntlValue, Switch } from '../../../components'

import './style.css'

const days = [{en: "Monday", es: "Lunes"}, {en: "Tuesday", es: "Martes"}, {en: "Wednesday", es: "Miercoles"}, {en: "Thursday", es: "Jueves"}, {en: "Friday", es: "Viernes"}, {en: "Saturday", es: "Sabado"}, {en: "Sunday", es: "Domingo"}]

export function VendorOpeningHours({vendor: {id, openingHours}}) {

  const { getTranslation } = useIntl()
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION) 
  const [allDay, toggleAllDay] = useState(openingHours.every((oph) => JSON.stringify(oph) === JSON.stringify(openingHours[0])))

  async function onChange(_data){
    const alert = toast.loading()
    try {
      await editVendor({variables: {id, data: _data}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }
  
  function changeAvailibility(day, value){
    let _openingHours = []
    days.forEach((item, i) => {
      if (i === day) {   
        _openingHours[i] = {
          isOpen: value,
          from: openingHours[i]?.from,
          to: openingHours[i]?.to,
        }      
      } else {
        _openingHours[i] = {
          isOpen: openingHours[i]?.isOpen,
          from: openingHours[i]?.from,
          to: openingHours[i]?.to,
        }             
      }
    })

    onChange({openingHours: _openingHours})
  }

  function changeTime(day, key, data){
    let _openingHours = []
    days.forEach((item, i) => {
      if (i === day) {   
        if (key === "from"){
          _openingHours[i] = {
            isOpen: openingHours[i]?.isOpen,
            from:(data.hours + data.mins).toString(),
            to: openingHours[i]?.to,
          }      
        } else {
          _openingHours[i] = {
            isOpen: openingHours[i]?.isOpen,
            from: openingHours[i]?.from,
            to: (data.hours + data.mins).toString(),
          }      
        }
      } else {
        _openingHours[i] = {
          isOpen: openingHours[i]?.isOpen,
          from: openingHours[i]?.from,
          to: openingHours[i]?.to,
        }             
      }
    })

    onChange({openingHours: _openingHours})
  }

  function changeAllAvailibility(value){
    if (allDay){
      toggleAllDay(false)
    } else {    
      let _openingHours = []
      days.forEach((item, i) => {
        _openingHours[i] = {
          isOpen: true,
          from: openingHours[0]?.from,
          to: openingHours[0]?.to,        
        }
      })
      toggleAllDay(value)
      onChange({openingHours: _openingHours})
    }
  }

  function changeAllTime({key, data}){
    let _openingHours = []
    days.forEach((item, i) => {
      if (key === "from"){
        _openingHours[i] = {
          isOpen: true,
          from: (data.hours + data.mins).toString(),
          to: openingHours[i]?.to,
        }      
      } else {
        _openingHours[i] = {
          isOpen: true,
          from: openingHours[i]?.from,
          to: (data.hours + data.mins).toString(),
        }      

      }
    })

    onChange({openingHours: _openingHours})
  }

  return(
    <div id="opening-hours">
      <div className="day">
        <div className="day-title">
          <Switch rounded id={`open-all`} checked={allDay} onChange={(e) => changeAllAvailibility(e.target.checked)}/>
          <Title tag="h3">{getTranslation({group: "opening-hours", id: "every-day"})}</Title>
        </div>
        {allDay &&
          <div className="hours-wrap">
            <HourPicker time={openingHours[0]?.from || "0000"} placeholder={getTranslation({group: "opening-hours", id: "from"})} onChange={(data) => changeAllTime({key: "from", data})}/>
            <HourPicker time={openingHours[0]?.to || "0000"} placeholder={getTranslation({group: "opening-hours", id: "to"})} onChange={(data) => changeAllTime({key: "to", data})}/>
          </div>
        }
      </div>
      {!allDay && openingHours.map((day, key) =>
        <div key={key} className="day">
          <div className="day-title">
            <Switch rounded id={`open-${key}`} checked={day.isOpen} onChange={(e) => changeAvailibility(key, e.target.checked)}/>
            <Title tag="h3"><IntlValue>{days[key]}</IntlValue></Title>
          </div>
          {day.isOpen &&
            <div className="hours-wrap">
              <HourPicker time={day.from || "0000"} placeholder={getTranslation({group: "opening-hours", id: "from"})} onChange={(data) => changeTime(key, "from", data)}/>
              <HourPicker time={day.to || "0000"} placeholder={getTranslation({group: "opening-hours", id: "to"})} onChange={(data) => changeTime(key, "to", data)}/>
            </div>
          }
        </div>
      )}
    </div>
  )
}
