import React from 'react'
import FontAwesome from 'react-fontawesome'

import './Title.css'

export default function Title({id, tag = "h1", icon, className, children, ...rest}) {

  const Tag = tag

  return(
    <div id={id} className={`title ${className ? className : ""}`} {...rest}>
      <Tag>
        {icon && <FontAwesome name={icon} />}
        {children}
      </Tag>
    </div>
  )
}
