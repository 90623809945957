import React from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { SETTINGS_QUERY } from '../../../graphql'
import { IntlText, Loader } from '../../../components'

export function VendorOpeningHoursBadge({vendor: { openingHours }}) {

  const { loading, error, data } = useQuery(SETTINGS_QUERY, {variables: {keys: ["OPENING_HOUR_FROM", "OPENING_HOUR_TO"]}})

  if (loading) return <Loader style={{margin: 0}} size="small" theme="main" />
  if (error) return <Loader style={{margin: 0}} theme="main" />

  const { settings } = data
  const defaultFrom = settings.find(_setting => _setting.key === "OPENING_HOUR_FROM")
  const defaultTo = settings.find(_setting => _setting.key === "OPENING_HOUR_TO")

  function getOpeningHours(){
    const tomatoHours = {from: defaultFrom?.value, to: defaultTo?.value}
    let date = new Date()
    let today = (date.getDay() || 7) - 1
    let todayHours = openingHours.length === 1 ? openingHours[0] : openingHours[today]

    if (todayHours.from === null || todayHours.to === null || todayHours.isOpen === false) return null

    let data = {
      from: todayHours.from < tomatoHours.from ? tomatoHours.from : todayHours.from,
      to: todayHours.to > tomatoHours.to ? tomatoHours.to : todayHours.to
    }

    data.from = `${data.from.toString().slice(0, 2)}:${data.from.toString().slice(2)}`
    data.to = `${data.to.toString().slice(0, 2)}:${data.to.toString().slice(2)}`
    return data
  }

  const _openingHours = getOpeningHours(openingHours)

  if (_openingHours) return(
    <div className="icon-info">
      <FontAwesome name="clock" />
      <span className="amount">{_openingHours.from} - {_openingHours.to}</span>
    </div>
  )

  return(
    <div className="icon-info">
      <FontAwesome name="clock" />
      <span className="amount"><IntlText group="opening-hours" id="today-closed" /></span>
    </div>
  )
}
