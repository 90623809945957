import React from 'react'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { IntlText } from '../../../components'
import { PurchaseStatusBadge } from '../../../views'

import './style.css'

export function PurchaseDetails({purchase, purchase: {customer, rider, createdAt, status}}) {

  const isToday = moment().isSame(moment(createdAt), 'day')

  return(
    <div className="purchase-details">
      <div className='detail'>
        <span className='label'><IntlText group="purchase-details" id="customer" /></span>
        <div className='inner'>
          <div className="icon">
            <FontAwesome name="user"/>
          </div><div className="info">{customer.name}</div>
        </div>
      </div>  
      {rider && 
        <div className='detail'>
          <span className='label'><IntlText group="purchase-details" id="rider" /></span>
          <div className='inner'>
            <div className="icon">
              <FontAwesome name="motorcycle"/>
            </div><div className="info">{rider.name}</div>
          </div>
        </div>  
      }
      <div className='detail'>
        <span className='label'><IntlText group="purchase-details" id="createdAt" /></span>
        <div className='inner'>
          <div className="icon">
            <FontAwesome name="clock"/>
          </div><div className="info">{moment(createdAt).format(isToday ? 'HH:mm' : 'MM/DD/YYYY HH:mm')}</div>
        </div>
      </div>  
      <div className='detail'>
        <PurchaseStatusBadge purchase={purchase} />
      </div>  
    </div>
  )
}
