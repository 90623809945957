import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IntlText } from '..'

import './style.css'

export function Breadcrumbs({breadcrumbs = {}, title, hideHome, homeClass = "", ...rest}) {

  const [routes, setRoutes] = useState({})
  const { pathname } = useLocation()

  useEffect(() => {
    const path = pathname?.split("/").filter(Boolean)

    function buildTree(){
      let level = breadcrumbs
      let link = ""
      let _routes = {}
      path.forEach(async (p, key) => {
        level = level[p] || level.fallback || {}
        link += `/${p}`
        switch (typeof level?.label) {
          case "function":
            await level?.label({p, link, _routes, key, setRoutes})
            break;
          case "string":
            _routes[p] = {url: link, value: level?.label, rank: key}
            break;
          default:
            break;
        }
      })

      setRoutes(_routes)
    }

    if (path?.length >= 0) buildTree()

  }, [pathname, breadcrumbs])

  if (!pathname) return null
  if (!Object.keys(routes)?.length > 0 && hideHome) return null

  return(
    <nav className="breadcrumbs" {...rest}>
      <ol>
        {!hideHome &&
          <li key="home" className={homeClass}>
            <Link to="/">
              {!Object.keys(routes)?.length > 0 ? 
                breadcrumbs.label
              :
                <IntlText group="breadcrumbs" id="default-home" />
              }
            </Link>
          </li>
        }
        {Object.keys(routes)?.length > 0 && Object.values(routes).sort((a, b) => a.rank - b.rank).map(({url, value}, i) =>
          <li key={i} className={i === (Object.keys(routes)?.length - 1) ? "current" : ""}>
            <Link to={url}>{value}</Link>
          </li>
        )}
      </ol>
    </nav>
  )
}
