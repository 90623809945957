import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '../../hooks'
import { Loader } from '../../components'
import { AuthenticationError } from '../../views'

export function PrivateRoute({auth = () => true, fallback, children}) {

  const { isLoggedIn, loading, error, user } = useAuth()

  if (loading) return <Loader theme="main" />
  if (error) return <AuthenticationError />
  if (!isLoggedIn) return <Navigate to="/login" />
  if (!auth(user)) return fallback

  return children

}
