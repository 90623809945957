import React, { useEffect, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { Modal, Button, Actions, IntlText, ProgressSteps } from '../../../components'
import { AddProductAddon, AddProductAddonOption, Addon, AddonOption, ReviewProductAddon } from '../../../views'
import { CREATE_PRODUCT_ADDON_MUTATION, PRODUCT_QUERY } from '../../../graphql'
import { returnGraphqlError } from '../../../helpers'
import { useValidatedForm } from '../../../hooks'
import { ModalContext, IntlContext } from '../../../contexts'

import './style.css'

export function AddProductAddonModal() {

  const [selected, change] = useState(1)
  const [newAddon, toggleNewAddon] = useState(false)
  const [newOption, toggleNewOption] = useState(false)
  const items = [
    <IntlText group="add-product-addon-modal" id="steps-select-addon" />,
    <IntlText group="add-product-addon-modal" id="steps-select-options" />,
    <IntlText group="add-product-addon-modal" id="steps-product-review" />
  ]
  const { attributes, closeModal, modal } = useContext(ModalContext)
  const { getTranslation } = useContext(IntlContext)
  const { form: { addon, options  }, updateField, resetForm } = useValidatedForm({addon: undefined, options: []})
  const [createProductAddon, { loading: editLoading }] = useMutation(CREATE_PRODUCT_ADDON_MUTATION)

  useEffect(() => {
    resetForm()
    change(1)
    toggleNewAddon(false)
    toggleNewOption(false)
    // eslint-disable-next-line
  }, [attributes, modal])

  function selectAddon(_addon){
    change(2)
    updateField({key: "addon", value: _addon})
  }

  async function save(){
    let data = {
      product: attributes?.id,
      addon: addon?.id,
      options: options ? options.map((option) => option.id) : []
    }

    try {
      await createProductAddon({variables: {data}, refetchQueries: [{query: PRODUCT_QUERY, variables: {id: attributes.id}}]})
      closeModal()
    } catch (e) {
      console.log("e", e);
      returnGraphqlError(e)
    }

  }

  function getActiveView(){
    switch (selected) {
      case 1: return newAddon ?
        <Modal.Content>
          <div className="section">
            <Addon success={() => toggleNewAddon(false)} cancel={() => toggleNewAddon(false)}/>
          </div>
        </Modal.Content>
      :
        <>
          <Modal.Content>
            <div className="section">
              <ProgressSteps navigationEnabled={false} items={items} selected={selected} change={change}/>
            </div>
            <div className="section">
              <AddProductAddon addon={addon} onChange={selectAddon} />
              <span id="add-new-addon-button" onClick={() => toggleNewAddon(true)}><FontAwesome name="plus-circle" /><IntlText group="add-product-addon-modal" id="add-new-addon" /></span>
            </div>
          </Modal.Content>
          {addon &&
            <Modal.Footer>
              <Actions fluid>
                  <Button theme="alt" onClick={() => change(2)}><IntlText id="next" /></Button>
              </Actions>
            </Modal.Footer>
          }
        </>
      case 2: return newOption ?
        <>
          <Modal.Content>
            <div className="section">
              <AddonOption success={() => toggleNewOption(false)} cancel={() => toggleNewOption(false)} />
            </div>
          </Modal.Content>
        </>
      :
        <>
          <Modal.Content>
            <div className="section">
              <ProgressSteps navigationEnabled={false} items={items} selected={selected} change={change}/>
            </div>
            <div className="section">
              <div className="section">
                <AddProductAddonOption options={options} onChange={(data) => updateField({key: "options", value: data.selected})}/>
              </div>
              <span id="add-new-addon-button" onClick={() => toggleNewOption(true)}><FontAwesome name="plus-circle" /><IntlText group="add-product-addon-modal" id="add-new-option" /></span>
            </div>
          </Modal.Content>
          <Modal.Footer>
            <Actions fluid>
              <Button theme="alt" onClick={() => change(1)}><IntlText id="back" /></Button>
              <Button theme="alt" onClick={() => options?.length > 0 ? change(3) : toast.error(getTranslation({group: "add-product-addon-modal", id: "select-options-to-continue"}))}><IntlText id="next" /></Button>
            </Actions>
          </Modal.Footer>
        </>
      case 3: return(
        <>
          <Modal.Content>
            <div className="section">
              <ProgressSteps navigationEnabled={false} items={items} selected={selected} change={change}/>
            </div>
            <div className="section">
              <ReviewProductAddon addon={addon} options={options} />
            </div>
          </Modal.Content>
          <Modal.Footer>
            <Actions fluid>
              <Button theme="alt" onClick={() => change(2)}><IntlText id="back" /></Button>
              <Button theme="main" loading={editLoading} type="theme" onClick={save}><IntlText id="save" /></Button>
            </Actions>
          </Modal.Footer>
        </>
      )
      default: return null
    }
  }

  return(
    <Modal.Modal id="add-product-addon">
      {({attributes}) =>
        <Modal.Info>
          <Modal.Header><IntlText group="add-product-addon-modal" id="title" /></Modal.Header>
          {getActiveView()}
        </Modal.Info>
      }
    </Modal.Modal>
  )
}
