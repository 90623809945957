import React from 'react'

import './style.css'

function Form({className = "", children, ...rest}) {

  return(
    <form className={`form ${className}`} {...rest}>
      {children}
    </form>
  )
}

function Fieldset({className = "", top, children, ...rest}) {

  return(
    <fieldset className={`form-fieldset ${className} ${top ? "top" : ""}`} {...rest}>
      {children}
    </fieldset>
  )
}

function Field({className = "", fluid, children, ...rest}) {

  return(
    <div className={`form-field ${className} ${fluid ? "fluid" : ""}`} {...rest}>
      {children}
    </div>
  )
}

const exportedObject = { Form, Fieldset, Field }

export default exportedObject
