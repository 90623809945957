import { useNavigate } from 'react-router-dom'

import { Heading, IntlText, Button } from '../../components'
import { VendorOpeningHours } from '../../views'

export function OpeningHours({vendor}) {

  const navigate = useNavigate()

  return(
    <div id="vendor-openind-hours">
      <Heading spacing info={<IntlText group="wizard" id="opening-hours-info" />}>
        <IntlText group='wizard' id='opening-hours' />
      </Heading>         
      <VendorOpeningHours vendor={vendor}/>
      <div className='actions' style={{marginTop: 30}}>
        <Button onClick={() => navigate("../terms")}><IntlText id="back" /></Button>
        <Button theme="main" onClick={() => navigate("../payment")}><IntlText id="next" /></Button>
      </div>
    </div>
  )
}
