import { useState, useEffect } from 'react'

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export function useDetectOutsideClick({wrapper, menu, initialState, disableOutclick, disableInClickClose}){
  const [isActive, setIsActive] = useState(initialState)

  useEffect(() => {
    function handleClick(e){
      if (!isActive) return false
      if ((wrapper?.current?.contains(e.target) && (!menu?.current?.contains(e.target) || disableInClickClose)) || disableOutclick) return false

      setIsActive(false)
    }

    document.addEventListener("click", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [wrapper, menu, isActive, disableOutclick, disableInClickClose])

  return [isActive, setIsActive]
}
