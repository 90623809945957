export function extractSettings(settings){
    if (!settings?.length > 0) return {}

    const _settings = settings.reduce((current, next) => {
      let item = {...next}
  
      switch (next.type) {
        case "BOOLEAN":
          item.value = next.value && next.value === "true" ? true : false
          break;
        case "PERCENTAGE":
        case "NUMBER":
          item.value = Number(next.value)
          break;
        default:
          item.value = next.value
      }
  
      current[next.key] = item
  
      return current
    }, {})
  
    return _settings
  }
  