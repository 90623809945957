import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import moment from 'moment'

import { REPORT_PURCHASE } from '../../../graphql'
import { toast } from '../../../helpers'
import { IntlText, ContextMenu } from '../../../components'
import { PurchaseSetPreparationTime } from '../../../views'

import './style.css'

export function PurchaseStatus({purchase}) {

    const [reportPurchase] = useMutation(REPORT_PURCHASE)
    const diff = moment() - moment(purchase.timestamps.vendorConfirmed).add(purchase.preparationTime, "m")
    const [isLate, toggleIsLate] = useState(diff > 0 && (purchase.status === "INPREPARATION" || purchase.status === "READYFORCOLLECTION"))

    useEffect(() => {
        if (diff < 0) {
            setTimeout(() => {
                toggleIsLate(true)
            }, -diff)
        }
    }, [diff])

    async function _reportPurchase(reason){
        const alert = toast.loading()

        try {
            await reportPurchase({variables:{id: purchase.id, reason}})
            alert.success(<IntlText group="order-status" id="report-success" />)
        } catch (e) {
            alert.apolloError(e)
        }
    }

    return(
        <div id="purchase-status">
            <div className='info'>
                <span><IntlText group='purchase-status' id={purchase.status} /></span>
                <span><IntlText group='purchase-status' id={`${purchase.status}-desc`} /></span>
            </div>
            <div className='actions' style={{flexDirection: "column", alignItems: "center"}}>
                {(purchase.status === "PROCESSED" || purchase.status === "INPREPARATION" || purchase.status === "READYFORCOLLECTION") && 
                    <PurchaseSetPreparationTime purchase={purchase}/>
                }
                {isLate && 
                    <ContextMenu.Menu placeholder={<IntlText group="order-status" id="report-button" />}>
                        <ContextMenu.Button onClick={() => _reportPurchase("RIDER_DID_NOT_ARRIVE")}>
                            <IntlText group="order-status" id="delivery-guy-not-arrived" />
                        </ContextMenu.Button>
                    </ContextMenu.Menu>                
                }
            </div>
        </div>
    )
}
