import React, { useState } from 'react'
import { node } from 'prop-types'

import { config, strings} from './config'

const IntlContext = React.createContext()
const IntlConsumer = IntlContext.Consumer

function IntlProvider({children}) {
  const forcedLanguage = (() => {
    let url = new URL(window.location.href)
    return url.searchParams.get("lang")
  })()
  const userLanguage = forcedLanguage || localStorage.getItem('lang') || window.navigator.userLanguage || window.navigator.language
  const [selectedLanguage, setLanguage] = useState(config.languages[userLanguage.substring(0, 2)] || config.languages[config.default])

  function _setLanguage(lang){
    localStorage.setItem('lang', lang.shortcode)
    setLanguage(lang)
  }

  function getTranslation(data){
    let { group, id, defaultValue, variables } = data
    if (!group) group = "default"
    let string = strings[selectedLanguage.shortcode][group] && strings[selectedLanguage.shortcode][group][id] ? strings[selectedLanguage.shortcode][group][id] : defaultValue

    if (variables){
      Object.keys(variables).forEach((variable) => {
        let regex = new RegExp(`{([${variable}}]+)}`, 'g')
        if (!string) return
        string = string.replace(regex, variables[variable])
      })
    }

    return string || "No default defined"
  }

  function getTranslatedValue(data){
    if (!data) return null 
    return data[selectedLanguage.shortcode]
  }

  return (
    <IntlContext.Provider
      value={{
        selectedLanguage,
        userLanguage,
        languages: config.languages,
        strings,
        setLanguage: _setLanguage,
        getTranslation,
        getTranslatedValue
      }}
    >
      {children}
    </IntlContext.Provider>
  )
}

IntlProvider.propTypes = {
  children: node.isRequired,
}

export { IntlContext, IntlProvider, IntlConsumer}
