const style = {
  content : {
    left                  : '50%',
    top                   : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    display               : 'inline-block',
    margin                : '0 auto',
    padding               : '0',
    border                : 'none',
    borderRadius          : '0',
    transform             : 'translate(-50%, -50%)',
    background            : 'transparent',
    overflow              : 'visible'
  }
}

export default style
