import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { EDIT_CATEGORY_MUTATION, CREATE_CATEGORY_MUTATION, CATEGORIES_QUERY } from '../../../graphql'
import { sentenceCase, returnGraphqlError } from '../../../helpers'
import { HourPicker, Button, IntlText, LabeledInput, Validation, LabeledCheckbox, Form } from '../../../components'
import { IntlContext } from '../../../contexts'
import { useValidatedForm } from '../../../hooks'

const validations = {
  "nameEn": [{id: "required", validation: (val) => !!val}, {id: "maxLength", validation: (val) => val?.length <= 30}],
  "nameEs": [{id: "required", validation: (val) => !!val}, {id: "maxLength", validation: (val) => val?.length <= 30}]
}

export function CategoryForm({category, success, actions}) {

  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const [hasAvailability, _toggleAvailability] = useState(!!(category?.availability?.from || category?.availability?.to))
  const [editCategory, { loading: editLoading }] = useMutation(EDIT_CATEGORY_MUTATION)
  const [createCategory, { loading: createLoading }] = useMutation(CREATE_CATEGORY_MUTATION)
  const { form: { nameEn, nameEs, descriptionEn, descriptionEs, availability }, edited, updateField, errors, validate } = useValidatedForm({nameEn: category?.name?.en, nameEs: category?.name?.es, descriptionEn: category?.description?.en, descriptionEs: category?.description?.es, availability: category?.availability || {}}, validations)

  async function save(){
    try {
      const { valid } = validate()

      if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))
  
      let data = {
        name: {
          es: nameEs,
          en: nameEn
        },
        description: {
          es: descriptionEs || "",
          en: descriptionEn || ""
        },
        availability: {
          from: availability?.from,
          to: availability?.to
        }
      }
  
      if (availability) data.availability = {from: availability.from, to: availability.to}
  
      if (category && category?.id){
        await edit(data)
      } else {
        await create(data)
        navigate('../')
      }      
    } catch (error) {
      console.log("Error: ", error)
    }

  }

  async function create(data){
    try {
      await createCategory({variables: {data}, refetchQueries: [{query: CATEGORIES_QUERY}]})
      toast.success(getTranslation({id: "save-success"}))
      if (success) success()
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  async function edit(data){
    try {
      await editCategory({variables: {id: category?.id, data}})
      toast.success(getTranslation({id: "edit-success"}))
      if (success) success()
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  function setAvaibility(key, time){
    availability[key] = time.hours.toString() + time.mins.toString()
    updateField({"availability": availability})
  }

  function toggleAvailability(){
    updateField({key: "availability", value: {}})
    _toggleAvailability(!hasAvailability)
  }

  return(
    <div id="category-form">
      <Form.Form>
        <div className="warning"><IntlText group="form" id="responsibility-warning" /></div>
        <Form.Field>
          <Validation errors={errors["nameEs"]}>
            <LabeledInput name="nameEs" placeholder={getTranslation({group: "form", id: "name-es"})} value={nameEs} onChange={(e) => updateField({key: "nameEs", value: sentenceCase(e.target.value)})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <LabeledInput name="descriptionEs" placeholder={getTranslation({group: "form", id: "description-es"})} value={descriptionEs} onChange={(e) => updateField({key: "descriptionEs", value: sentenceCase(e.target.value)})} />
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["nameEn"]}>
            <LabeledInput name="nameEn" placeholder={getTranslation({group: "form", id: "name-en"})} value={nameEn} onChange={(e) => updateField({key: "nameEn", value: sentenceCase(e.target.value)})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <LabeledInput name="descriptionEn" placeholder={getTranslation({group: "form", id: "description-en"})} value={descriptionEn} onChange={(e) => updateField({key: "descriptionEn", value: sentenceCase(e.target.value)})} />
        </Form.Field>
        <Form.Fieldset>
          <Form.Field>
            <LabeledCheckbox rounded id="hasAvailability" checked={hasAvailability} onChange={toggleAvailability} placeholder={getTranslation({group: "form", id: "availability"})}/>
          </Form.Field>
          {hasAvailability &&
            <>
              <Form.Field style={{marginLeft: "auto"}}>
                <HourPicker placeholder={getTranslation({group: "form", id: "available-from"})} time={availability?.from || "0000"} onChange={(data) => setAvaibility("from", data)}/>
              </Form.Field>
              <Form.Field>
                <HourPicker placeholder={getTranslation({group: "form", id: "available-to"})} time={availability?.to || "0000"} onChange={(data) => setAvaibility("to", data)}/>
              </Form.Field>
            </>
          }
        </Form.Fieldset>
        <Form.Fieldset>
          {actions}
          {edited &&
            <Form.Field fluid>
              <Button theme="main" fluid loading={editLoading || createLoading} onClick={() => save()}><IntlText id="save" /></Button>
            </Form.Field>
          }
        </Form.Fieldset>
      </Form.Form>
    </div>
  )
}
