import React from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlText } from '../../../components'

import './style.css'

export function PurchaseNotifications({purchase: {sendUtensils}}) {

  return(
    <div id="purchase-instructions">
      <div id="eco" className="totals-info">
        <div className="icon">
          <FontAwesome name="utensils"/>
        </div>
        <div className="info">
          {sendUtensils > 0 ?
            <IntlText group="purchase-instructions" id="send-utensils" variables={{number: sendUtensils}} />
            :
            <IntlText group="purchase-instructions" id="no-utensils" />
          }
        </div>
      </div>  
    </div>
  )
}
